(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventSessionsTabController', SheetMainEventSessionsTabController);

    SheetMainEventSessionsTabController.$inject = ['Principal', '$log', '$state', '$stateParams', '$timeout', 'CustomUtils', '$translate', '$filter', 'AlertService', 'EventRegistration', '$q', 'ModelConfig', '$http', '$uibModal', 'ConfirmDialog', 'entity', 'EventSession', 'EventDay'];

    function SheetMainEventSessionsTabController(Principal, $log, $state, $stateParams, $timeout, CustomUtils, $translate, $filter, AlertService, EventRegistration, $q, ModelConfig, $http, $uibModal, ConfirmDialog, entity, EventSession, EventDay) {
        if ($stateParams.tab !== "sessions") {
            //update state param
            $stateParams.tab = "sessions";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: entity,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                informations: false,
                description: false,
                otherinformation: false,
                registrationMail: false,
                confirmRegistrationMail: false,
                dates: false
            },
            currentParticipationCount: 0,
            html: {}
        };

        var sessionsSource = {
            datatype: "json",
            url: "api/public/",
            data: {
                size: 1000
            },
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'sessionType',
                    type: 'string'
                }, {
                    name: 'dayId',
                    type: 'number'
                }, {
                    name: 'price',
                    type: 'number'
                }, {
                    name: 'startDate',
                    type: 'number'
                }, {
                    name: 'endDate',
                    type: 'number'
                }, {
                    name: 'eventSessions',
                    type: 'array'
                }, {
                    name: 'participations',
                    type: 'number'
                }, {
                    name: 'participationsConfirmed',
                    type: 'number'
                }, {
                    name: 'abstractNumber',
                    type: 'number'
                }
            ],
            hierarchy: {
                root: 'eventSessions'
            },
            sortcolumn: 'startDate',
            sortdirection: 'asc'
        };

        vm.atLeastOneDay = false;

        vm.moderators = [];

        vm.getSelectedSessionModerators = function () {
            var result = [];
            if (!CustomUtils.isUndefinedOrNull(vm.selectedSessionId)) {
                angular.forEach(vm.moderators, function (moderator) {
                    if (moderator.sessionId === vm.selectedSessionId) {
                        result.push(moderator);
                    }
                });
            }
            return result;
        };

        vm.sessionsDataAdapter = new $.jqx.dataAdapter(sessionsSource, {
            loadServerData: function (serverdata, source, callback) {
                vm.moderators = [];
                EventRegistration.getSessions({
                    id: vm.Model.id
                }, function (records) {
                    if (records.length > 0) {
                        vm.atLeastOneDay = true;
                    }
                    angular.forEach(records, function (day) {
                        angular.forEach(day.eventSessions, function (session) {
                            if (session.moderators.length > 0) {
                                vm.moderators = vm.moderators.concat(session.moderators);
                            }
                        });
                    });
                    callback({
                        records: records
                    });
                });
            }
        });

        vm.tryRemoveModerator = function (moderator) {
            var msg = moderator.firstName + " " + moderator.lastName + " des modérateurs";
            ConfirmDialog.openConfirmRemove(msg, function () {
                $http.delete("api/event-registrations/moderators/" + moderator.sessionId + "/" + moderator.registrationId).then(function () {
                    $state.reload();
                });
            });
        };

        vm.addModerator = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/add-moderator.dialog.html',
                controller: 'AddModeratorDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    session: function () {
                        return {
                            id: vm.selectedSessionId,
                            name: vm.selectedSessionName
                        };
                    },
                    participations: function () {
                        return vm.participationsGrid.source.originaldata;
                    }
                }
            }).result.then(function (result) {
                if (result.length > 0) {
                    $log.info(result);
                    $http.put("api/event-registrations/moderators", result).then(function () {
                        $state.reload();
                    });
                }
            });
        };

        vm.editDay = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/daysession.edit.dialog.html',
                controller: 'DaySessionEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventDay');
                            return $translate.refresh();
                        }],
                    eventDay: ['EventDay', function (EventDay) {
                            if (data !== null) {
                                return {
                                    id: data.id,
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    mainEventId: vm.Model.entity.id
                                };
                            }
                        }]
                }
            }).result.then(function (result) {
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    result.mainEventId = vm.Model.entity.id;

                    EventDay.save(result, function () {
                        vm.sessionsDataAdapter.dataBind();
                    });
                } else {
                    EventDay.update(result, function () {
                        vm.sessionsDataAdapter.dataBind();
                    });
                }
            });
        };

        vm.editSession = function (session) {

            var sessionId = null;
            if (session !== null) {
                sessionId = session.id;
            }

            var days = [];
            angular.forEach(vm.sessionsDataAdapter.records, function (record) {
                days.push({
                    id: record.id,
                    endDate: record.endDate,
                    startDate: record.startDate,
                    mainEventId: vm.Model.entity.id
                });
            });

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/session.edit.dialog.html',
                controller: 'SessionEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventSession');
                            return $translate.refresh();
                        }],
                    session: ['EventSession', function (EventSession) {
                            if (sessionId !== null) {
                                return EventSession.get({id: sessionId}).$promise;
                            }
                        }],
                    eventDays: function () {
                        return days;
                    }
                }
            }).result.then(function (result) {
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    EventSession.save(result, function () {
                        vm.sessionsDataAdapter.dataBind();
                    });
                } else {
                    EventSession.update(result, function () {
                        vm.sessionsDataAdapter.dataBind();
                    });
                }
            });
        };

        vm.sessionsSettings = {
            width: "100%",
            altRows: true,
            source: vm.sessionsDataAdapter,
            columnsResize: true,
            sortable: true,
            created: function (args) {
                vm.sessionsGrid = args.instance;
            },
            columns: [{
                    text: 'Session',
                    datafield: 'name',
                    cellsrenderer: function (row, dataField, cellValue, rowData, cellText) {
                        if (rowData.level === 0) {
                            var start = $filter('date')(rowData.startDate, 'EEEE dd MMMM');
                            return "<b>" + start + "</b>";
                        } else if (rowData.mandatory) {
                            var result = cellValue;
                            result += " <span class='text-danger'>";
                            result += $translate.instant("canceropoleGsoApp.eventSession.mandatory");
                            result += "</span>";
                            return result;
                        }
                    }
                }, {
                    text: 'Communications',
                    datafield: 'abstractNumber',
                    width: 50,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Participations',
                    datafield: 'participations',
                    width: 70,
                    cellsalign: 'center',
                    align: 'center',
                    sortable: false,
                    cellsrenderer: function (row, dataField, cellValue, rowData, cellText) {
                        if (!CustomUtils.isEmpty(cellValue)) {
                            var result = '<span class="text-success" title="Confirmés">' + rowData.participationsConfirmed + '</span>';
                            result += ' / ';
                            result += '<span class="text-primary" title="Inscrits">' + cellValue + '</span>';
                            return result;
                        }
                        return "";
                    }
                }, {
                    text: 'Type',
                    datafield: 'sessionType',
                    cellsalign: 'center',
                    align: 'center',
                    width: 60,
                    cellsrenderer: function (row, dataField, cellValue, rowData, cellText) {
                        if (!CustomUtils.isEmpty(cellValue)) {
                            var i18nKey = "canceropoleGsoApp.eventSession.types." + cellValue;
                            return $translate.instant(i18nKey);
                        }
                        return "";
                    }
                }, {
                    text: 'Tarif',
                    datafield: 'price',
                    cellsalign: 'right',
                    align: 'right',
                    width: 50,
                    cellsrenderer: function (row, dataField, cellValue, rowData, cellText) {
                        if (cellValue) {
                            return cellValue + " €";
                        }
                    }
                }, {
                    text: 'Début',
                    datafield: 'startDate',
                    cellsFormat: 'HH:mm',
                    width: 60
                }, {
                    text: 'Fin',
                    datafield: 'endDate',
                    cellsFormat: 'HH:mm',
                    width: 60
                }],
            rowDoubleClick: function (event) {
                var row = event.args.row;
                if (row.eventSessions) {
                    vm.editDay(row.data);
                } else {
                    vm.editSession(row.data);
                }
            },
            rowClick: function (event) {
                var row = event.args.row;

                if (row.eventSessions) {
                    //jour
                    vm.selection = {
                        type: "day",
                        data: row.data
                    };
                    vm.selection.type = "day";
                    vm.selectedSessionId = null;
                    vm.selectedSessionName = null;
                    vm.sessionIdChange(null);
                    vm.filterSession(null);
                } else {
                    //session
                    vm.selection = {
                        type: "session",
                        data: row.data
                    };
                    if (row.data.id !== vm.selectedSessionId) {
                        vm.selectedSessionId = row.data.id;
                        vm.selectedSessionName = row.data.name;
                        vm.sessionIdChange(row.data.id);
                        vm.filterSession(vm.selectedSessionName);
                    }
                }
            },
            bindingComplete: function () {
                vm.sessionsGrid.expandAll();
            }
        };

        vm.editSelection = function () {
            if (vm.selection) {
                if (vm.selection.type === "day") {
                    vm.editDay(vm.selection.data);
                } else if (vm.selection.type === "session") {
                    vm.editSession(vm.selection.data);
                }
            }
        };

        vm.participationSelected = [];

        vm.rowselect = function () {
            vm.participationSelected = vm.participationsGrid.getselectedrowindexes();
        };

        vm.rowunselect = function () {
            vm.participationSelected = vm.participationsGrid.getselectedrowindexes();
        };

        vm.exportEventRegistrations = function (type) {
            var registrations = [];

            angular.forEach(vm.participationSelected, function (index) {
                var registration = vm.participationsGrid.getrowdata(index);
                registrations.push(registration);
            });
            $state.go('exportEventRegistrationsFromSheet', {
                registrations: registrations,
                type: type,
                mainEventId: vm.Model.id
            }, {
                notify: false
            });
        };

        vm.tryRemoveSelection = function () {
            if (vm.selection) {
                if (vm.selection.type === "day") {
                    vm.tryRemoveDay(vm.selection.data);
                } else if (vm.selection.type === "session") {
                    vm.tryRemoveSession(vm.selection.data);
                }
            }
        };

        vm.tryRemoveDay = function (data) {
            $log.info(data);
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/daysession.remove.dialog.html',
                controller: 'DaySessionRemoveDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventDay');
                            return $translate.refresh();
                        }],
                    daySession: function () {
                        return data;
                    }
                }
            }).result.then(function (result) {
                EventDay.remove({id: result.id}, function () {
                    vm.sessionsDataAdapter.dataBind();
                });
            });
        };

        vm.tryRemoveSession = function (session) {

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/session.remove.dialog.html',
                controller: 'SessionRemoveDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventSession');
                            return $translate.refresh();
                        }],
                    session: function () {
                        return session;
                    }
                }
            }).result.then(function (result) {
                EventSession.remove({id: result.id}, function () {
                    vm.sessionsDataAdapter.dataBind();
                });

            });
        };
    }
})();