(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetCallForProjectExpertisesTabController', SheetCallForProjectExpertisesTabController);

    SheetCallForProjectExpertisesTabController.$inject = ['CustomUtils', '$stateParams', 'JQWidgetsService', 'SheetHistoryService', '$state', 'callForProject', '$translate', 'Principal', '$filter', '$uibModal', '$log', 'DataUtils', 'CallForProjectService', '$http'];

    function SheetCallForProjectExpertisesTabController(CustomUtils, $stateParams, JQWidgetsService, SheetHistoryService, $state, callForProject, $translate, Principal, $filter, $uibModal, $log, DataUtils, CallForProjectService, $http) {
        if ($stateParams.tab !== "expertises") {
            //update state param
            $stateParams.tab = "expertises";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.callForProject = callForProject;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.selectedIndexes = [];

        var sheetHistory = {
            name: vm.callForProject.name,
            type: 'callforproject',
            id: vm.callForProject.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        var expertisesSource = {
            datatype: "json",
            datafields: [
                {name: 'id', type: 'number'},
                {name: 'type', type: 'String'},
                {name: 'state', type: 'String'},
                {name: 'generalEvaluation', type: 'String'},
                {name: 'generalNotation', type: 'number'},
                {name: 'expertId', type: 'number'},
                {name: 'expertStartDate', type: 'date'},
                {name: 'expertEndDate', type: 'date'},
                {name: 'expertKey', type: 'number'},
                {name: 'expertPersonId', type: 'number'},
                {name: 'expertName', type: 'string'},
                {name: 'expertFirstName', type: 'string'},
                {name: 'expertLastName', type: 'string'},
                {name: 'expertEmail', type: 'string'},
                {name: 'submissionId', type: 'number'},
                {name: 'projectId', type: 'number'},
                {name: 'submissionName', type: 'string'},
                {name: 'submissionDate', type: 'date'},
                {name: 'privateKey', type: 'number'},
                {name: 'personId', type: 'number'},
                {name: 'personName', type: 'String'},
                {name: 'personFirstName', type: 'String'},
                {name: 'personLastName', type: 'String'},
                {name: 'geositeId', type: 'number'},
                {name: 'geosite', type: 'String'},
                {name: 'axeId', type: 'number'},
                {name: 'axe', type: 'String'},
                {name: 'evaluations', type: 'array'}
            ],
            id: 'id',
            url: 'api/call-for-projects/expertises/' + vm.callForProject.id,
            sortcolumn: 'expertStartDate',
            sortdirection: 'desc'
        };

        var expertisesDataAdapter = new $.jqx.dataAdapter(expertisesSource, {
            beforeLoadComplete: function (records) {
                angular.forEach(records, function (record) {
                    record.expertName = record.expertFirstName + " " + record.expertLastName;
                    record.personName = record.personFirstName + " " + record.personLastName;
                });
            }
        });

        var linkCellRenderer = function (row, column, value, html, cell, resource) {
            if (CustomUtils.isDefined(value)) {
                var url = '';
                var strValue = '';
                if ((column === 'expertFirstName' || column === 'expertLastName') && CustomUtils.isDefined(resource.personId)) {
                    url = "#/sheet/person/" + resource.expertPersonId + "?tab=main";
                    strValue = value;
                } else if (column === 'submissionName') {
                    url = "#/sheet/project/" + resource.projectId + "?tab=main";
                    strValue = value;
                } else if (column === 'expertStartDate') {
                    url = "#/callforproject/" + vm.callForProject.id + "/expert?key=" + resource.expertKey + "&expertiseId=" + resource.id;
                    strValue = $filter('date')(value, 'dd/MM/yyyy HH:mm');
                } else if (column === 'personName' && CustomUtils.isDefined(resource.expertPersonId)) {
                    url = "#/sheet/person/" + resource.personId + "?tab=main";
                    strValue = value;
                } else if (column === 'submissionDate') {
                    url = "#/callforproject/" + vm.callForProject.id + "/space?key=" + resource.privateKey;
                    strValue = $filter('date')(value, 'dd/MM/yyyy HH:mm');
                } else {
                    return html;
                }
                var link = "<a href=\"" + url + "\" title=\"" + value + "\">" + strValue + "</a>";
                return html.replace(value, link);
            }
            return html;
        };

        vm.experts = [];
        vm.porteurs = [];

        vm.expertisesGridSettings = {
            width: '100%',
            height: 550,
            source: expertisesDataAdapter,
            editable: vm.canEdit,
            groupable: true,
            columnsresize: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            altrows: true,
            showstatusbar: true,
            statusbarheight: 70,
            showaggregates: true,
            selectionmode: 'checkbox',
            localization: JQWidgetsService.getLocalization($translate.use()),
            columns: [{
                    text: $translate.instant('sheet.callforproject.expertises.grid.expertStartDate'),
                    datafield: 'expertStartDate',
                    width: 140,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    pinned: true,
                    filtertype: 'date',
                    cellsalign: 'center',
                    align: 'center',
                    aggregates: [{
                            '<b>Expertises</b>': function (aggregatedValue) {
                                return ++aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.expertPersonLastName'),
                    datafield: 'expertLastName',
                    width: 110,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    pinned: true,
                    aggregates: [{
                            '<b>Experts</b>': function (aggregatedValue, currentValue, datafield, row) {
                                var found = false;
                                for (var i = 0; i < vm.experts.length; i++) {
                                    var expert = vm.experts[i];
                                    if (expert === row.expertName) {
                                        found = true;
                                    }
                                }
                                if (!found) {
                                    vm.experts.push(row.expertName);
                                }
                                return vm.experts.length;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.expertPersonFirstName'),
                    datafield: 'expertFirstName',
                    width: 110,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    pinned: true
                }, CallForProjectService.getExpertiseStateColumn(), {
                    text: $translate.instant('sheet.callforproject.expertises.grid.coordinator'),
                    datafield: 'personName',
                    width: 180,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    aggregates: [{
                            '<b>Porteurs</b>': function (aggregatedValue, currentValue) {
                                var found = false;
                                for (var i = 0; i < vm.porteurs.length; i++) {
                                    var porteur = vm.porteurs[i];
                                    if (porteur === currentValue) {
                                        found = true;
                                    }
                                }
                                if (!found) {
                                    vm.porteurs.push(currentValue);
                                }
                                return vm.porteurs.length;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.submissionName'),
                    datafield: 'submissionName',
                    minwidth: 300,
                    cellsrenderer: linkCellRenderer,
                    editable: false
                }, {
                    text: $translate.instant('sheet.callforproject.experts.expertGrid.notation'),
                    datafield: 'generalNotation',
                    columnType: 'custom',
                    filterType: 'checkedlist',
                    createwidget: function (row, column, value, htmlElement) {
                        var rating = $('<div style="margin-top:2px;margin-left:8px;"></div>');
                        $(htmlElement).append(rating);
                        rating.jqxRating({
                            height: 30,
                            count: 4,
                            value: value,
                            disabled: true
                        });
                    },
                    initwidget: function (row, column, value, htmlElement) {
                        $(htmlElement).children().jqxRating('setValue', value);
                    },
                    width: 100,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.axe'),
                    datafield: 'axe',
                    filtertype: 'checkedlist',
                    width: 160,
                    editable: false
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.geosite'),
                    datafield: 'geosite',
                    filtertype: 'checkedlist',
                    width: 120,
                    editable: false
                }, {
                    text: $translate.instant('sheet.callforproject.expertises.grid.submissionDate'),
                    datafield: 'submissionDate',
                    filtertype: 'date',
                    width: 140,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center'
                }],
            created: function (event) {
                vm.grid = event.instance;
                vm.selectedIndexes = vm.grid.getselectedrowindexes();
            },
            rowselect: function () {
                vm.selectedIndexes = vm.grid.getselectedrowindexes();
                vm.computeStats();
                vm.retrieveProjectInformations();
            },
            rowunselect: function () {
                vm.selectedIndexes = vm.grid.getselectedrowindexes();
                vm.computeStats();
                vm.retrieveProjectInformations();
            },
            filter: function () {
                vm.experts = [];
                vm.porteurs = [];
                vm.grid.refreshaggregates();
            }
        };

        vm.expertisesStats1 = [];
        vm.expertisesStats2 = [];

        vm.computeStats = function () {
            vm.expertisesStats1 = [];
            vm.expertisesStats2 = [];
            var projects = [];

            var expertises = vm.getSelectedExpertises();
            for (var i = 0; i < expertises.length; i++) {
                var expertise = expertises[i];
                var project = null;
                for (var j = 0; j < projects.length; j++) {
                    if (projects[j].id === expertise.projectId) {
                        project = projects[j];
                        break;
                    }
                }
                if (project === null) {
                    project = {
                        id: expertise.projectId,
                        expertises: []
                    };
                    projects.push(project);
                }
                project.expertises.push(expertise);
            }
            if (angular.isDefined(projects[0])) {
                vm.expertisesStats1 = projects[0].expertises;
            }
            if (angular.isDefined(projects[1])) {
                vm.expertisesStats2 = projects[1].expertises;
            }
        };

        vm.selectedExpertise = null;
        vm.retrieveProjectInformations = function () {
            var selectedExpertises = vm.getSelectedExpertises();
            if (selectedExpertises.length === 1) {
                vm.selectedExpertise = vm.getSelectedExpertises()[0]

                var url = 'api/expert/space/' + vm.selectedExpertise.expertKey + '/' + vm.selectedExpertise.id;
                $http.get(url).then(function (result) {
                    if (result.data) {
                        vm.selectedForm = CallForProjectService.getStructuredForm(result.data, vm.selectedExpertise.type, true);
                        vm.selectedProject = result.data.project;
                        vm.selectedSubmission = result.data.submission;
                        vm.showSubmission = true;
                    }
                });
            } else {
                vm.showSubmission = false;
            }
        };

        vm.getSelectedExpertises = function () {
            vm.selectedIndexes = vm.grid.getselectedrowindexes();

            var result = [];
            angular.forEach(vm.selectedIndexes, function (selectedIndex) {
                var expertise = expertisesDataAdapter.records[selectedIndex];
                result.push(expertise);
            });
            return result;
        };

        vm.exportExpertises = function (type) {
            var exportType = {
                type: type,
                fileName: 'Expertises.' + type
            };

            var expertises = vm.getSelectedExpertises();

            var columns = {};
            columns["info_porteur"] = "Description Administrative";
            columns.projet = "Bloc projet";
            //columns.expertiseId = vm.selectedExpertise.id;

            /*angular.forEach(vm.form.groups, function (group) {
             var datafield = "group_" + group.id;
             columns[datafield] = group.name;
             });*/

            var data = [];
            angular.forEach(expertises, function (expertise) {
                data.push(expertise.id);
            });
            var toSend = {
                type: exportType.type,
                data: data,
                columns: columns
            };

            DataUtils.downloadFileFromPostUrl("api/call-for-projects/" + vm.callForProject.id + "/expertises/export", toSend, exportType.fileName);
        };

        vm.sendMailToExperts = function () {

            var selectedExpertises = vm.getSelectedExpertises();
            console.log(selectedExpertises);

            var experts = [];
            _.forEach(selectedExpertises, function (selectedExpertise) {
                var expert = _.find(experts, function (expert) {
                    return expert.id === selectedExpertise.expertId;
                });

                if (_.isNil(expert)) {
                    var expert = {
                        id: selectedExpertise.expertId,
                        personName: selectedExpertise.expertName,
                        email: selectedExpertise.expertEmail,
                        expertise: []
                    };
                    experts.push(expert);
                }
                expert.expertise.push({
                    id: selectedExpertise.id
                });
            });

            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/send-callforproject-mail.dialog.html',
                controller: 'SendCallForProjectMailDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('expert');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                    callForProject: function () {
                        return vm.callForProject;
                    },
                    experts: function () {
                        return experts;
                    },
                    existingsMails: ['$http', function ($http) {
                            return $http.get("api/call-for-projects/mails/" + vm.callForProject.id).then(function (response) {
                                return response.data;
                            });
                        }]
                }
            });
        };

    }
})();