(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectMainController', SheetProjectMainController);

    SheetProjectMainController.$inject = ['Principal', 'project', '$stateParams', '$state', 'Project', 'AlertService', 'Axe', 'SocietySearch', 'Society', 'CustomUtils', 'coordinators', 'JQWidgetsService', '$translate', '$uibModal', 'ProjectParticipation', 'ConfirmDialog', '$filter'];

    function SheetProjectMainController(Principal, project, $stateParams, $state, Project, AlertService, Axe, SocietySearch, Society, CustomUtils, coordinators, JQWidgetsService, $translate, $uibModal, Participation, ConfirmDialog, $filter) {
        if ($stateParams.tab !== "main") {
            //update state param
            $stateParams.tab = "main";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.axes = Axe.query();
        vm.project = project;
        vm.coordinators = coordinators;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.edition = {
            informations: false,
            timeline: false
        };

        if (vm.project.societyId) {
            Society.get({id: vm.project.societyId}, function (result) {
                vm.society = result;
            });
        }

        vm.coordinatorsTimeline = [];
        angular.forEach(vm.coordinators, function (coordinator) {
            var link = '<a href="#/sheet/person/' + coordinator.personId + '?tab=main">';
            link += coordinator.name;
            link += '</a>';
            var event = {
                startDate: coordinator.startDate,
                endDate: coordinator.endDate,
                type: 'info',
                icon: 'glyphicon glyphicon-user',
                title: link
            };
            vm.coordinatorsTimeline.push(event);
        });

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.cancel = vm.load;

        vm.edit = function (key) {
            vm.edition[key] = true;
        };

        vm.save = function () {
            Project.update(vm.project, vm.load, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });
        };

        var societyDataAdapter = new $.jqx.dataAdapter({
            url: 'api/search',
            datatype: 'json',
            id: 'id',
            data: {
                size: '20'
            },
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }]
        }, {
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: vm.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });


        vm.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                vm.societyComboBox = args.instance;
                if (vm.society) {
                    vm.societyComboBox.val(vm.society.name);
                    vm.societyComboBoxSettings.search(vm.society.name);
                }
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var society = item.originalItem;
                    vm.society = society;
                    vm.project.societyId = vm.society.id;
                    if (!CustomUtils.isEmpty(society.city)) {
                        vm.project.city = society.city;
                    }
                }
            },
            unselect: function () {
                vm.society = null;
            },
            change: function () {
                var selection = vm.societyComboBox.getSelectedItem();
                if (selection !== null) {
                    vm.society = selection.originalItem;
                    vm.project.societyId = vm.society.id;
                }
            }
        };

        vm.axesSettings = {
            width: "100%",
            displayMember: "name",
            autoHeight: true,
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: vm.axes,
            created: function (args) {
                vm.axeList = args.instance;
                angular.forEach(vm.project.axes, function (axe) {
                    vm.axeList.checkItem(axe.id);
                });
            },
            checkChange: function (event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;
                    for (var i = 0; i < vm.project.axes.length; i++) {
                        var axe = vm.project.axes[i];
                        if (axe.id === args.value) {
                            index = i;
                            break;
                        }
                    }

                    if (args.checked === true && index === null) {
                        vm.project.axes.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        vm.project.axes.splice(index, 1);
                    }
                }
            }
        };

        vm.selectedParticipation = null;

        vm.participationDataAdapter = new $.jqx.dataAdapter({
            dataType: "json",
            localdata: vm.coordinators,
            dataFields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: "personId",
                    type: "number"
                }, {
                    name: "name",
                    type: "string"
                }, {
                    name: 'startDate',
                    type: 'date'
                }, {
                    name: 'endDate',
                    type: 'date'
                }, , {
                    name: 'type',
                    type: 'string'
                }]
        });

        vm.participationsGridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: vm.participationDataAdapter,
            enabletooltips: true,
            height: 350,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                vm.participationsGrid = args.instance;
            },
            columns: [{
                    text: 'Nom',
                    datafield: 'name',
                    minwidth: 130,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Début',
                    datafield: 'startDate',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'date',
                    width: 130
                }, {
                    text: 'Fin',
                    datafield: 'endDate',
                    filtertype: 'date',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    width: 130
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    vm.selectedParticipation = args.row;
                }
            },
            rowdoubleclick: function (event) {
                if (event.args) {
                    vm.editParticipation(vm.selectedParticipation);
                }
            }
        };

        vm.editParticipation = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/project/dialog/participation.edit.dialog.html',
                controller: 'ParticipationEditDialogController',
                controllerAs: 'ctrl',
                //size: 'sm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('projectParticipation');
                            return $translate.refresh();
                        }],
                    participation: function () {
                        if (CustomUtils.isDefined(data)) {
                            return {
                                id: data.id,
                                personId: data.personId,
                                type: data.type,
                                name: data.name,
                                startDate: data.startDate,
                                endDate: data.endDate
                            };
                        }
                    },
                    searchPerson: true,
                    searchProject: false
                }
            }).result.then(function (result) {
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    result.projectId = vm.project.id;
                    result.type = 'coordinator';

                    Participation.save(result, function () {
                        AlertService.success("Information sauvegardée");
                        vm.load();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                } else {
                    result.projectId = vm.project.id;
                    result.type = 'coordinator';
                    Participation.update(result, function () {
                        AlertService.success("Information mise à jour.");
                        vm.load();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                }
            });
        };

        vm.tryRemoveParticipation = function () {
            if (CustomUtils.isDefined(vm.selectedParticipation)) {
                var message = vm.selectedParticipation.name;
                if (vm.selectedParticipation.startDate) {
                    if (message.length > 0) {
                        message += " ";
                    }
                    message += $filter("date")(vm.selectedParticipation.startDate, "dd/MM/yyyy");
                }
                if (vm.selectedParticipation.endDate) {
                    if (message.length > 0) {
                        message += " - ";
                    }
                    message += $filter("date")(vm.selectedParticipation.endDate, 'dd/MM/yyyy');
                }

                ConfirmDialog.openConfirmRemove(message, function () {
                    vm.removeParticipation(vm.selectedParticipation.id);
                });
            }
        };

        vm.removeParticipation = function (id) {
            Participation.delete({id: id}, function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la suppression.");
            });
        };
    }
})();
