(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .service('ConfirmDialog', ConfirmDialog);

    ConfirmDialog.$inject = ['$uibModal'];

    function ConfirmDialog($uibModal) {

        this.open = function (title, text, onConfirm, onCancel) {
            this.openModal('default', title, text, onConfirm, onCancel);
        };

        this.openConfirmRemove = function (text, onConfirm, onCancel) {
            this.openModal('remove', null, text, onConfirm, onCancel);
        };

        this.openConfirmRemoveWithCustomMsg = function (text, onConfirm, onCancel) {
            this.openModal('removeWithCustomMsg', null, text, onConfirm, onCancel);
        };

        this.openConfirmRemoveLink = function (text, onConfirm, onCancel) {
            this.openModal('removeLink', null, text, onConfirm, onCancel);
        };

        this.openYesNo = function (title, text, onConfirm, onCancel) {
            this.openModal('yesNo', title, text, onConfirm, onCancel);
        };

        this.openModal = function (type, title, text, onConfirm, onCancel) {
            $uibModal.open({
                templateUrl: 'app/components/dialog/confirm.dialog.html',
                controller: 'ConfirmDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    context: function () {
                        text = _.replace(text, /\'/g, '\\\'');
                        title = _.replace(title, /\'/g, '\\\'');
                        return {
                            title: title,
                            text: text,
                            type: type
                        };
                    },
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }]
                }
            }).result.then(onConfirm, onCancel);
        };
    }
})();
