(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectValorisationController', SheetProjectValorisationController);

    SheetProjectValorisationController.$inject = ['Principal', 'project', '$stateParams', '$state', 'valorizations', 'JQWidgetsService', '$translate', 'ConfirmDialog', '$log', 'Valorization', '$uibModal', 'CustomUtils'];

    function SheetProjectValorisationController(Principal, project, $stateParams, $state, valorizations, JQWidgetsService, $translate, ConfirmDialog, $log, Valorization, $uibModal, CustomUtils) {
        if ($stateParams.tab !== "valorisation") {
            //update state param
            $stateParams.tab = "valorisation";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.project = project;
        vm.valorizations = valorizations;
        vm.selectedValorization = null;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        var valorizationSource = {
            datatype: "json",
            localdata: vm.valorizations,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'year',
                    type: 'number'
                }, {
                    name: 'content',
                    type: 'string'
                }, {
                    name: 'valorizationTypeId',
                    type: 'number'
                }, {
                    name: 'valorizationTypeName',
                    type: 'string'
                }
            ],
            sortcolumn: 'year',
            sortdirection: 'desc',
            id: 'id'
        };

        vm.gridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: new $.jqx.dataAdapter(valorizationSource),
            enabletooltips: true,
            height: 450,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                vm.grid = args.instance;
            },
            columns: [{
                    text: 'Année',
                    datafield: 'year',
                    width: 80,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Type',
                    datafield: 'valorizationTypeName',
                    width: 250
                }, {
                    text: 'Titre',
                    datafield: 'name',
                    width: 300
                }, {
                    text: 'Contenu',
                    datafield: 'content',
                    minwidth: 250
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    vm.selectedValorization = args.row;
                }
            }
        };

        vm.addValorization = function () {
            vm.editValorization({
                year: 2017
            });
        };

        vm.editValorization = function (valorization) {
            $uibModal.open({
                templateUrl: 'app/sheet/project/dialog/valorization.edit.dialog.html',
                controller: 'ValorizationEditDialogController',
                controllerAs: 'ctrl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    valorization: function () {
                        valorization.projectId = vm.project.id;
                        return valorization;
                    },
                    valorizationTypes: ['ValorizationType', function (ValorizationType) {
                            return ValorizationType.query().$promise;
                        }]
                }
            }).result.then(function () {
                vm.refresh();
            });
        };

        vm.tryRemoveValorization = function () {
            ConfirmDialog.openConfirmRemove(vm.selectedValorization.name, function () {
                vm.removeValorization();
            });
        };

        vm.removeValorization = function () {
            Valorization.delete({id: vm.selectedValorization.id}, vm.refresh);
        };

        vm.refresh = function () {
            $state.reload();
        };
    }
})();
