/* global _ */

(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('CallForProjectSpaceStepOneController', CallForProjectSpaceStepOneController);

    CallForProjectSpaceStepOneController.$inject = ['Principal', '$filter', 'allInformations', '$log', 'personMetierEntities', 'CallForProjectService', '$state', 'PersonSocietyInput', 'AlertService', 'CustomUtils', '$http', '$stateParams', '$uibModal', 'ConfirmDialog', '$timeout', 'Submission'];

    function CallForProjectSpaceStepOneController(Principal, $filter, allInformations, $log, personMetierEntities, CallForProjectService, $state, PersonSocietyInput, AlertService, CustomUtils, $http, $stateParams, $uibModal, ConfirmDialog, $timeout, Submission) {

        var vm = this;
        vm.spaceKey = $stateParams.key;
        vm.isSaving = false;
        vm.editCoordonnees = false;
        vm.editProject = false;
        vm.showValidityError = false;

        vm.metiers = personMetierEntities;
        vm.managements = allInformations.managements;
        vm.callForProject = allInformations.callForProject;
        vm.coordonnees = allInformations.coordonnee;
        vm.project = allInformations.project;
        vm.groups = allInformations.groups;
        vm.submission = allInformations.submission;
        vm.form = CallForProjectService.getStructuredForm(allInformations, 'LI');
        vm.confirmSend = false;
        vm.editPrivateInformation = false;

        vm.isAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        vm.canEdit = function () {
            if (vm.isAdmin()) {
                return true;
            }

            if (vm.isOpenForm(vm.form) === true &&
                    CustomUtils.isUndefinedOrNull(vm.submission.stepOneDate) &&
                    CustomUtils.isUndefinedOrNull(vm.submission.keep)) {
                return true;
            }

            return false;
        };

        vm.canUpload = function () {
            if (vm.isAdmin()) {
                return true;
            }

            if (vm.isOpenForm(vm.form) === true &&
                    CustomUtils.isUndefinedOrNull(vm.submission.stepOneDate) &&
                    CustomUtils.isUndefinedOrNull(vm.submission.keep)) {
                return Principal.isAuthenticated();
            }
            return false;
        };

        vm.isOpenForm = function (form) {
            return CallForProjectService.isOpenForForm(form);
        };

        vm.reload = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancelEdition = vm.reload;

        vm.saveCoordonnees = function () {
            //not mandatory portable field.
            vm.coordonnees.portable = CustomUtils.correctPhone(vm.coordonnees.portable);
            vm.coordonnees.phone = CustomUtils.correctPhone(vm.coordonnees.phone);
            if (vm.coordonneesForm.$valid === true) {
                if (vm.isSaving === false && vm.coordonneesForm.$dirty === true) {
                    vm.isSaving = true;
                    if (vm.coordonnees.personStatusId !== 18) {
                        vm.coordonnees.detailPersonFunction = null;
                    }
                    PersonSocietyInput.update(vm.coordonnees, function () {
                        vm.reload();
                    }, function () {
                        vm.isSaving = false;
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                }
            } else {
                AlertService.warning("Veuillez corriger les erreurs avant de sauvegarder.");
            }
        };

        vm.savePrivateInformation = function () {
            vm.editPrivateInformation = false;
            Submission.update(vm.submission, function (result) {
                vm.submission = result;
                AlertService.info("Commentaire privé mis à jour.");
            }, function (error) {
                $log.error(error);
                AlertService.error("Le commentaire privé n'a pas pu être mis à jour.");
            });
        };

        vm.linkToSociety = function () {
            $uibModal.open({
                templateUrl: 'app/manifestation/subscription/dialog/link-subscription-society.dialog.html',
                controller: 'LinkSubscriptionSocietyDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('entities');
                            return $translate.refresh();
                        }],
                    toSearch: function () {
                        return vm.coordonnees.societyName;
                    }
                }
            }).result.then(function (result) {
                vm.coordonnees.societyId = result.id;
                vm.coordonnees.societyName = result.name;
                vm.coordonnees.city = result.city;

                PersonSocietyInput.update(vm.coordonnees, function () {
                    vm.reload();
                }, function () {
                    vm.isSaving = false;
                    AlertService.error("Impossible de sauvegarder en base.");
                });
            });
        };

        vm.linkToPerson = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/link-registration-person.dialog.html',
                controller: 'LinkRegistrationPersonDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('entities');
                            return $translate.refresh();
                        }],
                    toSearch: function () {
                        return vm.coordonnees.firstName + " " + vm.coordonnees.lastName;
                    },
                    abstractSpeakers: function () {
                        return [];
                    }
                }
            }).result.then(function (result) {
                vm.coordonnees.personId = result.person.id;

                PersonSocietyInput.update(vm.coordonnees, function () {
                    vm.reload();
                }, function () {
                    vm.isSaving = false;
                    AlertService.error("Impossible de sauvegarder en base.");
                });
            });
        };

        vm.tryUnlinkPerson = function () {
            var title = 'Confirmation de dissociation';
            var msg = 'Confirmez-vous la dissociation de ' + vm.coordonnees.firstName + ' ' + vm.coordonnees.lastName + ' avec la fiche en base ?';
            ConfirmDialog.openYesNo(title, msg, function (result) {
                if (result === true) {
                    vm.unlinkPerson();
                }
            });
        };

        vm.unlinkPerson = function () {
            vm.coordonnees.personId = null;

            PersonSocietyInput.update(vm.coordonnees, function () {
                vm.reload();
            }, function () {
                vm.isSaving = false;
                AlertService.error("Impossible de sauvegarder en base.");
            });
        };

        vm.tryUnlinkSociety = function () {
            var title = 'Confirmation de dissociation';
            var msg = 'Confirmez-vous la dissociation de ' + vm.coordonnees.societyName + ' avec la fiche en base ?';
            ConfirmDialog.openYesNo(title, msg, function (result) {
                if (result === true) {
                    vm.unlinkSociety();
                }
            });
        };

        vm.unlinkSociety = function () {
            vm.coordonnees.societyId = null;

            PersonSocietyInput.update(vm.coordonnees, function () {
                vm.reload();
            }, function () {
                vm.isSaving = false;
                AlertService.error("Impossible de sauvegarder en base.");
            });
        };

        vm.metierDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            source: new $.jqx.dataAdapter({// jshint ignore:line
                localdata: vm.metiers,
                datatype: 'json',
                id: 'id',
                datafields: [{
                        name: 'id'
                    }, {
                        name: 'name'
                    }, {
                        name: 'personStatuses',
                        type: 'array'
                    }]
            })
        };

        vm.onMetierSelect = function (event) {
            var args = event.args;
            if (args) {
                var metier = args.item.originalItem;
                //vm.metier = metier;
                vm.coordonnees.personMetierId = metier.id;
                vm.coordonnees.personMetierName = metier.name;
                var dataAdapter = new $.jqx.dataAdapter({
                    localdata: metier.personStatuses,
                    datatype: 'json',
                    datafields: [{
                            name: 'id'
                        }, {
                            name: 'name'
                        }]
                });
                var found = false;
                if (vm.status !== null) {
                    for (var i = 0; i < metier.personStatuses.length; i++) {
                        var aStatus = metier.personStatuses[i];
                        if (aStatus.id === vm.status.id) {
                            found = true;
                            break;
                        }
                    }
                }
                if (!found) {
                    vm.statusDropDownList.clearSelection();
                    vm.status = null;
                }
                vm.statusDropDownListSettings.source = dataAdapter;

                vm.coordonneesForm.$dirty = true;
            }
        };

        vm.statusDropDownList = null;
        vm.statusDropDownListSettings = {
            source: [],
            displayMember: 'name',
            valueMember: 'id',
            created: function (args) {
                vm.statusDropDownList = args.instance;
            }
        };

        vm.onStatusSelect = function (event) {
            var args = event.args;
            if (args) {
                if (angular.isDefined(args.item.value)) {
                    var status = args.item.originalItem;
                    vm.coordonnees.personStatusId = status.id;
                    vm.coordonnees.personStatusName = status.name;
                    vm.coordonneesForm.$dirty = true;
                }
            }
        };

        vm.metier = null;
        vm.status = null;
        for (var i = 0; i < vm.metiers.length; i++) {
            var personMetier = vm.metiers[i];
            if (personMetier.id === vm.coordonnees.personMetierId) {
                vm.metier = personMetier;
                for (var j = 0; j < vm.metier.personStatuses.length; j++) {
                    var personStatus = vm.metier.personStatuses[j];
                    if (personStatus.id === vm.coordonnees.personStatusId) {
                        vm.status = personStatus;
                        break;
                    }
                }
                break;
            }
        }

        vm.checkPhone = function (event) {
            var correctedPhone = CustomUtils.correctPhone(vm.coordonnees.phone);
            var isPortable = CustomUtils.isPhoneNumber(correctedPhone);
            if (vm.coordonnees.phone !== correctedPhone) {
                $timeout(function () {
                    $(event.target).jqxMaskedInput('clear');
                    $(event.target).jqxMaskedInput('val', correctedPhone);
                }, 200);
            }
            vm.coordonneesForm.phone.$setValidity('minlength', isPortable);
        };

        vm.checkPortable = function (event) {
            var correctedPhone = CustomUtils.correctPhone(vm.coordonnees.portable);
            if (!CustomUtils.isEmpty(correctedPhone)) {
                var isPortable = CustomUtils.isPhoneNumber(correctedPhone);
                vm.coordonneesForm.portable.$setValidity('minlength', isPortable);
                if (vm.coordonnees.portable !== correctedPhone) {
                    $timeout(function () {
                        $(event.target).jqxMaskedInput('clear');
                        $(event.target).jqxMaskedInput('val', correctedPhone);
                    }, 200);
                }
            } else {
                vm.coordonneesForm.portable.$setValidity('minlength', true);
            }
        };

        vm.initCountryList = function () {
            vm.countries = [];
            CustomUtils.getCountries().then(function (result) {
                angular.forEach(result, function (value, key) {
                    if (value.alpha2Code === vm.defaultCountry) {
                        vm.countryToSelect = value.name;
                    }
                    vm.countries.push({
                        name: value.name
                    });
                });
            });
        };

        vm.canSaveForm = function (form) {
            var angularForm = vm[form.formName];
            if (angularForm &&
                    angularForm.$valid && !angularForm.$invalid) {
                if (vm.canSaveProject() === true) {
                    return true;
                }
            }
            return false;
        };

        vm.trySaveFormResponse = function (form, checkAllInformation) {
            vm.showValidityError = false;

            var send = !vm.isSaving && vm.canSaveProject();
            if (send && checkAllInformation) {
                send = vm.confirmSend && vm.canSaveForm(form);
            }
            if (send) {
                if (checkAllInformation) {
                    var title = 'Confirmation de soumission';
                    var msg = 'Confirmez-vous la soumission ?<br><br>' +
                            '<span class="label label-warning">Attention</span> La modification ne sera plus possible.';
                    ConfirmDialog.openYesNo(title, msg, function (result) {
                        if (result === true) {
                            vm.saveFormResponse(form, checkAllInformation);
                        }
                    });
                } else {
                    vm.saveFormResponse(form, checkAllInformation);
                }
            } else {
                vm.showValidityError = true;
            }
        };

        vm.saveFormResponse = function (form, checkAllInformation) {
            vm.submit = checkAllInformation;
            vm.isSaving = true;

            vm.saveProject();

            var angularForm = vm[form.formName];
            var responses = [];
            angular.forEach(form.groups, function (group) {
                angular.forEach(group.fields, function (field) {
                    var value = null;
                    if (field.type === 'checkbox') {
                        value = JSON.stringify(field.value);
                    } else if (field.type === 'ifYes') {
                        if (field.valueRadio === false) {
                            value = 'false';
                        } else {
                            value = _.escape(field.textValue);
                        }
                    } else if (field.type === 'ifNo') {
                        if (field.valueRadio === true) {
                            value = 'true';
                        } else {
                            value = _.escape(field.textValue);
                        }
                    } else if (field.type === 'date') {
                        field.value = $filter('date')(field.value, 'MM/dd/yyyy');
                        value = field.value;
                    } else if (field.type === 'editor') {
                        value = field.value;
                    } else if (angularForm[field.name]) {
                        value = _.escape(angularForm[field.name].$viewValue);
                    } else if (field.type === 'table') {
                        value = JSON.stringify(field.value);
                    }
                    if (!CustomUtils.isUndefinedOrNull(value)) {
                        var response = {
                            fieldId: field.id,
                            value: value.toString()
                        };
                        if (field.response && field.response.id) {
                            response.id = field.response.id;
                        }
                        responses.push(response);
                    }
                });
            });

            $log.info(responses);

            var url = 'api/submission/responses/' + +vm.spaceKey;
            if (checkAllInformation) {
                url += '?stepOne=true';
            }

            $http.put(url, responses).then(function (response) {
                if (response.status === 200) {
                    AlertService.success('Informations sauvegardées.');
                    vm.reload();
                } else {
                    AlertService.error('Impossible de sauvegarder en base.');
                }
                vm.isSaving = false;
            });
        };

        vm.exportSubmission = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/export-submissions.dialog.html',
                controller: 'ExportSubmissionsDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('callForProjectForm');
                            $translatePartialLoader.addPart('cFPFormType');
                            return $translate.refresh();
                        }],
                    submissions: function () {
                        return [vm.submission];
                    },
                    exportType: function () {
                        return {
                            type: "pdf",
                            fileName: vm.callForProject.name + ".zip"
                        };
                    },
                    callForProject: function () {
                        return vm.callForProject;
                    },
                    formTypes: function () {
                        return ["LI"];
                    },
                    formFieldsList: ['$http', '$stateParams', 'DateUtils', function ($http, $stateParams, DateUtils) {
                            return $http.get("api/call-for-projects/" + vm.callForProject.id + "/form/all").then(function (response) {
                                if (response.status === 200) {
                                    return response.data;
                                } else {
                                    return [];
                                }
                            });
                        }]
                }
            });
        };

        vm.initCountryList();
    }
})();