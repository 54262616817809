/* global _ */

(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .factory('CallForProjectService', CallForProjectService);

    CallForProjectService.$inject = ['CustomUtils', 'DateUtils', '$translate', '$filter'];

    function CallForProjectService(CustomUtils, DateUtils, $translate, $filter) {

        var oneStepMails = [{
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.D_EPSaved'),
                value: 'A_LISaved',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.oneStep.A_LISaved.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.D_EPSubmitted'),
                value: 'A_LISubmitted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.oneStep.A_LISubmitted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.E_EPAccepted'),
                value: 'B_LIAccepted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.oneStep.B_LIAccepted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.F_EPRefused'),
                value: 'C_LIRefused',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.oneStep.C_LIRefused.help')
            }];

        var twoStepMails = [{
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.A_LISaved'),
                value: 'A_LISaved',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.A_LISaved.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.A_LISubmitted'),
                value: 'A_LISubmitted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.A_LISubmitted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.B_LIAccepted'),
                value: 'B_LIAccepted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.B_LIAccepted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.C_LIRefused'),
                value: 'C_LIRefused',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.C_LIRefused.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.D_EPSaved'),
                value: 'D_EPSaved',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.D_EPSaved.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.D_EPSubmitted'),
                value: 'D_EPSubmitted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.D_EPSubmitted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.E_EPAccepted'),
                value: 'E_EPAccepted',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.E_EPAccepted.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.F_EPRefused'),
                value: 'F_EPRefused',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.twoStep.F_EPRefused.help')
            }];

        var expMails = [{
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved'),
                value: 'G_EXPSaved',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_2'),
                value: 'G_EXPSaved_2',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_2.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_3'),
                value: 'G_EXPSaved_3',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_3.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_4'),
                value: 'G_EXPSaved_4',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_4.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_5'),
                value: 'G_EXPSaved_5',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_5.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_6'),
                value: 'G_EXPSaved_6',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_6.help')
            }, {
                label: $translate.instant('canceropoleGsoApp.CallForProjectMailType.G_EXPSaved_7'),
                value: 'G_EXPSaved_7',
                help: $translate.instant('canceropoleGsoApp.CallForProjectMailType.exp.G_EXPSaved_7.help')
            }];

        oneStepMails = oneStepMails.concat(expMails);
        twoStepMails = twoStepMails.concat(expMails);

        var service = {
            isOpen: isOpen,
            isOpenForForm: isOpenForForm,
            getDuration: getDuration,
            getMailTypes: getMailTypes,
            getStructuredForm: getStructuredForm,
            renderFormDecision: renderFormDecision,
            getMailStepLabelValue: getMailStepLabelValue,
            getMailStepFundedValue: getMailStepFundedValue,
            getFormTypeLabel: getFormTypeLabel,
            loadFormField: loadFormField,
            getFormConfig: getFormConfig,
            getExpertiseStateColumn: getExpertiseStateColumn,
            getProgressionStepColumn: getProgressionStepColumn
        };

        return service;

        function isOpen(forms) {
            for (var i = 0; i < forms.length; i++) {
                var form = forms[i];
                if (this.isOpenForForm(form) === true) {
                    return true;
                }
            }
            return false;
        }

        function isOpenForForm(form) {
            if (CustomUtils.isUndefinedOrNull(form.endDate)) {
                return true;
            }
            var now = new Date();
            if (DateUtils.compare(now, form.endDate) < 0) {
                return true;
            }
            return false;
        }

        function getDuration(callForProject) {
            if (CustomUtils.isUndefinedOrNull(callForProject.startDate) && CustomUtils.isUndefinedOrNull(callForProject.endDate)) {
                var months;
                months = (callForProject.endDate.getFullYear() - callForProject.startDate.getFullYear()) * 12;
                months -= callForProject.startDate.getMonth() + 1;
                months += callForProject.endDate.getMonth();
                return months <= 0 ? 0 : months;
            }
            return -1;
        }

        function getMailStepFundedValue(callForProject, funded) {
            if (callForProject.privateSpace === true) {
                if (funded == true) {
                    return "E_EPAccepted";
                } else {
                    return "F_EPRefused";
                }
            } else {
                if (funded == true) {
                    return "B_LIAccepted";
                } else {
                    return "C_LIRefused";
                }
            }
        }

        function getMailStepLabelValue(callForProject, value) {
            var stepMails = getMailTypes(callForProject);
            for (var i = 0; i < stepMails.length; i++) {
                if (stepMails[i].value === value) {
                    return stepMails[i].label;
                }
            }
            return "";
        }

        function getMailTypes(callForProject) {
            var result = null;
            if (callForProject.privateSpace) {
                result = angular.copy(twoStepMails);
            } else {
                result = angular.copy(oneStepMails);
            }

            if (!callForProject.canSaveSubmission) {
                _.remove(result, function (item) {
                    return item.value === 'A_LISaved' || item.value === 'D_EPSaved';
                });
            }

            return result;
        }

        function getStructuredForm(allInformation, formType, showEmptyField) {
            var forms = allInformation.forms;
            var groups = allInformation.groups;
            var fields = allInformation.fields;
            var responses = allInformation.responses;
            var evaluations = allInformation.evaluations;

            var formToReturn = null;
            angular.forEach(forms, function (form) {
                form.groups = [];
                form.formName = CustomUtils.removeSpecialChars(form.name);
                if (form.type === formType) {
                    formToReturn = form;
                    angular.forEach(groups, function (group) {
                        if (group.callForProjectFormId === form.id) {
                            group.fields = [];
                            angular.forEach(fields, function (field) {
                                if (field.callForProjectFormGroupId === group.id && (field.type !== 'empty' || showEmptyField === true)) {
                                    group.fields.push(field);
                                    field.response = null;
                                    //response
                                    angular.forEach(responses, function (response) {
                                        if (response.callForProjectFormFieldId === field.id) {
                                            field.response = response;
                                            if (field.type === 'number') {
                                                field.value = Number(response.response);
                                            } else if (field.type === 'yesNo') {
                                                if (response.response == 'false') {
                                                    field.value = false;
                                                } else {
                                                    field.value = true;
                                                }
                                            } else if (field.type === "ifYes") {
                                                if (response.response == 'false') {
                                                    field.valueRadio = false;
                                                    field.value = false;
                                                } else {
                                                    field.valueRadio = true;
                                                    field.value = _.unescape(response.response);
                                                    field.textValue = _.unescape(response.response);
                                                }
                                            } else if (field.type === 'ifNo') {
                                                if (response.response == 'true') {
                                                    field.valueRadio = true;
                                                    field.value = true;
                                                } else {
                                                    field.valueRadio = false;
                                                    field.value = _.unescape(response.response);
                                                    field.textValue = _.unescape(response.response);
                                                }
                                            } else if (field.type === 'table') {
                                                field.value = JSON.parse(response.response);
                                            } else {
                                                field.value = _.unescape(response.response);
                                            }
                                        }
                                    });
                                    //evaluations
                                    if (field.criterion === true && CustomUtils.isDefined(evaluations)) {
                                        for (var i = 0; i < evaluations.length; i++) {
                                            var evaluation = evaluations[i];
                                            if (evaluation.callForProjectFormFieldId === field.id) {
                                                if (angular.isString(evaluation.content)) {
                                                    evaluation.content = JSON.parse(evaluation.content);
                                                }
                                                field.criterionValue = evaluation.content.value;
                                                field.criterionComment = evaluation.content.comment;
                                                break;
                                            }
                                        }
                                    }
                                }
                            });
                            if (group.fields.length > 0) {
                                form.groups.push(group);
                                CustomUtils.sortByKey(group.fields, 'position');
                            }
                        }
                    });
                    CustomUtils.sortByKey(form.groups, 'position');
                }
            });

            return formToReturn;
        }

        function renderFormDecision(accepted, addDiv) {
            var result = '';
            if (!CustomUtils.isEmpty(accepted)) {
                if (addDiv) {
                    result += '<div class="jqx-grid-cell-middle-align" style="margin-top:6px;">';
                }
                if (accepted === "true" || accepted === true) {
                    result += '<span class="column-images" style="color:green">';
                    result += '<i class="fa fa-check" aria-hidden="true" title="Accepté"></i>';
                } else if (accepted === "false" || accepted === false) {
                    result += '<span class="column-images" style="color:red">';
                    result += '<i class="fa fa-times" aria-hidden="true" title="Refusé"></i>';
                }
                result += '</span>';
                if (addDiv) {
                    result += "</div>";
                }
            }
            return result;
        }

        function getFormTypeLabel(callForProject, type) {
            if (type === 'EP') {
                return $translate.instant('canceropoleGsoApp.CFPFormType.EP');
            } else if (type === 'LI') {
                if (callForProject.privateSpace) {
                    return $translate.instant('canceropoleGsoApp.CFPFormType.LI');
                } else {
                    return $translate.instant('canceropoleGsoApp.CFPFormType.FS');
                }
            }
            return "";
        }

        function loadFormField(formField) {
            formField.formName = CustomUtils.removeSpecialChars(formField.form.name);
            formField.online = false;
            if (formField.form.state === 'Online') {
                formField.online = true;
            }
            angular.forEach(formField.groups, function (group, index) {
                group.fields = [];

                //drag & drop
                group.sortableOpts = {
                    containment: ".sortable-container-" + index
                };

                angular.forEach(formField.fields, function (field) {
                    if (field.callForProjectFormGroupId === group.id) {
                        group.fields.push(field);
                    }
                });
            });
            return formField;
        }

        function getFormConfig(callForProject) {
            var formConfig = JSON.parse(callForProject.formConfig);
            if (CustomUtils.isUndefinedOrNull(formConfig)) {
                formConfig = {
                    project: {
                        blockName: 'Projet',
                        title: {
                            label: "Titre",
                            help: "Titre du projet"
                        }
                    },
                    userInfo: {
                        blockName: 'Description administrative du porteur de projet'
                    }
                };
            }
            return formConfig;
        }

        function getExpertiseStateColumn() {
            return {
                text: $translate.instant('sheet.callforproject.expertises.grid.state'),
                datafield: 'state',
                filtertype: 'checkedlist',
                createfilterwidget: function (column, columnElement, widget) {
                    widget.jqxDropDownList({
                        //dropDownWidth: 250,
                        renderer: function (index, label, value) {
                            switch (value) {
                                case "TODO":
                                    return '<span class="badge progress-bar-danger">A faire</span>';
                                case "INPROGRESS":
                                    return '<span class="badge progress-bar-warning">En cours</span>';
                                case "DONE":
                                    return '<span class="badge progress-bar-success">Soumise</span>';
                                default :
                                    return value;
                            }
                        }
                    });
                },
                width: 100,
                cellsrenderer: function (row, column, value, html, cell, resource) {
                    if (CustomUtils.isDefined(value)) {
                        var badges = '';
                        switch (value) {
                            case "TODO":
                                badges += '<span class="badge progress-bar-danger">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                                break;
                            case "INPROGRESS":
                                badges += '<span class="badge progress-badge">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-bar-warning">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                                break;
                            case "DONE":
                                badges += '<span class="badge progress-badge">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                                badges += '&nbsp;<span class="badge progress-bar-success">&nbsp;</span>';
                                break;
                        }

                        return html.replace(value, badges);
                    }
                    return html;
                },
                editable: false,
                cellsalign: 'center',
                align: 'center',
                aggregates: [{
                        '<span class="badge progress-bar-danger">A faire</span>': function (aggregatedValue, currentValue) {
                            if (currentValue === "TODO") {
                                aggregatedValue++;
                            }
                            return aggregatedValue;
                        }
                    }, {
                        '<span class="badge progress-bar-warning">En cours</span>': function (aggregatedValue, currentValue) {
                            if (currentValue === "INPROGRESS") {
                                aggregatedValue++;
                            }
                            return aggregatedValue;
                        }
                    }, {
                        '<span class="badge progress-bar-success">Soumise</span>': function (aggregatedValue, currentValue) {
                            if (currentValue === "DONE") {
                                aggregatedValue++;
                            }
                            return aggregatedValue;
                        }
                    }]
            };
        }

        function getProgressionStepColumn(datafield, callForProject, hidden) {

            var aggregates = [{
                    '<span class="badge progress-bar-danger">Non soumis</span>': function (aggregatedValue, currentValue) {
                        if (currentValue === 'red') {
                            aggregatedValue++;
                        }
                        return aggregatedValue;
                    }
                }, {
                    '<span class="badge progress-bar-success">Soumis</span>': function (aggregatedValue, currentValue) {
                        if (currentValue === 'green') {
                            aggregatedValue++;
                        }
                        return aggregatedValue;
                    }
                }];

            if (callForProject.canSaveSubmission) {
                aggregates.splice(1, 0, {
                    '<span class="badge progress-bar-warning">En cours</span>': function (aggregatedValue, currentValue) {
                        if (currentValue === 'orange') {
                            aggregatedValue++;
                        }
                        return aggregatedValue;
                    }
                });
            }

            return {
                text: $translate.instant('sheet.callforproject.projects.grid.' + datafield),
                datafield: datafield,
                filtertype: 'checkedlist',
                hidden: hidden,
                pinned: true,
                createfilterwidget: function (column, columnElement, widget) {
                    widget.jqxDropDownList({
                        //dropDownWidth: 250,
                        renderer: function (index, label, value) {
                            if (value === 'green') {
                                return '<span class="badge progress-bar-success">Soumis</span>';
                            } else if (value === 'red') {
                                return '<span class="badge progress-bar-danger">Non soumis</span>';
                            } else if (value === 'orange') {
                                return '<span class="badge progress-bar-warning">En cours</span>';
                            } else if (value === null) {
                                return 'N/A';
                            }
                            return value;
                        }
                    });
                },
                width: 110,
                cellsrenderer: function (row, column, value, html, cell, resource) {
                    if (CustomUtils.isDefined(value)) {

                        var date = null;
                        if (column === 'stepOneProgress' && !_.isNil(resource.stepOneDate)) {
                            date = $filter('date')(resource.stepOneDate, 'dd/MM/yyyy HH:mm');
                        } else if (column = 'stepTwoProgress' && !_.isNil(resource.stepTwoDate)) {
                            date = $filter('date')(resource.stepTwoDate, 'dd/MM/yyyy HH:mm');
                        }

                        var badges = '';
                        if (value === 'red') {
                            badges += '<span class="badge progress-bar-danger">&nbsp;</span>';
                            badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                            if (callForProject.canSaveSubmission) {
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                            }
                        } else if (value === 'green') {
                            var title = '';
                            if (date != null) {
                                title = 'Soumis le ' + date;
                            }
                            badges += '<span class="badge progress-badge" title="' + title + '">&nbsp;</span>';
                            if (callForProject.canSaveSubmission) {
                                badges += '&nbsp;<span class="badge progress-badge" title="' + title + '">&nbsp;</span>';
                            }
                            badges += '&nbsp;<span class="badge progress-bar-success" title="' + title + '">&nbsp;</span>';
                        } else if (value === 'orange') {
                            badges += '<span class="badge progress-badge">&nbsp;</span>';
                            badges += '&nbsp;<span class="badge progress-bar-warning">&nbsp;</span>';
                            if (callForProject.canSaveSubmission) {
                                badges += '&nbsp;<span class="badge progress-badge">&nbsp;</span>';
                            }
                        }
                        return html.replace(value, badges);
                    }
                    return html;
                },
                editable: false,
                cellsalign: 'center',
                align: 'center',
                aggregates: aggregates
            };
        }
    }

})();
