/* global _ */

(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('SheetCallForProjectProjectsTabController', SheetCallForProjectProjectsTabController);
    SheetCallForProjectProjectsTabController.$inject = ['$log', '$stateParams', 'JQWidgetsService', 'CustomUtils', '$state', 'callForProject', '$filter', 'Principal', '$q', '$translate', '$http', 'AlertService', 'geosites', 'CallForProjectService', 'ConfirmDialog', '$uibModal', 'DateUtils'];
    function SheetCallForProjectProjectsTabController($log, $stateParams, JQWidgetsService, CustomUtils, $state, callForProject, $filter, Principal, $q, $translate, $http, AlertService, geosites, CallForProjectService, ConfirmDialog, $uibModal, DateUtils) {
        if ($stateParams.tab !== "projects") {
            //update state param
            $stateParams.tab = "projects";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.stats = {};
        vm.callForProject = callForProject;
        vm.axes = callForProject.axes;
        vm.geosites = geosites;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.selectedIndexes = [];
        vm.chartHeight = 50 * vm.axes.length;
        if (vm.chartHeight < 250) {
            vm.chartHeight = 250;
        } else if (vm.chartHeight > 400) {
            vm.chartHeight = 400;
        }

        vm.chartWidth = vm.chartHeight + 100;

        vm.computeStatistics = function (records) {
            vm.stats.totalSubmission = records.length;
            vm.stats.totalSubKeep = 0;
            vm.stats.totalSubFunded = 0;
            vm.stats.totalAskedBudget = 0;
            vm.stats.totalAcceptedBudget = 0;

            var dataPerAxe = {};
            var dataPerSite = {};
            for (var i = 0; i < records.length; i++) {
                var record = records[i];
                if (record.keep) {
                    vm.stats.totalSubKeep++;
                }
                if (record.funded) {
                    vm.stats.totalSubFunded++;
                }
                if (record.axeId) {
                    var axeStats = dataPerAxe[record.axeId];
                    if (angular.isUndefined(axeStats)) {
                        axeStats = {};
                        axeStats.id = record.axeId;
                        axeStats.name = record.axeName;
                        axeStats.nb = 0;
                        axeStats.totalAsked = 0;
                        axeStats.nbAccepted = 0;
                        axeStats.totalAccepted = 0;
                        dataPerAxe[record.axeId] = axeStats;
                    }
                    axeStats.nb++;
                    if (angular.isDefined(record.acceptedBudget)) {
                        axeStats.totalAccepted += record.acceptedBudget;
                        if (record.acceptedBudget > 0) {
                            axeStats.nbAccepted++;
                        }
                    }
                    if (angular.isDefined(record.askedBudget)) {
                        axeStats.totalAsked += record.askedBudget;
                    }
                }
                if (record.geositeId) {
                    var geositeStats = dataPerSite[record.geositeId];
                    if (angular.isUndefined(geositeStats)) {
                        geositeStats = {};
                        geositeStats.id = record.geositeId;
                        geositeStats.name = record.geositeName;
                        geositeStats.nb = 0;
                        geositeStats.totalAsked = 0;
                        geositeStats.nbAccepted = 0;
                        geositeStats.totalAccepted = 0;
                        dataPerSite[record.geositeId] = geositeStats;
                    }
                    geositeStats.nb++;
                    if (angular.isDefined(record.acceptedBudget)) {
                        geositeStats.totalAccepted += record.acceptedBudget;
                        if (record.acceptedBudget > 0) {
                            geositeStats.nbAccepted++;
                        }
                    }
                    if (angular.isDefined(record.askedBudget)) {
                        geositeStats.totalAsked += record.askedBudget;
                    }
                }

                if (record.askedBudget) {
                    vm.stats.totalAskedBudget += record.askedBudget;
                }
                if (record.acceptedBudget) {
                    vm.stats.totalAcceptedBudget += record.acceptedBudget;
                }
            }
            var finalDataPerAxe = [];
            for (var index in dataPerAxe) {
                finalDataPerAxe.push(dataPerAxe[index]);
            }
            var finalDataPerSite = [];
            for (var index in dataPerSite) {
                finalDataPerSite.push(dataPerSite[index]);
            }
            vm.stats.finalDataPerAxe = finalDataPerAxe;
            vm.stats.finalDataPerSite = finalDataPerSite;

            vm.totalLIChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: [{
                        name: $translate.instant('sheet.callforproject.projects.total.liNotKeep'),
                        value: vm.stats.totalSubmission - vm.stats.totalSubKeep
                    }, {
                        name: $translate.instant('sheet.callforproject.projects.total.LIKeep'),
                        value: vm.stats.totalSubKeep
                    }],
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'value'
                    }]
            });

            vm.totalSubChar = vm.stats.totalSubmission;
            if (vm.callForProject.privateSpace) {
                vm.totalSubChar = vm.stats.totalSubKeep;
            }
            vm.totalSubChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: [{
                        name: $translate.instant('sheet.callforproject.projects.total.notKeep'),
                        value: vm.totalSubChar - vm.stats.totalSubFunded
                    }, {
                        name: $translate.instant('sheet.callforproject.projects.total.subFunded'),
                        value: vm.stats.totalSubFunded
                    }],
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'value'
                    }]
            });
            vm.totalBudgetChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: [{
                        name: 'Budget',
                        asked: vm.stats.totalAskedBudget,
                        accepted: vm.stats.totalAcceptedBudget
                    }],
                datatype: 'json',
                datafields: [
                    {name: 'name'},
                    {name: 'asked'},
                    {name: 'accepted'}
                ]
            });

            vm.axeChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: finalDataPerAxe,
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'nb'
                    }, {
                        name: 'totalAsked'
                    }]
            });

            vm.totalAxeChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: finalDataPerAxe,
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'nbAccepted'
                    }, {
                        name: 'totalAccepted'
                    }]
            });

            vm.geositeChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: finalDataPerSite,
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'nb'
                    }, {
                        name: 'totalAsked'
                    }]
            });

            vm.totalGeositeChartSettings.source = new $.jqx.dataAdapter({// jshint ignore:line
                localdata: finalDataPerSite,
                datatype: 'json',
                datafields: [{
                        name: 'name'
                    }, {
                        name: 'nbAccepted'
                    }, {
                        name: 'totalAccepted'
                    }]
            });
        };

        var submissionSource = {
            datatype: "json",
            datafields: [
                {name: 'id', type: 'number'},
                {name: 'date', type: 'date'},
                {name: 'name', type: 'string'},
                {name: 'projectId', type: 'number'},
                {name: 'privateKey', type: 'number'},
                {name: 'keep', type: 'bool'},
                {name: 'funded', type: 'bool'},
                {name: 'acceptedBudget', type: 'number'},
                {name: 'acceptedDuration', type: 'number'},
                {name: 'askedDuration', type: 'number'},
                {name: 'askedBudget', type: 'number'},
                {name: 'personId', type: 'number'},
                {name: 'personFirstName', type: 'String'},
                {name: 'personLastName', type: 'String'},
                {name: 'personName', type: 'String'},
                {name: 'geositeId', type: 'number'},
                {name: 'geositeName', type: 'string'},
                {name: 'axeId', type: 'number'},
                {name: 'axeName', type: 'String'},
                {name: 'stepOneDate', type: 'date'},
                {name: 'stepTwoDate', type: 'date'},
                {name: 'privateInformation', type: 'string'},
                {name: 'nbAttachement', type: 'number'},
                {name: 'nbResponseStepOne', type: 'number'},
                {name: 'nbResponseStepTwo', type: 'number'},
                {name: 'stepOneProgress', type: 'string'},
                {name: 'stepTwoProgress', type: 'string'}
            ],
            id: 'id',
            url: 'api/call-for-projects/project-submissions/' + vm.callForProject.id
        };

        var submissionDataAdapter = new $.jqx.dataAdapter(submissionSource, {
            beforeLoadComplete: function (records) {
                angular.forEach(records, function (record) {
                    record.personName = record.personFirstName + " " + record.personLastName;
                    record.stepOneProgress = null;
                    record.stepTwoProgress = null;
                    if (!_.isNil(record.stepOneDate)) {
                        record.stepOneProgress = 'green';
                    } else if (record.nbResponseStepOne === 0) {
                        record.stepOneProgress = 'red';
                    } else if (!vm.callForProject.canSaveSubmission) {
                        record.stepOneProgress = 'green';
                    } else {
                        record.stepOneProgress = 'orange';
                    }

                    if (record.keep) {
                        if (!_.isNil(record.stepTwoDate)) {
                            record.stepTwoProgress = 'green';
                        } else if (record.nbResponseStepTwo === 0) {
                            record.stepTwoProgress = 'red';
                        } else if (!vm.callForProject.canSaveSubmission) {
                            record.stepTwoProgress = 'green';
                        } else {
                            record.stepTwoProgress = 'orange';
                        }
                    }

                    //correction lorsque les geosites ne sont pas indiqués
                    if (CustomUtils.isUndefinedOrNull(record.geositeName)) {
                        record.geositeName = null;
                    }
                });
            },
            loadComplete: function (result) {
                vm.computeStatistics(result);
                vm.createDonuts = true;
            }
        });

        vm.totalLIChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.LITitle'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'scheme04',
            drawBefore: function (renderer, rect) {
                var sz = renderer.measureText(vm.stats.totalSubmission, 0, {'class': 'chart-inner-text'});
                renderer.text(
                        vm.stats.totalSubmission,
                        rect.x + (rect.width - sz.width) / 2,
                        rect.y + (rect.height - sz.height / 2) / 2,
                        0,
                        0,
                        0,
                        {'class': 'chart-inner-text'}
                );
            },
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'value',
                            labelRadius: 120,
                            initialAngle: 90,
                            radius: 55,
                            innerRadius: 45,
                            centerOffset: 0
                        }]
                }]
        };

        vm.totalSubChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.projectTitle'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'scheme05',
            drawBefore: function (renderer, rect) {
                var sz = renderer.measureText(vm.totalSubChar, 0, {'class': 'chart-inner-text'});
                renderer.text(
                        vm.totalSubChar,
                        rect.x + (rect.width - sz.width) / 2,
                        rect.y + (rect.height - sz.height / 2) / 2,
                        0,
                        0,
                        0,
                        {'class': 'chart-inner-text'}
                );
            },
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'value',
                            labelRadius: 120,
                            initialAngle: 90,
                            radius: 55,
                            innerRadius: 45,
                            centerOffset: 0
                        }]
                }]
        };

        vm.totalBudgetChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.budget'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'scheme02',
            toolTipFormatFunction: function (value, itemIndex, serie) {
                return serie.displayText + ":" + $filter('currency')(value, '€', 0);
            },
            xAxis: {
                dataField: 'name',
                displayText: 'test',
                showLabels: false,
                gridLines: {visible: true},
                flip: false
            },
            valueAxis: {
                formatFunction: function (value) {
                    return $filter('currency')(value, '€', 0);
                }
            },
            seriesGroups: [{
                    type: 'column',
                    columnsMaxWidth: 50,
                    series: [{
                            dataField: 'asked',
                            displayText: $translate.instant('sheet.callforproject.projects.total.askedBudget')
                        }, {
                            dataField: 'accepted',
                            displayText: $translate.instant('sheet.callforproject.projects.total.acceptedBudget')
                        }]
                }]
        };

        vm.axeChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.axeNb'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            //titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'axe',
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'nb',
                            labelRadius: 100,
                            initialAngle: 90,
                            radius: 40,
                            innerRadius: 30,
                            centerOffset: 0,
                            formatFunction: function (value, itemIndex) {
                                var source = vm.axeChartSettings.source;
                                var record = source.getrecords()[itemIndex];
                                if (value > 0) {
                                    return $filter('currency')(record.totalAsked, '€', 0) + ' (' + value + ')';
                                }
                                return value;
                            }
                        }]
                }]
        };

        vm.totalAxeChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.axe'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'axe',
            toolTipFormatFunction: function (value) {
                return $filter('currency')(value, '€', 0);
            },
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'totalAccepted',
                            labelRadius: 120,
                            initialAngle: 90,
                            radius: 40,
                            innerRadius: 30,
                            centerOffset: 0,
                            legendFormatFunction: function (value) {
                                return value;
                            },
                            formatFunction: function (value, itemIndex) {
                                var source = vm.totalAxeChartSettings.source;
                                var record = source.getrecords()[itemIndex];
                                if (value > 0) {
                                    return $filter('currency')(value, '€', 0) + ' (' + record.nbAccepted + ')';
                                }
                                return value;
                            }
                        }]
                }]
        };

        vm.geositeChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.geositeNb'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            //titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'geosite',
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'nb',
                            labelRadius: 120,
                            initialAngle: 90,
                            radius: 45,
                            innerRadius: 35,
                            centerOffset: 0,
                            formatFunction: function (value, itemIndex) {
                                var source = vm.geositeChartSettings.source;
                                var record = source.getrecords()[itemIndex];
                                if (value > 0) {
                                    return $filter('currency')(record.totalAsked, '€', 0) + ' (' + value + ')';
                                }
                                return value;
                            }
                        }]
                }]
        };

        vm.totalGeositeChartSettings = {
            title: $translate.instant('sheet.callforproject.projects.total.geosite'),
            description: '',
            backgroundColor: '#FAFAFA',
            padding: {left: 5, top: 5, right: 5, bottom: 5},
            titlePadding: {left: 5, top: 5, right: 5, bottom: 5},
            showToolTips: true,
            showLegend: true,
            colorScheme: 'geosite',
            seriesGroups: [{
                    type: 'donut',
                    useGradientColors: false,
                    series: [{
                            showLabels: true,
                            displayText: 'name',
                            dataField: 'nbAccepted',
                            labelRadius: 120,
                            initialAngle: 90,
                            radius: 50,
                            innerRadius: 40,
                            centerOffset: 0,
                            legendFormatFunction: function (value) {
                                return value;
                            },
                            formatFunction: function (value, itemIndex, serie, group) {
                                var source = vm.totalGeositeChartSettings.source;
                                var record = source.getrecords()[itemIndex];
                                if (value > 0) {
                                    return $filter('currency')(record.totalAccepted, '€', 0) + ' (' + value + ')';
                                }
                                return value;
                            }
                        }]
                }]
        };

        var linkCellRenderer = function (row, column, value, html, cell, resource) {
            if (value.length > 0) {
                var url = "";
                if (column === 'personLastName' || column === 'personFirstName') {
                    if (CustomUtils.isDefined(resource.personId)) {
                        url = "#/sheet/person/" + resource.personId + "?tab=main";
                    } else {
                        return html;
                    }
                } else if (column === 'name') {
                    url = "#/sheet/project/" + resource.projectId + "?tab=main";
                }

                var link = "<a href=\"" + url + "\" title=\"" + value + "\">" + value + "</a>";
                return html.replace(value, link);
            }
            return '';
        };

        var budgetCellRenderer = function (row, column, value, html, cell, resource) {
            if (value) {
                var newValue = $filter('currency')(value, '€', 0);
                return html.replace(value, newValue);
            }
            return '';
        };

        var attachementCellRenderer = function (row, column, value, html, cell, resource) {
            if (value) {
                var content = '<div title="' + value + '">';
                for (var i = 0; i < value; i++) {
                    content += '<span class="glyphicon glyphicon-paperclip"></span>';
                }
                content += '</div>';
                return html.replace(value, content);
            }
            return '';
        };

        var privateInformationCellRenderer = function (row, column, value, html, cell, resource) {
            if (value) {
                var content = '<div title="' + value + '">';
                content += '<span class="glyphicon glyphicon-comment"></span>';
                content += '</div>';
                return html.replace(value, content);
            }
            return '';
        };

        var privateSpaceRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(value)) {
                var link = '<a href="#/callforproject/' + vm.callForProject.id + '/space?key=' + object.privateKey + '">';
                link += $filter('date')(object.date, 'dd/MM/yyyy HH:mm');
                link += '</a>';
                var result = html.replace(">" + value + "<", ">" + link + "<");
                return result;
            }
            return html;
        };

        var decisionRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isEmpty(value)) {
                var images = CallForProjectService.renderFormDecision(value);
                if (!CustomUtils.isEmpty(images)) {
                    var result = html.replace(value, images);
                    return result;
                }
            }
            return html;
        };

        var decisionCellEditor = function (row, value, editor) {
            var finalTypes = ["true", "false"];
            editor.jqxDropDownList({
                source: finalTypes,
                placeHolder: 'Décision',
                dropDownHeight: 70,
                renderer: function (index, label, value) {
                    return CallForProjectService.renderFormDecision(value, true);
                },
                selectionRenderer: function (event, index, label, value) {
                    return CallForProjectService.renderFormDecision(value, true);
                }
            });
        };

        var aggregateCurrencyRenderer = function (aggregates) {
            var renderstring = '';
            $.each(aggregates, function (key, value) {
                renderstring += '<div style="position: relative; margin: 4px; overflow: hidden;">' + key + ': ' + $filter('currency')(value, '€', 0) + '</div>';
            });
            return renderstring;
        };

        var statusbarheight = 50;
        if (vm.callForProject.canSaveSubmission) {
            statusbarheight += 25;
        }

        vm.submissionGridSettings = {
            width: '100%',
            height: 600,
            source: submissionDataAdapter,
            editable: vm.canEdit,
            columnsresize: true,
            columnsreorder: true,
            sortable: true,
            groupable: true,
            filterable: true,
            showfilterrow: true,
            altrows: true,
            showstatusbar: true,
            statusbarheight: statusbarheight,
            showaggregates: true,
            selectionmode: 'checkbox',
            localization: JQWidgetsService.getLocalization($translate.use()),
            autosavestate: true,
            //autoloadstate: true,
            columns: [{
                    text: 'Id',
                    datafield: 'id',
                    width: 50,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.date'),
                    datafield: 'date',
                    filtertype: 'date',
                    cellsrenderer: privateSpaceRenderer,
                    width: 135,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true,
                    aggregates: [{
                            '<b>Déposés</b>': function (aggregatedValue) {
                                return ++aggregatedValue;
                            }
                        }]
                },
                CallForProjectService.getProgressionStepColumn('stepOneProgress', vm.callForProject, false),
                {
                    text: $translate.instant('sheet.callforproject.projects.grid.keep'),
                    pinned: true,
                    datafield: 'keep',
                    width: 80,
                    cellsrenderer: decisionRenderer,
                    columntype: 'dropdownlist',
                    createEditor: decisionCellEditor,
                    hidden: !vm.callForProject.privateSpace,
                    filtertype: 'bool',
                    cellsalign: 'center',
                    align: 'center',
                    aggregates: [{
                            '<b>Retenu</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue++;
                                }
                                return aggregatedValue;
                            }
                        }]
                },
                CallForProjectService.getProgressionStepColumn('stepTwoProgress', vm.callForProject, !vm.callForProject.privateSpace),
                {
                    text: $translate.instant('sheet.callforproject.projects.grid.funded'),
                    datafield: 'funded',
                    width: 80,
                    cellsrenderer: decisionRenderer,
                    columntype: 'dropdownlist',
                    createEditor: decisionCellEditor,
                    filtertype: 'bool',
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true,
                    aggregates: [{
                            '<b>Financé</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue++;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.lastName'),
                    datafield: 'personLastName',
                    width: 120,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    pinned: true
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.firstName'),
                    datafield: 'personFirstName',
                    width: 110,
                    cellsrenderer: linkCellRenderer,
                    editable: false,
                    pinned: true
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.projectName'),
                    datafield: 'name',
                    minwidth: 300,
                    editable: false,
                    cellsrenderer: linkCellRenderer
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.nbAttachement'),
                    datafield: 'nbAttachement',
                    width: 70,
                    editable: false,
                    filtertype: 'number',
                    cellsrenderer: attachementCellRenderer
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.privateInformation'),
                    datafield: 'privateInformation',
                    width: 60,
                    editable: false,
                    filtertype: 'checkedlist',
                    createfilterwidget: function (column, columnElement, widget) {
                        widget.jqxDropDownList({
                            //dropDownWidth: 250,
                            renderer: function (index, label, value) {
                                if (index === 0) {
                                    return value;
                                }
                                if (CustomUtils.isEmpty(value)) {
                                    return '&nbsp;';
                                }
                                return '<span class="glyphicon glyphicon-comment"></span>';
                            }
                        });
                    },
                    cellsalign: 'center',
                    align: 'center',
                    cellsrenderer: privateInformationCellRenderer
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.askedBudget'),
                    columngroup: 'budget',
                    datafield: 'askedBudget',
                    filtertype: 'number',
                    columntype: 'numberinput',
                    width: 130,
                    cellsrenderer: budgetCellRenderer,
                    align: 'center',
                    cellsalign: 'center',
                    aggregatesrenderer: aggregateCurrencyRenderer,
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue += currentValue;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.acceptedBudget'),
                    columngroup: 'budget',
                    datafield: 'acceptedBudget',
                    filtertype: 'number',
                    columntype: 'numberinput',
                    width: 130,
                    cellsrenderer: budgetCellRenderer,
                    align: 'center',
                    cellsalign: 'center',
                    aggregatesrenderer: aggregateCurrencyRenderer,
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue += currentValue;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.askedDuration'),
                    columngroup: 'duration',
                    datafield: 'askedDuration',
                    filtertype: 'number',
                    columntype: 'numberinput',
                    width: 110,
                    align: 'center',
                    cellsalign: 'center',
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue += currentValue;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.acceptedDuration'),
                    columngroup: 'duration',
                    datafield: 'acceptedDuration',
                    filtertype: 'number',
                    columntype: 'numberinput',
                    width: 100,
                    align: 'center',
                    cellsalign: 'center',
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue += currentValue;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.axe'),
                    datafield: 'axeName',
                    width: 110,
                    align: 'center',
                    columntype: 'dropdownlist',
                    createEditor: function (row, value, editor) {
                        editor.jqxDropDownList({
                            source: vm.axes,
                            placeHolder: 'Axe',
                            autoDropDownHeight: true,
                            displayMember: 'name',
                            valueMember: 'id'
                        });
                    },
                    filtertype: 'checkedlist',
                    cellsalign: 'center'
                }, {
                    text: $translate.instant('sheet.callforproject.projects.grid.geosite'),
                    datafield: 'geositeName',
                    width: 100,
                    align: 'center',
                    columntype: 'dropdownlist',
                    createEditor: function (row, value, editor) {
                        editor.jqxDropDownList({
                            source: vm.geosites,
                            placeHolder: 'Site',
                            autoDropDownHeight: true,
                            //dropDownHeight: 145,
                            displayMember: 'name',
                            valueMember: 'id'
                        });
                    },
                    filtertype: 'checkedlist',
                    cellsalign: 'center'
                }],
            columngroups: [{
                    text: 'Financements (en €)',
                    align: 'center',
                    name: 'budget'
                }, {
                    text: 'Durée (en mois)',
                    align: 'center',
                    name: 'duration'
                }],
            created: function (event) {
                vm.grid = event.instance;
                vm.selectedIndexes = vm.grid.getselectedrowindexes();

                var state = vm.grid.getstate();
                var keepColumn = state.columns['keep'];
                var stepTwoColumn = state.columns['stepTwoSubmitted'];
                if (vm.callForProject.privateSpace) {
                    //deux étapes
                    if (!_.isNil(keepColumn)) {
                        keepColumn.hidden = false;
                    }
                    if (!_.isNil(stepTwoColumn)) {
                        stepTwoColumn.hidden = !vm.callForProject.managed;
                    }
                } else {
                    //une étape
                    if (!_.isNil(keepColumn)) {
                        keepColumn.hidden = true;
                    }
                    if (!_.isNil(stepTwoColumn)) {
                        stepTwoColumn.hidden = true;
                    }
                }
                vm.grid.loadstate(state);
            },
            rowselect: function () {
                vm.selectedIndexes = vm.grid.getselectedrowindexes();
            },
            rowunselect: function () {
                vm.selectedIndexes = vm.grid.getselectedrowindexes();
            },
            cellclick: function (event) {
                if (event.args) {
                    var args = event.args;
                    var datafield = args.datafield;
                    if (datafield === 'stepOneProgress') {
                        vm.rollbackSubmission(args.row.bounddata, 1);
                    } else if (datafield === 'stepTwoProgress') {
                        vm.rollbackSubmission(args.row.bounddata, 2);
                    }
                }
            },
            cellvaluechanged: function (event) {
                if (event.args) {
                    var args = event.args;
                    var submission = vm.submissionGridSettings.source.records[args.rowindex];
                    for (var i = 0; i < vm.axes.length; i++) {
                        var axe = vm.axes[i];
                        if (axe.name === submission.axeName) {
                            submission.axeId = axe.id;
                            break;
                        }
                    }

                    for (var i = 0; i < vm.geosites.length; i++) {
                        var geosite = vm.geosites[i];
                        if (geosite.name === submission.geositeName) {
                            submission.geositeId = geosite.id;
                            break;
                        }
                    }

                    if (CustomUtils.isEmpty(submission.keep)) {
                        submission.keep = null;
                    }

                    var promise = $q.when(true);
                    promise = promise.then(function () {
                        return $http.put('api/submission/updateInformations/', submission);
                    });
                    promise = promise.then(function () {
                        AlertService.info("Modifications enregistrées");
                        vm.submissionGridSettings.source.dataBind();
                    });
                    if (args.datafield === "keep" && !CustomUtils.isEmpty(submission.keep)) {
                        var message = "Le projet " + submission.name + " de " + submission.personName + " n'a pas été retenu.<br/><br/>Voulez-vous envoyer le mail de refus ?";
                        if (submission.keep === "true") {
                            message = "Le projet " + submission.name + " de " + submission.personName + " a été retenu.<br/><br/>Voulez-vous envoyer le mail de confirmation ?";
                        }
                        ConfirmDialog.openYesNo("Confirmer l'envoi de mail.",
                                message,
                                function (userChoise) {
                                    if (userChoise) {
                                        var type = "B_LIAccepted";
                                        if (submission.keep === "false") {
                                            type = "C_LIRefused";
                                        }

                                        vm.sendMailToServer(submission.id, type, submission.keep);
                                    }
                                });
                    } else if (args.datafield === "funded" && !CustomUtils.isEmpty(submission.funded)) {
                        var message = "Le projet " + submission.name + " de " + submission.personName + " n'a pas été retenu.<br/><br/>Voulez-vous envoyer le mail de refus ?";
                        if (submission.funded === "true") {
                            message = "Le projet " + submission.name + " de " + submission.personName + " a été retenu.<br/><br/>Voulez-vous envoyer le mail de confirmation ?";
                        }
                        ConfirmDialog.openYesNo("Confirmer l'envoi de mail.",
                                message,
                                function (userChoise) {
                                    if (userChoise) {
                                        var funded = (submission.funded === 'true');
                                        var type = CallForProjectService.getMailStepFundedValue(vm.callForProject, funded);
                                        vm.sendMailToServer(submission.id, type, submission.keep);
                                    }
                                });
                    }
                }
            }
        };

        vm.sendMailToServer = function (idSubmission, type, decision) {
            var json = {
                id: idSubmission,
                type: type
            };
            $log.info("Sending mail ", json);
            $http.post("api/call-for-project/submission/sendmail", json).then(function (response) {
                var i18nMessage = response.headers("x-canceropolegsoapp-alert");
                var i18nParam = response.headers("x-canceropolegsoapp-params");
                var message = $translate.instant(i18nMessage, {value: i18nParam});
                AlertService.info(message);
            }, function (response) {
                var i18nMessage = response.headers("x-canceropolegsoapp-alert");
                var i18nParam = response.headers("x-canceropolegsoapp-params");
                var mailType = CallForProjectService.getMailStepLabelValue(vm.callForProject, i18nParam);
                var message = $translate.instant(i18nMessage, {value: mailType});
                AlertService.error(message);
            });
        };

        vm.refresh = function () {
            vm.grid.updatebounddata();
        };

        vm.removeSubmission = function () {
            var submissions = vm.getSelectedSubmissions();
            if (submissions.length === 1) {
                var selectedSubmission = submissions[0];
                $uibModal.open({
                    templateUrl: 'app/sheet/callforproject/dialog/remove-submission.dialog.html',
                    controller: 'RemoveSubmissionDialogController',
                    controllerAs: 'ctrl',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                        callForProject: function () {
                            return vm.callForProject;
                        },
                        submission: function () {
                            return selectedSubmission;
                        },
                        expertises: ['$http', function () {
                                return $http.get('api/experts/callforproject/' + vm.callForProject.id + "?submission=" + selectedSubmission.id).then(function (response) {
                                    return response.data;
                                });
                            }]
                    }
                }).result.then(function () {
                    vm.refresh();
                });
            }
        };

        vm.getSelectedSubmissions = function () {
            vm.selectedIndexes = vm.grid.getselectedrowindexes();
            var result = [];
            angular.forEach(vm.selectedIndexes, function (selectedIndex) {
                var submission = submissionDataAdapter.records[selectedIndex];
                result.push(submission);
            });
            return result;
        };

        vm.exportSubmissions = function (type) {
            var ext = type;
            if (type === 'synthesis') {
                ext = 'xls';
            }

            var exportType = {
                type: type,
                fileName: 'Submissions.zip'
            };
            var submissions = vm.getSelectedSubmissions();
            if (submissions.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/sheet/callforproject/dialog/export-submissions.dialog.html',
                    controller: 'ExportSubmissionsDialogController',
                    controllerAs: 'ctrl',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                        submissions: function () {
                            return submissions;
                        },
                        exportType: function () {
                            return exportType;
                        },
                        callForProject: function () {
                            return vm.callForProject;
                        },
                        formTypes: function () {
                            return ['LI', 'EP'];
                        },
                        formFieldsList: ['$http', '$stateParams', 'DateUtils', function ($http, $stateParams, DateUtils) {
                                return $http.get("api/call-for-projects/" + $stateParams.id + "/form/all").then(function (response) {
                                    if (response.status === 200) {
                                        return response.data;
                                    } else {
                                        return [];
                                    }
                                });
                            }]
                    }
                });
            }
        };

        vm.goToForm = function () {
            $state.go("callForProjectSubscription", {id: vm.callForProject.id});
        };

        vm.rollbackSubmission = function (data, step) {
            var canRollbackDate = null;
            if (step === 1 &&
                    !_.isNil(data.stepOneDate) &&
                    _.isNil(data.stepTwoDate)) {
                canRollbackDate = data.stepOneDate;
            } else if (step === 2 &&
                    !_.isNil(data.stepTwoDate)) {
                canRollbackDate = data.stepTwoDate;
            }

            if (canRollbackDate !== null) {
                var title = "Permettre la modification de la soumission par le porteur";
                var msg = 'Voulez-vous permettre au porteur de modifier la soumission ?<br/>';
                msg += 'Porteur : ' + data.personName + '<br/>';
                msg += 'Date de la soumission : ' + $filter('date')(canRollbackDate, 'dd/MM/yyyy HH:mm');
                ConfirmDialog.openYesNo(title, msg, function (response) {
                    if (response === true) {
                        var url = 'api/call-for-projects/submission/rollback/' + data.id + '/' + step
                        $http.put(url).then(function () {
                            vm.refresh();
                        }, function () {
                            vm.refresh();
                        });
                    }
                });
            }
        };
    }
})();