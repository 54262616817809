(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('SheetWindowEditor', SheetWindowEditor);

    SheetWindowEditor.$inject = ['JQWidgetsService'];

    function SheetWindowEditor(JQWidgetsService) {

        this.underEdition = false;

        this.edit = function () {
            this.underEdition = true;
            var height = $(".sheet-bloc").height();
            if (height < 300) {
                height = 300;
            }
            this.editorSettings.height = height;
        };

        this.saveEdition = function () {
            if (this.saveAction) {
                this.saveAction(this.winId);
            }
            this.underEdition = false;
        };

        this.cancelEdition = function () {
            if (this.cancelAction) {
                this.cancelAction(this.winId);
            }
            this.underEdition = false;
        };

        this.editorSettings = {
            width: '100%',
            height: 300,
            tools: JQWidgetsService.getDefaultAdminEditorTools(),
            createCommand: JQWidgetsService.getEditorCommand(this.ngModel)
        };

    }
})();