(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LinkRegistrationPersonDialogController', LinkRegistrationPersonDialogController);

    LinkRegistrationPersonDialogController.$inject = ['$uibModalInstance', '$log', 'PersonSearch', 'abstractSpeakers', 'JQWidgetsService', '$translate', 'CustomUtils', 'toSearch'];

    function LinkRegistrationPersonDialogController($uibModalInstance, $log, PersonSearch, abstractSpeakers, JQWidgetsService, $translate, CustomUtils, toSearch) {

        var ctrl = this;
        ctrl.associateAbstracts = true;

        ctrl.speakers = abstractSpeakers.data;
        ctrl.toSearch = toSearch;

        angular.forEach(ctrl.speakers, function (result) {
            result.name = result.speaker.firstName + " " + result.speaker.lastName;
        });

        ctrl.person = null;

        $log.info(ctrl.speakers);

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    ctrl.result = result;
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
                ctrl.personComboBox.val(ctrl.toSearch);
                ctrl.personComboBoxSettings.search(ctrl.toSearch);
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var person = item.originalItem;
                }
            },
            unselect: function () {
                ctrl.personId = null;
            },
            change: function () {
                var selection = ctrl.personComboBox.getSelectedItem();
                if (selection === null) {
                    ctrl.personId = null;
                }
            }
        };

        ctrl.speakerSource = {
            localdata: ctrl.speakers,
            datafields: [{
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'personId',
                    type: 'number',
                    map: 'speaker>personId'
                }, {
                    name: 'abstractTitle',
                    type: 'string',
                    map: 'abstract>title'
                }]
        };

        ctrl.speakersSettings = {
            source: ctrl.speakerSource,
            enabletooltips: true,
            altrows: true,
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            selectionmode: 'checkbox',
            height: 140,
            sortable: true,
            columns: [{
                    text: 'Nom',
                    datafield: 'name',
                    width: 160,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.personId)) {
                            var link = '<span style="color:#357BB7" title="Associé à la personne id=' + object.personId + ' ">';
                            link += object.name;
                            link += "</span>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Titre',
                    datafield: 'abstractTitle'
                }
            ],
            created: function (event) {
                ctrl.speakersGrid = event.instance;
            }
        };

        ctrl.onSelect = function (person) {
            ctrl.person = person;
        };

        ctrl.ok = function () {
            var abstractPersonIds = [];
            if (!CustomUtils.isUndefinedOrNull(ctrl.speakersGrid)) {
                angular.forEach(ctrl.speakersGrid.getselectedrowindexes(), function (index) {
                    var abstractPerson = ctrl.speakers[index];
                    abstractPersonIds.push(abstractPerson.speaker.id);
                });
            }
            $uibModalInstance.close({
                abstractPersonIds: abstractPersonIds,
                person: ctrl.person
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();