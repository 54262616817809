(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .controller('ParticipationGridController', ParticipationGridController);
    ParticipationGridController.$inject = ['$translate', '$translatePartialLoader', '$filter', 'CustomUtils', '$log', 'JQWidgetsService', 'DateUtils', 'AlertService', '$q', '$http', '$scope'];
    function ParticipationGridController($translate, $translatePartialLoader, $filter, CustomUtils, $log, JQWidgetsService, DateUtils, AlertService, $q, $http, $scope) {

        $translatePartialLoader.addPart('sheet');
        $translate.refresh();

        var ctrl = this;

        if (CustomUtils.isUndefinedOrNull(ctrl.autosavestate)) {
            ctrl.autosavestate = false;
        }
        if (CustomUtils.isUndefinedOrNull(ctrl.height)) {
            ctrl.height = 600;
        }

        ctrl.sessionIdChange = function (sessionId) {
            $log.info("sessionIdChange", sessionId);

            ctrl.source.data = {
                session: sessionId
            };
            ctrl.grid.source = getDataAdapter();
            ctrl.grid.updatebounddata();
        };

        ctrl.source = {
            datatype: "json",
            url: "api/event-registrations/main-event/" + ctrl.mainEventId,
            data: {
                session: ctrl.sessionId
            },
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'firstName',
                    type: 'string'
                }, {
                    name: 'lastName',
                    type: 'string'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'metierName',
                    type: 'string'
                }, {
                    name: 'statusName',
                    type: 'string'
                }, {
                    name: 'city',
                    type: 'string'
                }, {
                    name: 'societyName',
                    type: 'string'
                }, {
                    name: 'societyId',
                    type: 'number'
                }, {
                    name: 'abstractCount',
                    type: 'number'
                }, {
                    name: 'confirmed',
                    type: 'bool'
                }, {
                    name: 'personId',
                    type: 'number'
                }, {
                    name: 'registrationDate',
                    type: 'date'
                }, {
                    name: 'totalPrice',
                    type: 'number'
                }, {
                    name: 'email',
                    type: 'string'
                }, {
                    name: 'key',
                    type: 'number'
                }, {
                    name: 'deletedAt',
                    type: 'date'
                }, {
                    name: 'confirmedPresence',
                    type: 'bool'
                }
            ],
            sortcolumn: 'registrationDate',
            sortdirection: 'desc',
            id: 'id'
        };

        var getDataAdapter = function () {
            return new $.jqx.dataAdapter(ctrl.source, {
                beforeLoadComplete: function (records) {
                    angular.forEach(records, function (record) {
                        record.name = record.firstName + " " + record.lastName;
                    });
                }
            });
        };

        var nameRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.personId)) {
                var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
                link += value;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };
        var societyRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(object.societyId)) {
                var link = '<a href="#/sheet/society/' + object.societyId + '?tab=main">';
                link += object.societyName;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };
        var privateSpaceRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(value)) {
                var privateSpaceId = object.key;
                var link = '';
                if (!CustomUtils.isUndefinedOrNull(object.deletedAt)) {
                    var tooltip = "Supprimée le " + $filter("date")(object.deletedAt, 'dd/MM/yyyy');
                    link += '<span class="text-danger">';
                    link += '<i class="fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" title="' + tooltip + '"></i>&nbsp;';
                    link += '</span>';
                }
                link += '<a href="#/manifestation/' + ctrl.mainEventId + '/space?key=' + privateSpaceId + '">';
                link += $filter('date')(object.registrationDate, 'dd/MM/yyyy HH:mm');
                link += '</a>';
                var result = html.replace(">" + value + "<", ">" + link + "<");
                return result;
            }
            return html;
        };
        var priceRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(value)) {
                var newValue = $filter('currency')(value, '€', 0);
                var result = html.replace(value, newValue);
                return result;
            }
            return html;
        };
        var abstractRenderer = function (row, column, values, html, cell, object) {
            var content = "";
            for (var i = 0; i < values; i++) {
                content += '<span class="glyphicon glyphicon-paperclip"></span>';
            }
            var result = html.replace(values, content);
            return result;
        };

        ctrl.settings = {
            source: getDataAdapter(),
            sortable: true,
            filterable: true,
            showfilterrow: true,
            altrows: true,
            editable: ctrl.editable,
            autosavestate: ctrl.autosavestate,
            autoloadstate: ctrl.autosavestate,
            groupable: true,
            selectionmode: 'checkbox',
            width: "100%",
            height: ctrl.height,
            columnsresize: true,
            columnsreorder: true,
            showstatusbar: true,
            statusbarheight: 25,
            showaggregates: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            columns: [{
                    text: 'Id',
                    datafield: 'id',
                    width: 50,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true,
                    editable: false
                }, {
                    text: "Espace personnel",
                    datafield: 'registrationDate',
                    filtertype: 'date',
                    cellsrenderer: privateSpaceRenderer,
                    //cellsformat: 'dd-MMMM-yyyy',
                    width: 135,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true,
                    aggregates: [{
                            '<b>Participants</b>': function (aggregatedValue) {
                                return ++aggregatedValue;
                            }
                        }]
                }, {
                    text: $translate.instant('sheet.mainevent.participations.lastName'),
                    datafield: 'lastName',
                    //width: "200",
                    cellsrenderer: nameRenderer,
                    width: 135,
                    pinned: true,
                    editable: false
                }, {
                    text: $translate.instant('sheet.mainevent.participations.firstName'),
                    datafield: 'firstName',
                    //width: "200",
                    cellsrenderer: nameRenderer,
                    width: 135,
                    pinned: true,
                    editable: false
                }, {
                    text: $translate.instant('sheet.mainevent.participations.metier'),
                    datafield: 'metierName',
                    width: 140,
                    filtertype: 'checkedlist',
                    editable: false
                }, {
                    text: $translate.instant('sheet.mainevent.participations.status'),
                    datafield: 'statusName',
                    width: 140,
                    filtertype: 'checkedlist',
                    editable: false
                }, {
                    text: $translate.instant('sheet.mainevent.participations.city'),
                    datafield: 'city',
                    width: 160,
                    editable: false
                }, {
                    text: $translate.instant('sheet.mainevent.participations.society'),
                    datafield: 'societyName',
                    minwidth: 200,
                    cellsrenderer: societyRenderer,
                    editable: false
                }, {
                    text: "Communications",
                    datafield: 'abstractCount',
                    width: 70,
                    cellsalign: 'right',
                    align: 'right',
                    cellsrenderer: abstractRenderer,
                    editable: false,
                    filterable: false,
                    sortable: true,
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue, datafield, object) {
                                aggregatedValue += currentValue;
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: "Tarif",
                    datafield: 'totalPrice',
                    width: 80,
                    cellsrenderer: priceRenderer,
                    editable: true,
                    cellsalign: 'right',
                    columntype: 'numberinput',
                    align: 'right',
                    createeditor: function (row, cellvalue, editor) {
                        editor.jqxNumberInput({
                            decimalDigits: 0,
                            digits: 5,
                            min: 0
                        });
                    },
                    validation: function (cell, value) {
                        if (value < 0) {
                            return {
                                result: false,
                                message: "Le prix doit être positif ou nul"
                            };
                        }
                        return true;
                    },
                    //cellsformat: 'c2',
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                if (currentValue) {
                                    aggregatedValue += currentValue;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: 'Confirmé',
                    datafield: 'confirmed',
                    columntype: 'checkbox',
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'bool',
                    width: 90,
                    editable: ctrl.editable,
                    aggregates: [{
                            '<b>Confirmés</b>': function (aggregatedValue, currentValue) {
                                if (currentValue === true) {
                                    aggregatedValue++;
                                }
                                return aggregatedValue;
                            }
                        }]
                }, {
                    text: 'Présence',
                    datafield: 'confirmedPresence',
                    columntype: 'checkbox',
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'bool',
                    width: 90,
                    editable: ctrl.editable,
                    aggregates: [{
                            '<b>Présences</b>': function (aggregatedValue, currentValue) {
                                if (currentValue === true) {
                                    aggregatedValue++;
                                }
                                return aggregatedValue;
                            }
                        }]
                }
            ],
            created: function (args) {
                ctrl.grid = args.instance;
            },
            cellvaluechanged: function (event) {
                if (event.args) {
                    var args = event.args;
                    $log.info("args=", args);
                    var eventRegistration = ctrl.settings.source.records[args.rowindex];
                    var promise = $q.when(true);

                    if (CustomUtils.isUndefinedOrNull(eventRegistration.confirmed)) {
                        eventRegistration.confirmed = false;
                    }

                    promise = promise.then(function () {
                        return $http.put("api/event-registrations/confirmOrUpdatePrice", {
                            id: eventRegistration.id,
                            total: eventRegistration.totalPrice,
                            confirmed: eventRegistration.confirmed,
                            confirmedPresence: eventRegistration.confirmedPresence
                        });
                    });
                    promise = promise.then(function () {
                        AlertService.info("Modifications enregistrées");
                        ctrl.settings.source.dataBind();
                    });
                    if (args.datafield === "confirmed" && eventRegistration.confirmed) {
                        //mail de confirmation
                        $log.info("Sending confirmation mail to ", eventRegistration.email);
                        promise = promise.then(function () {
                            return $http({
                                method: "GET",
                                url: "api/event-registrations/" + eventRegistration.id + "/send-confirm-registration-mail"
                            });
                        });
                        promise = promise.then(function () {
                            AlertService.info("Un mail de confirmation a été envoyé à " + eventRegistration.email);
                            ctrl.settings.source.dataBind();
                        });
                    }
                }
            }
        };

        if (ctrl.rowselect) {
            ctrl.settings.rowselect = ctrl.rowselect;
        }
        if (ctrl.rowunselect) {
            ctrl.settings.rowunselect = ctrl.rowunselect;
        }

        //extend settings with directiveSettings
        //angular.merge(ctrl.settings, directiveSettings);
    }
})();