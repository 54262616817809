(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('canceropoleGsoApp')
        .constant('VERSION', "0.0.8")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_DATE', "2018-6-11 14:03:07")
;
})();
