(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .factory('translationHandler', translationHandler);

    translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate', 'JhiLanguageService'];

    function translationHandler($rootScope, $window, $state, $translate, JhiLanguageService) {
        return {
            initialize: initialize,
            updateTitle: updateTitle
        };

        function initialize() {
            // if the current translation changes, update the window title
            var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function () {
                updateTitle();
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, options) {
                //certaine page sont dans une langue différente.
                //C'est le controller de ces pages qui change la langue.
                //il faut donc revenir à la langue par défaut.
                JhiLanguageService.changeToPreferedLanguage();
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null) {
                    translateChangeSuccess();
                }
            });
        }

        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        function updateTitle(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        }
    }
})();
