(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .factory('SearchService', SearchService);
    SearchService.$inject = ['$resource', 'localStorageService', '$log', '$state', 'DataUtils', 'SearchFilterService'];
    function SearchService($resource, localStorageService, $log, $state, DataUtils, SearchFilterService) {
        var SEARCH_KEY_STORAGE = "search";

        var resource = $resource('api/_search', {}, {
            'query': {
                method: 'POST'
            }
        });

        var sort = [{
                'sortBy': {
                    order: 'asc',
                    unmapped_type: 'string'
                }
            },
            {
                'sortBy2': {
                    order: 'asc',
                    unmapped_type: 'string'
                }
            }
        ];

        var service = {
            searching: false,
            selection: [],
            query: null,
            fields: ["title^10", "themes^8", "abstractText^2", "firstName^5", "lastName^10", "name^10", "shortName^20", "webSite^3", "_all"],
            page: 0,
            filters: [],
            aggregationList: [],
            pageSize: 20,
            newSearch: true,
            displayResult: false,
            requestTime: 0,
            total: 0,
            resultList: [],
            search: search,
            loadFirstPage: loadFirstPage,
            loadPage: loadPage,
            loadSearch: loadSearch,
            saveSearch: saveSearch,
            makeSearch: makeSearch,
            toggleFilter: toggleFilter,
            isFiltered: isFiltered,
            getJsonQuery: getJsonQuery,
            exportItems: exportItems,
            exportSelection: exportSelection,
            exportAll: exportAll,
            sortBy: 'pertinence',
            sort: sort,
            getCurrentTypeFilter: getCurrentTypeFilter,
            loadSimpleFilter: loadSimpleFilter,
            getFilterValue: getFilterValue
        };

        return service;

        function loadSearch() {
            var lastSearchStr = localStorageService.get(SEARCH_KEY_STORAGE);
            if (lastSearchStr) {
                this.resultList = [];
                this.searchResult = null;
                var lastSearch = angular.fromJson(lastSearchStr);
                angular.extend(this, lastSearch);
            }
        }

        function saveSearch() {
            var search = {
                query: this.query,
                filters: this.filters,
                orderBy: this.orderBy
            };
            localStorageService.add(SEARCH_KEY_STORAGE, angular.toJson(search));
        }

        function search() {
            if (this.query && this.searching === false) {
                this.searching = true;
                $log.debug("query=" + this.query);
                var query = this.getJsonQuery();

                this.makeSearch(query);
                this.saveSearch();
            }
        }

        function getAggs() {
            var aggs = _.cloneDeep(SearchFilterService.all);

            var currentTypeFilter = service.getCurrentTypeFilter();
            if (!_.isNil(currentTypeFilter)) {
                _.extend(aggs._type.aggs, SearchFilterService[currentTypeFilter]);
            }
            return aggs;
        }


        function getJsonQuery() {
            $log.debug("query=" + this.query);
            var json = {
                from: this.page,
                size: this.pageSize,
                query: {
                    bool: {
                        must: [{
                                query_string: {
                                    query: this.query,
                                    fields: this.fields
                                }
                            }]
                    }
                }
            };
            json.aggs = getAggs();

            if (!_.isEmpty(Object.keys(this.filters))) {
                json.query.bool.must = json.query.bool.must.concat(this.filters);
                console.log(json.filter);
            }
            if (this.sortBy === 'name') {
                json.sort = this.sort;
            }
            $log.info("json=", json);
            return json;
        }

        function makeSearch(query) {
            var startTime = new Date().getTime();
            var that = this;
            return resource.query(query, function (result) {
                that.searching = false;
                that.requestTime = new Date().getTime() - startTime;
                that.searchResult = result;
                that.loadSimpleFilter();
            }, function (response) {
                that.searching = false;
                $log.error(response);
            });
        }

        function loadFirstPage() {
            this.newSearch = true;
            this.page = 0;
            this.selection = [];
            this.search();
            $state.go("search");
        }

        function loadPage(page) {
            if (this.searching === false) {
                this.page = 0;
                if (page) {
                    this.page = page;
                }
                $log.info("page=", this.page);
                this.search();
            }
        }

        function toggleFilter(filterTypeName, filterKeyName, filterValue) {
            this.selection = [];
            var isFiltered = this.isFiltered(filterTypeName, filterKeyName, filterValue, true);
            if (isFiltered === null) {
                //le filtre n'existe pas, il faut l'ajouter.
                var filter = {};
                filter[filterTypeName] = {};
                filter[filterTypeName][filterKeyName] = [filterValue];
                this.filters.push(filter);
            } else if (_.isArray(isFiltered)) {
                //le filtre existe mais pas la valeur a filtrer
                isFiltered.push(filterValue);
            }

            $log.info("filters=", this.filters);
            this.loadFirstPage();
        }

        function isFiltered(filterTypeName, filterKeyName, filterValue, removeIfExists) {
            var filterKey = null;
            for (var i = 0; i < this.filters.length; i++) {
                var filter = this.filters[i];
                var filterType = filter[filterTypeName];
                if (!_.isNil(filterType)) {
                    var aFilterKey = filterType[filterKeyName];
                    if (!_.isNil(aFilterKey)) {
                        filterKey = aFilterKey;
                        var indexOf = _.indexOf(filterKey, filterValue);
                        if (indexOf !== -1) {
                            if (removeIfExists) {
                                if (filterKeyName === '_type') {
                                    this.filters = [];
                                } else {
                                    filterKey.splice(indexOf, 1);
                                    if (_.isEmpty(filterKey)) {
                                        this.filters.splice(i, 1);
                                    }
                                }
                            }
                            return true;
                        }
                    }
                }
            }
            return filterKey;
        }

        function getCurrentTypeFilter() {

            var filter = _.find(service.filters, function (filter) {
                if (!_.isNil(filter.terms) && !_.isEmpty(filter.terms._type)) {
                    return true;
                }
                return false;
            });
            if (!_.isNil(filter)) {
                return filter.terms._type[0];
            }
            return null;
        }

        function exportAll() {
            var query = this.getJsonQuery();
            query.from = 0;
            query.size = 10000;
            var that = this;
            return resource.query(query, function (result) {
                var items = result.hits.hits;
                that.exportItems(items);
            }, function (response) {
                $log.error(response);
            });
        }

        function exportSelection() {
            if (this.selection.length === 0) {
                this.exportAll();
            } else {
                this.exportItems(this.selection);
            }
        }

        function exportItems(items) {
            var toSend = [];
            angular.forEach(items, function (item) {
                var type = null;
                var itemType = item.type ? item.type : item._type;
                var itemId = item.id ? item.id : item._id;

                for (var i = 0; i < toSend.length; i++) {
                    var aType = toSend[i];
                    if (aType.type === itemType) {
                        type = aType;
                        break;
                    }
                }
                if (type === null) {
                    type = {
                        type: itemType,
                        ids: []
                    };
                    toSend.push(type);
                }
                type.ids.push(itemId);
            });

            $log.info(toSend);
            DataUtils.downloadFileFromPostUrl("api/search/export", toSend, "export.xls");
        }

        function loadSimpleFilter() {
            this.simpleFilters = [];
            var filters = this.filters;

            var that = this;
            _.each(filters, function (filter) {
                var terms = filter.terms;
                if (!_.isNil(terms)) {
                    var key = Object.keys(terms)[0];
                    that.simpleFilters.push({
                        field: key,
                        values: terms[key]
                    });
                }
            });
        }

        function getFilterValue(filter, value) {
            var currentTypeFilter = service.getCurrentTypeFilter();
            return SearchFilterService.getFilterValue(filter, value, currentTypeFilter);
        }
    }
})();
