(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'CustomUtils', '$timeout', '$rootScope', 'DateUtils', 'callForProjects', 'mainEvents'];

    function HomeController($scope, Principal, LoginService, $state, CustomUtils, $timeout, $root, DateUtils, callForProjects, mainEvents) {

        //cache la barre de navigation
        $root.hideNavBar = false;

        var vm = this;
        vm.now = new Date();
        vm.callForProjects = callForProjects;
        vm.mainEvents = mainEvents;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;

        $scope.$on('authenticationSuccess', function () {
            //getAccount();
            $state.reload();
        });

        getAccount();

        function initAAP() {
            vm.pastsAAP = [];
            vm.futuresAAP = [];
            vm.currentsAAP = [];
            vm.permanentsAAP = [];
            angular.forEach(vm.callForProjects, function (aap) {
                if (aap.startDate && aap.endDate) {
                    if (vm.isCurrentEvent(aap)) {
                        vm.currentsAAP.push(aap);
                    } else if (vm.isPastEvent(aap)) {
                        vm.pastsAAP.push(aap);
                    } else if (vm.isFutureEvent(aap)) {
                        vm.futuresAAP.push(aap);
                    }
                } else if (vm.isAdmin() || aap.publique) {
                    vm.permanentsAAP.push(aap);
                }
            });
        }

        function initMainEvents() {
            vm.pastsMainEvents = [];
            vm.futuresMainEvents = [];
            angular.forEach(vm.mainEvents, function (me) {
                if (vm.isFutureEvent(me)) {
                    vm.futuresMainEvents.push(me);
                } else {
                    vm.pastsMainEvents.push(me);
                }
            });
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        vm.isFutureEvent = function (item) {
            if (vm.isAdmin() || item.publique) {
                var startDate = item.startDate;
                if (DateUtils.compare(startDate, vm.now) > 0) {
                    return true;
                }
            }
            return false;
        };

        vm.isCurrentEvent = function (item) {
            if (vm.isAdmin() || item.publique) {
                return DateUtils.inRange(vm.now, item.startDate, item.endDate);
            }
            return false;
        };

        vm.isPastEvent = function (item) {
            if (vm.isAdmin() || item.publique) {
                var startDate = item.startDate;
                if (DateUtils.compare(startDate, vm.now) < 0) {
                    return true;
                }
            }
            return false;
        };

        vm.isAdmin = function () {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                return true;
            }
            return false;
        };

        vm.goToMainEvent = function (id, form) {
            if (vm.isAdmin() && CustomUtils.isUndefinedOrNull(form)) {
                $state.go("sheet.mainevent", {
                    id: id,
                    tab: 'main'
                });
            } else {
                $state.go("manifestationSubscription", {
                    id: id
                });
            }
        };

        vm.goToApp = function (id, form) {
            if (vm.isAdmin() && CustomUtils.isUndefinedOrNull(form)) {
                $state.go("sheet.callforproject", {
                    id: id,
                    tab: 'main'
                });
            } else {
                $state.go("callForProjectSubscription", {
                    id: id
                });
            }
        };

        initAAP();
        initMainEvents();
    }
})();
