(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('RemoveParticipationDialogController', RemoveParticipationDialogController);

    RemoveParticipationDialogController.$inject = ['$http', '$uibModalInstance', 'participation', '$log', '$filter', 'AlertService'];

    function RemoveParticipationDialogController($http, $uibModalInstance, participation, $log, $filter, AlertService) {

        var ctrl = this;
        var now = new Date();
        ctrl.participation = participation;
        ctrl.text = "Participation supprimée le " + $filter('date')(now, 'dd/MM/yyyy') + ".";

        $log.info(participation);


        ctrl.removeText = "Cette participation et ses abstracts doivent-ils être conservés en base ou voulez-vous tout supprimer ?";
        if (ctrl.participation.abstractCount === 0) {
            ctrl.removeText = "Cette participation doit-elle être conservée en base ou voulez-vous tout supprimer ?";
        }

        ctrl.softRemove = function () {
            ctrl.remove(false);
        };

        ctrl.ok = function () {
            ctrl.remove(true);
        };

        ctrl.remove = function (removeFromDatabase) {
            $http({
                method: 'DELETE',
                url: 'api/event-registrations/delete/' + ctrl.participation.id,
                data: ctrl.text,
                params: {
                    removeFromDatabase: removeFromDatabase
                }
            }).success(function () {
                if (removeFromDatabase) {
                    AlertService.success("Participation et abstracts supprimées.");
                } else {
                    AlertService.success("Participation marquée supprimée.");
                }
                $uibModalInstance.close();
            }).error(function () {
                AlertService.error("Problème lors de la suppression de la participation.");
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();