(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('CallForProjectSpaceController', CallForProjectSpaceController);

    CallForProjectSpaceController.$inject = ['Principal', '$rootScope', 'allInformations', 'CallForProjectService', '$state', '$translate', '$log', '$http', 'AlertService', 'CustomUtils'];

    function CallForProjectSpaceController(Principal, $root, allInformations, CallForProjectService, $state, $translate, $log, $http, AlertService, CustomUtils) {

        //cache la barre de navigation
        $root.hideNavBar = !Principal.isAuthenticated();

        var vm = this;
        vm.callForProject = allInformations.callForProject;
        vm.submission = allInformations.submission;
        vm.coordonnees = allInformations.coordonnee;
        vm.mails = allInformations.mails;

        vm.isAdmin = function () {
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        };

        vm.getMailInformation = function (mail) {
            var label = CallForProjectService.getMailStepLabelValue(vm.callForProject, mail.type);
            return label;
        };

        vm.sendMail = function (mail) {
            if (!vm.isAdmin()) {
                return;
            }
            var idSubmission = mail.submissionId;
            var type = mail.type;
            var json = {
                id: idSubmission,
                type: type
            };
            $log.info("Sending mail ", json);
            $http.post("api/call-for-project/submission/sendmail", json).then(function (response) {
                var i18nMessage = response.headers("x-canceropolegsoapp-alert");
                var i18nParam = response.headers("x-canceropolegsoapp-params");
                var message = $translate.instant(i18nMessage, {value: i18nParam});

                AlertService.info(message);
            }, function (response) {
                var i18nMessage = response.headers("x-canceropolegsoapp-alert");
                var i18nParam = response.headers("x-canceropolegsoapp-params");
                var mailType = CallForProjectService.getMailStepLabelValue(vm.callForProject, i18nParam);
                var message = $translate.instant(i18nMessage, {value: mailType});

                AlertService.error(message);
            });
        };

        var tab1 = {
            title: $translate.instant("canceropoleGsoApp.submission.space.stepOne"),
            warning: $translate.instant("canceropoleGsoApp.submission.space.stepOneWarning"),
            route: "SubmissionSpaceController.stepOne",
            disable: false
        };
        var tab2 = {
            title: $translate.instant("canceropoleGsoApp.submission.space.stepTwo"),
            warning: $translate.instant("canceropoleGsoApp.submission.space.stepTwoWarning"),
            route: "SubmissionSpaceController.stepTwo",
            disable: !vm.submission.keep
        };
        var tab3 = {
            title: $translate.instant("canceropoleGsoApp.submission.space.finalStep"),
            route: "SubmissionSpaceController.finalStep",
            disable: !vm.isAdmin() && CustomUtils.isEmpty(vm.submission.finalEvaluation)
        };

        if (vm.callForProject.privateSpace) {
            vm.tabs = [tab1, tab2, tab3];
        } else {
            tab1.title = $translate.instant("canceropoleGsoApp.submission.space.onlyOneStep");
            tab1.warning = $translate.instant("canceropoleGsoApp.submission.space.onlyOneStepWarning");
            vm.tabs = [tab1, tab3];
        }

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.disable === false && tab !== tab3) {
                vm.activeTabIndex = index;
            }
        });

        $state.go(vm.tabs[vm.activeTabIndex].route);

    }
})();