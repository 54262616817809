(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventMailsTabController', SheetMainEventMailsTabController);

    SheetMainEventMailsTabController.$inject = ['$log', '$stateParams', '$http', 'MainEventMail', '$state', 'entity', 'AlertService', 'Principal', '$q', 'MainEvent', 'ConfirmDialog', 'EventPrice'];

    function SheetMainEventMailsTabController($log, $stateParams, $http, MainEventMail, $state, entity, AlertService, Principal, $q, MainEvent, ConfirmDialog, EventPrice) {
        if ($stateParams.tab !== "mails") {
            //update state param
            $stateParams.tab = "mails";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.Model = {
            id: $stateParams.id,
            entity: entity,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN'])
        };


        vm.saveTemplate = function (templateSaved) {
            var template = null;
            angular.forEach(vm.Model.mailTemplates, function (mailTemplate) {
                if (mailTemplate.id === templateSaved.id) {
                    template = mailTemplate;
                }
            });
            if (template !== null) {
                template.title = templateSaved.title;
                template.content = templateSaved.content;
                MainEventMail.update(template, vm.loadMails);
            }
        };

        vm.loadMails = function () {
            vm.Model.mailTemplates = {};

            $http({method: "GET",
                url: "api/admin/main-events/" + vm.Model.id + "/mailTemplates"
            }).success(function (data) {
                angular.forEach(data, function (mailTemplate) {
                    vm.Model.mailTemplates[mailTemplate.type] = mailTemplate;
                });
            });
        };

        vm.cancel = vm.loadMails;

        vm.loadMails();
    }
})();