(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .factory('MainEventService', MainEventService);

    MainEventService.$inject = ['$translate', 'DateUtils', 'AlertService', '$translatePartialLoader'];

    function MainEventService($translate, DateUtils, AlertService, $translatePartialLoader) {
        $translatePartialLoader.addPart('eventPrice');
        $translate.refresh();

        var service = {
            getPriceForStatus: function (prices, mainEvent, status) {
                if (mainEvent.freeRegistration) {
                    return 0;
                }
                var now = new Date();
                for (var i = 0; i < prices.length; i++) {
                    var price = prices[i];
                    for (var j = 0; j < price.personStatuses.length; j++) {
                        var personStatus = price.personStatuses[j];
                        if (personStatus.id === status.id) {
                            if (price.startDate) {
                                var start = DateUtils.convertDateTimeFromServer(price.startDate);
                                if (DateUtils.compare(now, start) > 0) {
                                    return price.price;
                                }
                            } else if (price.endDate) {
                                var end = DateUtils.convertDateTimeFromServer(price.endDate);
                                if (DateUtils.compare(now, end) < 0) {
                                    return price.price;
                                }
                            }
                        }
                    }
                }
                $translate('canceropoleGsoApp.eventPrice.errors.nopriceforstatus', {status: status.name}).then(function (msg) {
                    AlertService.error(msg);
                });

                return null;
            }
        };


        return service;
    }

})();
