(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .factory('SearchFilterService', SearchFilterService);
    SearchFilterService.$inject = ['$translate', 'AbstractService'];
    function SearchFilterService($translate, AbstractService) {
        var sizeLimit = 30;
        return {
            all: {
                _type: {
                    terms: {
                        field: '_type',
                        size: sizeLimit
                    },
                    aggs: {}
                }
            },
            society: {
                'level': {
                    terms: {
                        field: 'level',
                        size: sizeLimit
                    }
                },
                'societyType.name': {
                    terms: {
                        field: 'societyType.name',
                        size: sizeLimit
                    }
                },
                'geosite.name': {
                    terms: {
                        field: 'geosite.name',
                        size: sizeLimit
                    }
                },
                'scienticAttachment.name': {
                    terms: {
                        field: 'scienticAttachment.name',
                        size: sizeLimit
                    }
                }
            },
            team: {
                'teamType.name': {
                    terms: {
                        field: 'teamType.name',
                        size: sizeLimit
                    }
                },
                'geosite.name': {
                    terms: {
                        field: 'geosite.name',
                        size: sizeLimit
                    }
                },
                'scienticAttachment.name': {
                    terms: {
                        field: 'scienticAttachment.name',
                        size: sizeLimit
                    }
                }
            },
            callforproject: {
                'year': {
                    terms: {
                        field: 'year',
                        size: sizeLimit
                    }
                },
                'managed': {
                    terms: {
                        field: 'managed',
                        size: sizeLimit
                    }
                },
                'privateSpace': {
                    terms: {
                        field: 'privateSpace',
                        size: sizeLimit
                    }
                },
                'callForProjectType.name': {
                    terms: {
                        field: 'callForProjectType.name',
                        size: sizeLimit
                    }
                },
                'canSaveSubmission': {
                    terms: {
                        field: 'canSaveSubmission',
                        size: sizeLimit
                    }
                }
            },
            eventabstract: {
                'type': {
                    terms: {
                        field: 'type',
                        size: sizeLimit
                    }
                }
            },
            person: {
                'subType': {
                    terms: {
                        field: 'subType',
                        size: sizeLimit
                    }
                },
                'job.metier': {
                    terms: {
                        field: 'job.metier',
                        size: sizeLimit
                    }
                },
                'job.status': {
                    terms: {
                        field: 'job.status',
                        size: sizeLimit
                    }
                },
                'job.geosite': {
                    terms: {
                        field: 'job.geosite',
                        size: sizeLimit
                    }
                },
                'job.inOffice': {
                    terms: {
                        field: 'job.inOffice',
                        size: sizeLimit
                    }
                },
                'scienticAttachment.name': {
                    terms: {
                        field: 'scienticAttachment.name',
                        size: sizeLimit
                    }
                }
            },
            getFilterValue: function (filter, value, currentTypeFilter) {
                if (filter === '_type') {
                    return $translate.instant('entities.' + value);
                } else if (value === 'not_affected') {
                    return $translate.instant('aggregation.not_affected');
                } else {
                    switch (currentTypeFilter) {
                        case 'eventabstract':
                            if (filter === 'type') {
                                return AbstractService.getLabelType(value);
                            }
                            break;
                        case 'society':
                            if (filter === 'level') {
                                return $translate.instant('canceropoleGsoApp.SocietyLevel.' + value);
                            }
                            break;
                        case 'person':
                            if (filter === 'job.inOffice') {
                                return $translate.instant('search.filter.job.inOffice.' + value);
                            }
                            break;
                        case 'callforproject':
                            if (filter === 'managed') {
                                return $translate.instant('search.filter.managed.' + value);
                            } else if (filter === 'privateSpace') {
                                return $translate.instant('search.filter.privateSpace.' + value);
                            } else if (filter === 'canSaveSubmission') {
                                return $translate.instant('search.filter.canSaveSubmission.' + value);
                            }
                            break;
                    }
                }
                return value;
            }
        };
    }
})();
