(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetSocietyStructureTabController', SheetSocietyStructureTabController);

    SheetSocietyStructureTabController.$inject = ['Principal', '$stateParams', '$state', 'JQWidgetsService', '$translate', '$uibModal', '$http', 'societyEntity', 'AlertService', 'ConfirmDialog', 'TeamType'];

    function SheetSocietyStructureTabController(Principal, $stateParams, $state, JQWidgetsService, $translate, $uibModal, $http, society, AlertService, ConfirmDialog, TeamType) {
        if ($stateParams.tab !== "structure") {
            //update state param
            $stateParams.tab = "structure";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.society = society;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        var childrenSource = {
            datatype: "json",
            url: "api/society/children/" + vm.society.id,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'shortName',
                    type: 'string'
                }, {
                    name: 'city',
                    type: 'string'
                }, {
                    name: 'webSite',
                    type: 'string'
                }, {
                    name: 'phone',
                    type: 'string'
                }, {
                    name: 'cp',
                    type: 'string'
                }
            ],
            sortcolumn: 'name',
            sortdirection: 'asc',
            id: 'id'
        };

        var nameRenderer = function (row, column, value, html, cell, object) {
            var link = "<a href=\"#/sheet/society/" + object.id + "?tab=main\">";
            link += value;
            link += "</a>";

            var result = html.replace(value, link);
            return result;
        };

        var mailToRenderer = function (row, column, value, html, cell, object) {
            var link = "<a href=\"mailto:" + value + "\">";
            link += value;
            link += "</a>";

            var result = html.replace(value, link);
            return result;
        };

        var linkRenderer = function (row, column, value, html, cell, object) {
            var link = "<a href=\"" + value + "\" target=\"_blank\">";
            link += value;
            link += "</a>";

            var result = html.replace(value, link);
            return result;
        };

        var childrenDataAdapter = new $.jqx.dataAdapter(childrenSource);

        vm.selected = null;
        vm.childrenGridSettings = {
            width: "100%",
            source: childrenDataAdapter,
            localization: JQWidgetsService.getLocalization($translate.use()),
            height: 500,
            //columnsresize: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            altrows: true,
            created: function (args) {
                vm.grid = args.instance;
            },
            rowselect: function (event) {
                vm.selected = null;
                if (event.args) {
                    vm.selected = event.args.row;
                }
            },
            rowunselect: function () {
            },
            columns: [{
                    text: 'Nom',
                    datafield: 'name',
                    minwidth: 200,
                    cellsrenderer: nameRenderer,
                    pinned: true
                }, {
                    text: 'Nom abrégé',
                    datafield: 'shortName',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Ville',
                    datafield: 'city',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Téléphone',
                    datafield: 'phone',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Site web',
                    datafield: 'webSite',
                    cellsrenderer: linkRenderer,
                    minwidth: 200
                }]
        };

        vm.addChild = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/society/dialog/search.society.dialog.html',
                controller: 'SearchSocietyDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    title: function () {
                        return "Structure";
                    }
                }
            }).result.then(function (result) {
                if (!_.isNil(result)) {
                    console.log(result);
                    $http.put('api/society/children/' + vm.society.id + '/' + result.id).then(function (response) {
                        if (response.status !== 200) {
                            AlertService.error(response.statusText);
                        }
                        vm.grid.updatebounddata();
                    });
                }
            });
        };

        vm.tryRemoveChild = function () {
            var msg = vm.selected.name;
            ConfirmDialog.openConfirmRemoveLink(msg, function () {
                vm.removeChild();
            });
        };

        vm.removeChild = function () {
            $http.delete('api/society/children/' + vm.society.id + '/' + vm.selected.id).then(function (response) {
                if (response.status !== 200) {
                    AlertService.error(response.statusText);
                } else {
                    vm.grid.clearselection();
                }
                vm.grid.updatebounddata();
            });
        };
    }
})();