(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .controller('PublicationsController', PublicationsController);

    PublicationsController.$inject = ['JQWidgetsService', '$uibModal', 'DataUtils', '$translate', 'CustomUtils', '$filter', 'ConfirmDialog', 'Publication', '$log'];

    function PublicationsController(JQWidgetsService, $uibModal, DataUtils, $translate, CustomUtils, $filter, ConfirmDialog, Publication, $log) {
        var ctrl = this;

        if (_.isNil(ctrl.showTitle)) {
            ctrl.showTitle = 'true';
        }

        var url = null;
        if (ctrl.personId) {
            url = "api/people/" + ctrl.personId + "/publications";
        } else if (ctrl.projectId) {
            url = "api/project/publications/" + ctrl.projectId;
        } else if (ctrl.societyId) {
            url = "api/society/publications/" + ctrl.societyId;
        }

        var publicationSource = {
            datatype: "json",
            url: url,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'createdBy',
                    type: 'string'
                }, {
                    name: 'createdDate',
                    type: 'date'
                }, {
                    name: 'content',
                    type: 'string'
                }, {
                    name: 'lastModifiedBy',
                    type: 'string'
                }, {
                    name: 'lastModifiedDate',
                    type: 'date'
                }, {
                    name: 'personId',
                    type: 'number'
                }, {
                    name: 'projectId',
                    type: 'number'
                }, {
                    name: 'societyId',
                    type: 'number'
                }, {
                    name: 'pubDate',
                    type: 'date'
                }, {
                    name: 'publicationTypeId',
                    type: 'number'
                }, {
                    name: 'publicationTypeName',
                    type: 'string'
                }, {
                    name: 'title',
                    type: 'string'
                }, {
                    name: 'webSite',
                    type: 'string'
                }, {
                    name: 'document',
                    type: 'string'
                }, {
                    name: 'documentContentType',
                    type: 'string'
                }
            ],
            sortcolumn: 'pubDate',
            sortdirection: 'desc',
            id: 'id'
        };

        ctrl.selectedPublication = null;
        ctrl.gridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: new $.jqx.dataAdapter(publicationSource),
            enabletooltips: true,
            height: 350,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                ctrl.publicationGrid = args.instance;
            },
            columns: [{
                    text: $translate.instant("canceropoleGsoApp.publication.pubDate"),
                    datafield: 'pubDate',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'date',
                    width: 100,
                    pinned: true
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.publicationType"),
                    datafield: 'publicationTypeName',
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120,
                    pinned: true
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.title"),
                    datafield: 'title',
                    minwidth: 80,
                    //maxwidth: 320,
                    pinned: true
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.webSite"),
                    datafield: 'webSite',
                    //minwidth: 300,
                    width: "30%",
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (CustomUtils.isDefined(object.webSite)) {
                            var link = '<a href="' + object.webSite + '" target="_blank" >';
                            link += object.webSite;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.document"),
                    datafield: 'document',
                    width: 40,
                    cellsalign: 'right',
                    align: 'right',
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isEmpty(value)) {
                            var link = '<span class="glyphicon glyphicon-paperclip"></span>';
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.content"),
                    datafield: 'content',
                    minwidth: 100
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.createdBy"),
                    datafield: 'createdBy',
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.createdBy)) {
                            var dateStr = $filter('date')(object.createdDate, 'dd/MM/yyyy HH:mm');
                            var span = '<span title="' + dateStr + '">';
                            span += object.createdBy;
                            span += '</span>';
                            var result = html.replace(value, span);
                            return result;
                        }
                        return html;
                    },
                    width: 150
                }, {
                    text: $translate.instant("canceropoleGsoApp.publication.lastModifiedBy"),
                    datafield: 'lastModifiedBy',
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.lastModifiedBy)) {
                            var dateStr = $filter('date')(object.lastModifiedDate, 'dd/MM/yyyy HH:mm');
                            var span = '<span title="' + dateStr + '">';
                            span += object.lastModifiedBy;
                            span += '</span>';
                            var result = html.replace(value, span);
                            return result;
                        }
                        return html;
                    },
                    width: 150
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    ctrl.selectedPublication = args.row;
                }
            }
        };


        ctrl.addPublication = function () {
            ctrl.editPublication(true);
        };

        ctrl.editPublication = function (add) {
            var publication = ctrl.selectedPublication;
            if (add === true) {
                publication = {
                    pubDate: new Date(),
                    title: null,
                    webSite: null,
                    content: null,
                    document: null,
                    documentContentType: null,
                    id: null
                };
                if (ctrl.personId) {
                    publication.personId = parseInt(ctrl.personId);
                } else if (ctrl.projectId) {
                    publication.projectId = parseInt(ctrl.projectId);
                } else if (ctrl.societyId) {
                    publication.societyId = parseInt(ctrl.societyId);
                }
            }

            $uibModal.open({
                templateUrl: 'app/components/publications/dialog/publication.edit.dialog.html',
                controller: 'PublicationEditDialogController',
                controllerAs: 'ctrl',
                resolve: {
                    publication: function () {
                        return publication;
                    },
                    publicationTypes: ["PublicationType", function (PublicationType) {
                            return PublicationType.query().$promise;
                        }]
                }
            }).result.then(function (publication) {
                $log.info(publication);
                if (CustomUtils.isDefined(publication.id)) {
                    Publication.update(publication, function () {
                        ctrl.refresh();
                    });
                } else {
                    Publication.save(publication, function () {
                        ctrl.refresh();
                    });
                }
            });
        };

        ctrl.refresh = function () {
            ctrl.publicationGrid.clearselection();
            ctrl.publicationGrid.updatebounddata();
            ctrl.selectedPublication = null;
        };

        ctrl.tryRemovePublication = function () {
            if (!CustomUtils.isUndefinedOrNull(ctrl.selectedPublication)) {
                ConfirmDialog.openConfirmRemove(ctrl.selectedPublication.title,
                        function () {
                            Publication.delete({id: ctrl.selectedPublication.id},
                                    function () {
                                        ctrl.refresh();
                                    });
                        });
            }
        };

        ctrl.openPublication = function () {
            if (!CustomUtils.isUndefinedOrNull(ctrl.selectedPublication) &&
                    !CustomUtils.isUndefinedOrNull(ctrl.selectedPublication.document)) {
                DataUtils.openFile(ctrl.selectedPublication.documentContentType, ctrl.selectedPublication.document);
            }
        };
    }
})();