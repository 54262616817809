(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventController', SheetMainEventController);

    SheetMainEventController.$inject = ['Principal', '$log', 'DataUtils', '$stateParams', 'MainEvent', '$scope', '$translate', '$http', 'SheetHistoryService', 'AlertService', 'EventRegistration', '$q', 'ModelConfig', 'EventManagement', '$uibModal', 'ConfirmDialog', 'entity', 'EventSession', 'EventDay', '$state'];

    function SheetMainEventController(Principal, $log, DataUtils, $stateParams, MainEvent, $scope, $translate, $http, SheetHistoryService, AlertService, EventRegistration, $q, ModelConfig, EventManagement, $uibModal, ConfirmDialog, entity, EventSession, EventDay, $state) {
        var vm = this;

        vm.id = $stateParams.id;
        vm.entity = entity;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        vm.titleEdition = false;

        vm.editTitle = function () {
            vm.titleEdition = true;
        };

        vm.cancel = function () {
            vm.titleEdition = false;
            $state.go($state.current, {}, {reload: true});
        };

        vm.save = function () {
            return MainEvent.update(vm.entity, vm.load, vm.load);
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.tryRemove = function () {

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/remove-mainevent.dialog.html',
                controller: 'RemoveMainEventDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    mainevent: function () {
                        return vm.entity;
                    },
                    dryDeleteResult: function () {
                        return $http.delete("api//main-events/" + vm.entity.id + "/dry").then(function (response) {
                            return response.data;
                        });
                    }
                }
            }).result.then(function () {
                $state.go("home");
            });

        };

        vm.byteSize = DataUtils.byteSize;
        vm.setAvatar = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.entity.avatar = base64Data;
                        vm.entity.avatarContentType = $file.type;
                    });
                });
            }
        };

        vm.titleEditorSettings = {
            width: '100%',
            height: 100,
            tools: "bold italic underline | font | color | html"
        };

        var sheetHistory = {
            name: vm.entity.name,
            type: 'mainevent',
            id: vm.id,
            tab: 'main'
        };
        $log.info("state tab=", $stateParams.tab);
        if ($stateParams.tab) {
            sheetHistory.tab = $stateParams.tab;
        }
        SheetHistoryService.add(sheetHistory);

        vm.tabs = [{
                title: $translate.instant("sheet.mainevent.informations.tab"),
                route: "sheet.mainevent.main"
            }, {
                title: $translate.instant("sheet.mainevent.sessions.tab"),
                route: "sheet.mainevent.sessions"
            }, {
                title: $translate.instant("sheet.mainevent.options.tab"),
                route: "sheet.mainevent.options"
            }, {
                title: $translate.instant("sheet.mainevent.mails.tab"),
                route: 'sheet.mainevent.mails'
            }, {
                title: $translate.instant("sheet.mainevent.participations.tab"),
                route: 'sheet.mainevent.participations'
            }, {
                title: $translate.instant("sheet.mainevent.statistics.tab"),
                route: 'sheet.mainevent.statistics'
            }
        ];


        if (!vm.entity.freeRegistration) {
            vm.tabs.splice(1, 0, {
                title: $translate.instant("sheet.mainevent.prices.tab"),
                route: "sheet.mainevent.prices"
            });
        }

        if (vm.entity.hasOral || vm.entity.hasPoster) {
            var tabIndex = vm.tabs.length - 1;
            vm.tabs.splice(tabIndex, 0, {
                title: $translate.instant("sheet.mainevent.abstracts.tab"),
                route: 'sheet.mainevent.abstracts'
            });
        }

        var activeTab = $stateParams.tab;

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.route.endsWith(activeTab)) {
                vm.activeTabIndex = index;
            }
        });

        $state.go(vm.tabs[vm.activeTabIndex].route);
    }
})();