(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ExportSubmissionsDialogController', ExportSubmissionsDialogController);

    ExportSubmissionsDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', '$log', 'submissions', 'DataUtils', '$timeout', 'callForProject', 'CallForProjectService', 'formFieldsList', 'JQWidgetsService', 'formTypes', 'exportType'];

    function ExportSubmissionsDialogController($translate, $translatePartialLoader, $uibModalInstance, $log, submissions, DataUtils, $timeout, callForProject, CallForProjectService, formFieldsList, JQWidgetsService, formTypes, exportType) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.submissions = submissions;
        ctrl.callForProject = callForProject;
        ctrl.formFieldsList = formFieldsList;
        ctrl.selectedForm = null;
        ctrl.exportType = exportType;

        ctrl.title = "Exporter les projets";
        if (ctrl.submissions.length === 1) {
            ctrl.title = "Exporter le projet";
        }

        ctrl.synthesisColumns = [{
                text: "Date",
                datafield: "date",
                visible: true
            }, {
                text: "Projet financé",
                datafield: 'funded',
                visible: true
            }, {
                text: 'Prénom porteur',
                datafield: 'personFirstName',
                visible: true
            }, {
                text: 'Nom porteur',
                datafield: 'personLastName',
                visible: true
            }, {
                text: 'Titre du projet',
                datafield: 'submissionTitle',
                visible: true
            }, {
                text: 'Budget demandé',
                datafield: 'askedBudget',
                visible: true
            }, {
                text: 'Budget attribué',
                datafield: 'acceptedBudget',
                visible: true
            }, {
                text: 'Durée demandée',
                datafield: 'askedDuration',
                visible: true
            }, {
                text: 'Durée acceptée',
                datafield: 'acceptedDuration',
                visible: true
            }, {
                text: 'Thématique',
                datafield: 'axe',
                visible: true
            }, {
                text: 'Site',
                datafield: 'geosite',
                visible: true
            }];
        if (ctrl.callForProject.privateSpace) {
            ctrl.synthesisColumns.splice(1, 0, {
                text: 'Li retenu',
                datafield: 'keep',
                visible: true
            });
        }

        if (ctrl.exportType.type === 'synthesis') {
            ctrl.columns = ctrl.synthesisColumns;
        }

        ctrl.forms = [];
        angular.forEach(ctrl.formFieldsList, function (formField) {
            for (var i = 0; i < formTypes.length; i++) {
                var formType = formTypes[i];
                if (formType === formField.form.type) {
                    ctrl.forms.push({
                        label: CallForProjectService.getFormTypeLabel(ctrl.callForProject, formField.form.type),
                        value: formField.form.type
                    });
                    break;
                }
            }
        });

        ctrl.typeSettings = {
            height: 30,
            width: 300,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.forms,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.exportForm.$setDirty();
                    var item = event.args.item;
                    ctrl.selectedForm = item;
                    if (ctrl.exportType.type === 'xls') {
                        ctrl.getFields();
                    } else {
                        ctrl.getGroups();
                    }
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.forms.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        ctrl.getFields = function () {
            ctrl.columns = ctrl.synthesisColumns;

            ctrl.specifics = [{
                    text: 'Acronyme',
                    datafield: 'acronym',
                    visible: true
                }, {
                    text: 'Mots clés',
                    datafield: 'keyword',
                    visible: true
                }, {
                    text: 'Partenaires éventuels du projet',
                    datafield: 'partner',
                    visible: true
                }, {
                    text: 'Résumé',
                    datafield: 'description',
                    visible: true
                }, {
                    text: 'Structure',
                    datafield: 'societyName',
                    visible: true
                }, {
                    text: 'Ville',
                    datafield: 'city',
                    visible: true
                }, {
                    text: 'Email',
                    datafield: 'email',
                    visible: true
                }, {
                    text: 'Téléphone',
                    datafield: 'phone',
                    visible: true
                }, {
                    text: 'Portable',
                    datafield: 'portable',
                    visible: true
                }, {
                    text: 'Métier',
                    datafield: 'metier',
                    visible: true
                }, {
                    text: 'Statut',
                    datafield: 'status',
                    visible: true
                }, {
                    text: 'Détail',
                    datafield: 'detail',
                    visible: true
                }, {
                    text: "Directeur d'unité",
                    datafield: 'unitDirector',
                    visible: true
                }, {
                    text: "Equipe de recherche",
                    datafield: 'teamName',
                    visible: true
                }, {
                    text: "Chef d'équipe",
                    datafield: 'teamDirector',
                    visible: true
                }];
            ctrl.columns = ctrl.columns.concat(ctrl.specifics);

            angular.forEach(ctrl.formFieldsList, function (formField) {
                if (formField.form.type === ctrl.selectedForm.value) {
                    var fields = formField.fields;
                    angular.forEach(fields, function (field) {
                        ctrl.columns.push({
                            text: field.label,
                            datafield: "field_" + field.id,
                            visible: true
                        });
                    });
                }
            });
        };

        ctrl.getGroups = function () {
            var formId = null;
            for (var i = 0; i < ctrl.formFieldsList.length; i++) {
                var formField = ctrl.formFieldsList[i];
                if (formField.form.type === ctrl.selectedForm.value) {
                    formId = formField.form.id;
                    break;
                }
            }

            ctrl.columns = [{
                    text: "En-tête",
                    datafield: "header_" + formId,
                    visible: true
                }, {
                    text: "Description Administrative du porteur de projet",
                    datafield: "info_porteur",
                    visible: true
                }, {
                    text: "Bloc projet",
                    datafield: "projet",
                    visible: true
                }];

            angular.forEach(ctrl.formFieldsList, function (formField) {
                if (formField.form.type === ctrl.selectedForm.value) {
                    var groups = formField.groups;
                    angular.forEach(groups, function (group) {
                        ctrl.columns.push({
                            text: group.name,
                            datafield: "group_" + group.id,
                            visible: true
                        });
                    });
                }
            });
            ctrl.columns.push({
                text: "Pied de page",
                datafield: "footer_" + formId,
                visible: true
            });
        };

        ctrl.ok = function () {
            var selectedColumns = {};
            angular.forEach(ctrl.columns, function (column) {
                if (column.visible === true) {
                    selectedColumns[column.datafield] = column.text;
                }
            });

            var data = [];
            angular.forEach(ctrl.submissions, function (submission) {
                data.push(submission.id);
            });

            var toSend = {
                type: exportType.type,
                columns: selectedColumns,
                data: data
            };

            $log.info(toSend);

            DataUtils.downloadFileFromPostUrl("api/call-for-projects/submissions/export?allAttachment=true", toSend, exportType.fileName);

            $uibModalInstance.close(ctrl.result);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();