(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .controller('SheetWindowMap', SheetWindowMap);

    SheetWindowMap.$inject = ['$scope', 'GeocoderDataGouv', 'AlertService', '$log', 'GeoService', '$translate', '$translatePartialLoader'];

    function SheetWindowMap($scope, GeocoderDataGouv, AlertService, $log, GeoService, $translate, $translatePartialLoader) {
        $translatePartialLoader.addPart("map");
        $translate.refresh();

        var that = this;

        this.defaultPosition = {
            lng: 1.432632,
            lat: 43.600865,
            zoom: 9
        };

        if (this.panelTitle === null) {
            this.panelTitle = "map.window.title";
        }

        this.underEdition = false;

        $scope.$watch('$ctrl.entity', function () {
            if (that.entity) {
                that.searchAddress = that.entity.address;
                if (that.entity.cp) {
                    that.searchAddress += ' - ' + that.entity.cp;
                }
                if (that.entity.city) {
                    that.searchAddress += ' - ' + that.entity.city;
                }
                if (that.entity.lat) {
                    var lng = parseFloat(that.entity.lng);
                    var lat = parseFloat(that.entity.lat);
                    that.setCenter(lng, lat);
                    that.setMarker(lng, lat, false, that.markerMessage);
                }
            }
        }, true);

        this.edit = function () {
            this.underEdition = true;
            this.markers.pointMarker.draggable = true;
        };

        this.saveEdition = function () {
            this.entity.lng = this.lng;
            this.entity.lat = this.lat;
            this.markers.pointMarker.draggable = false;
            if (this.saveAction) {
                this.saveAction(this.winId);
            }
            this.underEdition = false;
        };

        this.cancelEdition = function () {
            this.markers.pointMarker.draggable = false;
            if (this.cancelAction) {
                this.cancelAction(this.winId);
            }
            this.underEdition = false;
        };

        this.searchLocalization = function () {
            GeocoderDataGouv.geocode(this.searchAddress, function (result) {
                if (result.success) {
                    var coordinate = result.coordinate;
                    var lng = coordinate.lng;
                    var lat = coordinate.lat;

                    if (lng !== null && lat !== null) {
                        that.lng = lng;
                        that.lat = lat;
                        that.setMarker(lng, lat, true);
                        that.setCenter(lng, lat);
                    }
                } else {
                    AlertService.warning('sheet.no.result');
                }
            });
        };

        $scope.$on('leafletDirectiveMarker.dragend', function (event, args) {
            that.lng = args.model.lng;
            that.lat = args.model.lat;
        });

        this.setMarker = function (lng, lat, draggable, message) {
            $log.debug(GeoService.toPointWkt(lng, lat));

            var marker = {
                lng: lng,
                lat: lat,
                draggable: draggable,
                icon: GeoService.iconMarker(this.type),
                message: message,
                focus: true
            };
            this.markers = {
                pointMarker: marker
            };
        };

        this.setCenter = function (lng, lat, zoom) {
            $scope.center.lng = lng;
            $scope.center.lat = lat;
            $scope.center.zoom = zoom || this.defaultPosition.zoom;
        };

        angular.extend($scope, {
            center: {},
            defaults: {
                scrollWheelZoom: true
            },
            icons: GeoService.iconsMarkers
        });

        this.setMarker(this.defaultPosition.lng, this.defaultPosition.lat, false, this.markerMessage);
        this.setCenter(this.defaultPosition.lng, this.defaultPosition.lat, this.defaultPosition.zoom);
    }

})();
