(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('DaySessionRemoveDialogController', DaySessionRemoveDialogController);

    DaySessionRemoveDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'daySession', '$log'];

    function DaySessionRemoveDialogController($translate, $translatePartialLoader, $uibModalInstance, daySession, $log) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.daySession = daySession;

        ctrl.sessionNb = ctrl.daySession.records.length;

        $log.info(ctrl);

        ctrl.canRemove = function () {
            if (ctrl.sessionNb === 0) {
                return true;
            }
            return false;
        };

        ctrl.ok = function () {
            $uibModalInstance.close(ctrl.daySession);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();