(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('FieldTableController', FieldTableController);

    FieldTableController.$inject = ['CustomUtils', 'JQWidgetsService', '$scope', '$log', 'Principal'];

    function FieldTableController(CustomUtils, JQWidgetsService, $scope, $log, Principal) {
        var ctrl = this;
        ctrl.config = JSON.parse(ctrl.config);
        if (_.isString(ctrl.model)) {
            ctrl.model = JSON.parse(ctrl.model);
        }

        ctrl.mergeConfig = function () {
            _.mergeWith(ctrl.model, ctrl.config.values, function (modelValue, configValue) {
                for (var key in modelValue) {
                    if (modelValue.hasOwnProperty(key)) {
                        if (key.startsWith('col_')) {
                            var configCellValue = configValue[key];
                            if (!_.isEmpty(configCellValue)) {
                                modelValue[key] = configCellValue;
                            }
                        }
                    }
                }
                return modelValue;
            });
        };

        ctrl.mergeConfig();
        if (_.isNil(ctrl.model)) {
            ctrl.model = ctrl.config.values;
        }

        var tooltiprenderer = function (element) {
            $(element).parent().jqxTooltip({position: 'mouse', content: $(element).text()});
        };

        var datafields = [];
        var columns = [];
        for (var i = 0; i < ctrl.config.cols.length; i++) {
            var col = ctrl.config.cols[i];
            var dataField = col.datafield;
            datafields[datafields.length] = {
                name: dataField
            };
            columns.push({
                text: col.text,
                datafield: dataField,
                rendered: tooltiprenderer,
                width: col.width + '%',
                align: col.align,
                cellsalign: col.align,
                cellbeginedit: function (rowIndex, datafield) {
                    if (!ctrl.readOnly) {
                        var configValue = ctrl.config.values[rowIndex][datafield];
                        return _.isEmpty(configValue);
                    }
                    return false;
                }
            });
        }

        var source = {
            datafields: datafields,
            localdata: ctrl.model,
            updaterow: function (rowid, rowdata, commit) {
                ctrl.mergeConfig();
                _.merge(ctrl.model[rowid], rowdata);
            }
        };

        var dataAdapter = new $.jqx.dataAdapter(source);

        ctrl.gridSettings = {
            autoheight: true,
            width: '100%',
            source: dataAdapter,
            editable: true,
            columnsresize: !ctrl.readOnly,
            selectionmode: 'multiplecellsadvanced',
            columns: columns,
            created: function (args) {
                ctrl.grid = args.instance;
            }
        };
    }
})();
