(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('CFPFormController', CFPFormController);

    CFPFormController.$inject = ['ConfirmDialog', '$uibModal', 'CustomUtils', '$state', 'Principal', '$http', 'AlertService', 'CallForProjectForm', 'CallForProjectFormGroup', '$log', '$scope'];

    function CFPFormController(ConfirmDialog, $uibModal, CustomUtils, $state, Principal, $http, AlertService, CallForProjectForm, CallForProjectFormGroup, $log, $scope) {

        var vm = this;

        if (CustomUtils.isUndefinedOrNull(vm.preview)) {
            vm.preview = false;
            vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
            if (!vm.canEdit) {
                vm.preview = true;
            }
        }
        if (CustomUtils.isUndefinedOrNull(vm.showToolbar)) {
            vm.showToolbar = true;
        }

        vm.dateSettings = {
            height: 30,
            width: 160,
            formatString: 'dd/MM/yyyy HH:mm',
            culture: 'fr-FR'
        };

        vm.loadForm = function () {
            vm.formField.formName = CustomUtils.removeSpecialChars(vm.formField.form.name);
            vm.formField.online = false;
            if (vm.formField.form.state === 'Online') {
                vm.formField.online = true;
            }
            console.log("load form");

            angular.forEach(vm.formField.groups, function (group, index) {
                group.fields = [];

                //drag & drop
                group.sortableOpts = {
                    containment: '.sortable-container-' + index
                };

                angular.forEach(vm.formField.fields, function (field) {
                    if (field.callForProjectFormGroupId === group.id) {
                        if (!vm.preview) {
                            group.fields.push(field);
                        } else if (field.type !== 'empty') {
                            group.fields.push(field);
                        }
                    }
                });
            });
            if (vm.preview) {
                return _.filter(vm.formField.groups, function (group) {
                    return group.fields.length > 0;
                });

            }
            return vm.formField.groups;
        };
        $scope.$watch('vm.preview', function () {
            vm.groups = vm.loadForm();
        });

        vm.editorSettings = {
            tools: 'bold italic underline | format font size | color background | left center right | outdent indent | ul ol | link | clean | html',
            pasteMode: 'text',
            height: 200
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.addField = function (formGroup) {
            var position = formGroup.fields.length + 1;
            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/add-field.dialog.html',
                controller: 'AddCallForProjectFormFieldDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('callForProjectFormField');
                            $translatePartialLoader.addPart('formFieldType');
                            $translatePartialLoader.addPart('criterionType');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                    formField: function () {
                        return {
                            name: null,
                            label: null,
                            type: null,
                            callForProjectFormGroupId: formGroup.id,
                            position: position,
                            criterion: false,
                            criterionType: null,
                            required: false,
                            legend: null
                        };
                    },
                    groups: function () {
                        return vm.formField.groups;
                    }
                }
            }).result.then(function () {
                vm.load();
            });
        };

        vm.editGroup = function (formGroup) {
            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/add-group.dialog.html',
                controller: 'AddCallForProjectFormGroupDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('callForProjectFormField');
                            $translatePartialLoader.addPart('callForProjectFormGroup');
                            $translatePartialLoader.addPart('formFieldType');
                            $translatePartialLoader.addPart('criterionType');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                    formGroup: function () {
                        return formGroup;
                    },
                    existingGroups: function () {
                        return vm.formField.groups;
                    }
                }
            }).result.then(function () {
                vm.load();
            });
        };

        vm.addGroup = function (formId, position) {
            var groupToAdd = {
                name: null,
                type: null,
                callForProjectFormId: formId,
                showToExpert: true,
                position: position
            };
            vm.editGroup(groupToAdd);
        };

        vm.tryRemoveGroup = function (formGroup) {
            var msg = CustomUtils.removeQuotes(formGroup.name);
            ConfirmDialog.openConfirmRemove(msg, function () {
                CallForProjectFormGroup.delete({id: formGroup.id}, function () {
                    $state.go($state.current, {}, {reload: true});
                });
            });
        };

        vm.saveLayoutForm = function (formField) {
            var positions = [];
            angular.forEach(formField.groups, function (group) {
                angular.forEach(group.fields, function (field, index) {
                    positions.push({
                        id: field.id,
                        position: index + 1
                    });
                });
            });
            $http.put('api/call-for-project-forms/positions', positions).then(function (result) {
                AlertService.success('Disposition sauvegardée.');
            });

        };

        vm.saveForm = function (formField) {
            vm.saveLayoutForm(formField);
            CallForProjectForm.update(formField.form, function () {
                vm.load();
            });
        };

        vm.formStateChange = function (formField) {
            var formState = 'Offline';
            if (formField.online) {
                formState = 'Online';
            }
            formField.form.state = formState;
            vm.saveForm(formField);
        };

        vm.tryDeleteForm = function (formField) {
            var msg = CustomUtils.removeQuotes(formField.form.name);
            ConfirmDialog.openConfirmRemove(msg, function () {
                CallForProjectForm.delete({id: formField.form.id}, function () {
                    $state.go($state.current, {}, {reload: true});
                }, function (errorResponse) {
                    $log.error(errorResponse);
                    AlertService.error('Impossible de supprimer ce formulaire en base.');
                });
            });
        };

        vm.goToLIForm = function () {
            $state.go('callForProjectSubscription', {id: vm.callForProject.id});
        };
    }
})();
