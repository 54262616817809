(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectInterventionController', SheetProjectInterventionController);

    SheetProjectInterventionController.$inject = ['Principal', 'project', '$stateParams', '$state', '$log', 'JQWidgetsService', '$translate', 'CustomUtils', 'AbstractService', '$filter', '$uibModal', 'AlertService', 'Project', 'ConfirmDialog'];

    function SheetProjectInterventionController(Principal, project, $stateParams, $state, $log, JQWidgetsService, $translate, CustomUtils, AbstractService, $filter, $uibModal, AlertService, Project, ConfirmDialog) {
        if ($stateParams.tab !== "intervention") {
            //update state param
            $stateParams.tab = "intervention";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.project = project;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        var typeRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isEmpty(value)) {
                var images = AbstractService.renderFinalType(value);
                if (!CustomUtils.isEmpty(images)) {
                    var result = html.replace(value, images);
                    return result;
                }
            }
            return html;
        };

        var privateSpaceRenderer = function (row, column, value, html, cell, object) {
            if (CustomUtils.isDefined(value)) {
                var link = '';
                link += '<a href="#/manifestation/' + object.mainEventId + '/space?key=' + object.registrationKey + '">';
                link += $filter('date')(object.registrationDate, 'dd/MM/yyyy HH:mm');
                link += '</a>';
                var result = html.replace(">" + value + "<", ">" + link + "<");
                return result;
            }
            return html;
        };

        vm.selectedAbstract = null;
        vm.abstractsDataAdapter = new $.jqx.dataAdapter({
            dataType: "json",
            url: "api/project/abstracts/" + vm.project.id,
            dataFields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'title',
                    type: 'string'
                }, {
                    name: 'theme',
                    type: 'date'
                }, {
                    name: 'registrationKey',
                    type: 'number'
                }, {
                    name: 'finalType',
                    type: 'string'
                }, {
                    name: 'accepted',
                    type: 'bool'
                }, {
                    name: 'registrationDate',
                    type: 'date'
                }, {
                    name: 'mainEventId',
                    type: 'number'
                }, {
                    name: 'mainEventName',
                    type: 'string'
                }],
            id: 'id',
            sortcolumn: 'id',
            sortdirection: 'desc'
        });
        vm.abstractsGridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: vm.abstractsDataAdapter,
            enabletooltips: true,
            height: 300,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                vm.abstractsGrid = args.instance;
            },
            columns: [{
                    text: "Espace personnel",
                    datafield: 'registrationDate',
                    filtertype: 'date',
                    cellsrenderer: privateSpaceRenderer,
                    //cellsformat: 'dd-MMMM-yyyy',
                    width: 135,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true,
                    aggregates: [{
                            '<b>Participants</b>': function (aggregatedValue) {
                                return ++aggregatedValue;
                            }
                        }]
                }, {
                    text: 'Type final',
                    datafield: 'finalType',
                    cellsrenderer: typeRenderer,
                    filtertype: 'checkedlist',
                    createfilterwidget: function (column, columnElement, widget) {
                        widget.jqxDropDownList({
                            //dropDownWidth: 250,
                            renderer: function (index, label, value) {
                                var images = AbstractService.renderFinalType(value);
                                if (CustomUtils.isEmpty(images)) {
                                    return value;
                                }
                                return images;
                            }
                        });
                    },
                    cellsalign: 'center',
                    align: 'center',
                    width: 60
                }, {
                    text: 'Mots clés',
                    datafield: 'theme',
                    width: 250,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Evénement',
                    datafield: 'mainEventName',
                    width: 300,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (CustomUtils.isDefined(object.mainEventId)) {
                            var link = '<a href="#/sheet/mainevent/' + object.mainEventId + '\">';
                            link += value;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    },
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Titre',
                    datafield: 'title',
                    minwidth: 200,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (CustomUtils.isDefined(object.id)) {
                            var link = '<a href="#/sheet/eventabstract/' + object.id + '\">';
                            link += value;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    vm.selectedAbstract = args.row;
                }
            }
        };

        vm.linkToAbstract = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/project/dialog/link.abstract.dialog.html',
                controller: 'LinkAbstractDialogController',
                controllerAs: 'ctrl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    project: function () {
                        return vm.project;
                    }
                }
            }).result.then(function (abstract) {
                if (CustomUtils.isDefined(abstract.id)) {
                    vm.project.eventAbstracts.push(abstract);

                    vm.saveProject();
                }
            });
        };

        vm.tryUnlinkToAbstract = function () {
            ConfirmDialog.openConfirmRemove(vm.selectedAbstract.title, function () {
                var index = null;
                for (var i = 0; i < vm.project.eventAbstracts.length; i++) {
                    var abstract = vm.project.eventAbstracts[i];
                    if (abstract.id === vm.selectedAbstract.id) {
                        index = i;
                        break;
                    }
                }
                if (index !== null) {
                    vm.project.eventAbstracts.splice(index, 1);
                    vm.saveProject();
                }
            }, function () {
                AlertService.error("Impossible de sauvegarder en base.");
            });
        };

        vm.saveProject = function () {
            Project.update(vm.project, function () {
                vm.load();
            }, function () {
                AlertService.error("Impossible de sauvegarder en base.");
            });
        };
    }
})();
