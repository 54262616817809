(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventPricesTabController', SheetMainEventPricesTabController);

    SheetMainEventPricesTabController.$inject = ['JQWidgetsService', '$stateParams', 'personStatus', 'CustomUtils', '$state', '$filter', 'AlertService', 'prices', '$q', '$uibModal', 'ConfirmDialog', 'EventPrice', '$translate', 'entity'];
    function SheetMainEventPricesTabController(JQWidgetsService, $stateParams, personStatus, CustomUtils, $state, $filter, AlertService, prices, $q, $uibModal, ConfirmDialog, EventPrice, $translate, entity) {
        if ($stateParams.tab !== "prices") {
            //update state param
            $stateParams.tab = "prices";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.mainEventId = $stateParams.id;
        vm.selectedPrice = null;
        vm.prices = prices;
        vm.mainEvent = entity;

        var pricesSource = {
            datatype: "json",
            localdata: vm.prices,
            data: {
                size: 1000
            },
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'endDate',
                    type: 'date'
                }, {
                    name: 'mainEventId',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'personStatuses',
                    type: 'array'
                }, {
                    name: 'statuses',
                    type: 'string'
                }, {
                    name: 'position',
                    type: 'number'
                }, {
                    name: 'price',
                    type: 'number'
                }, {
                    name: 'startDate',
                    type: 'date'
                }
            ],
            sortcolumn: '',
            sortdirection: 'asc'
        };

        vm.pricesDataAdapter = new $.jqx.dataAdapter(pricesSource);

        var statusRenderer = function (row, column, value, html, cell, object) {
            if (angular.isArray(value)) {
                var newValue = "";
                angular.forEach(value, function (personStatus) {
                    if (newValue.length > 0) {
                        newValue += "<br/>";
                    }
                    newValue += personStatus.name;
                });
                var result = html.replace(value, newValue);
                return result;
            }
            return html;
        };

        vm.pricesSettings = {
            width: "100%",
            altrows: true,
            source: vm.pricesDataAdapter,
            sortable: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            autoheight: true,
            autorowheight: true,
            created: function (args) {
                vm.pricesGrid = args.instance;
            },
            columns: [{
                    text: 'Statut',
                    datafield: 'personStatuses',
                    cellsalign: 'center',
                    align: 'center',
                    cellsrenderer: statusRenderer,
                    width: 200,
                    pinned: true
                }, {
                    text: 'Avant le',
                    datafield: 'endDate',
                    cellsalign: 'center',
                    align: 'center',
                    cellsFormat: 'dd/MM/yyyy HH:mm',
                    //columngroup: 'period',
                    width: 130
                }, {
                    text: 'Après le',
                    datafield: 'startDate',
                    cellsalign: 'center',
                    align: 'center',
                    cellsFormat: 'dd/MM/yyyy HH:mm',
                    //columngroup: 'period',
                    width: 130
                }, {
                    text: 'Tarif',
                    datafield: 'price',
                    cellsalign: 'center',
                    align: 'center',
                    width: 80,
                    //columngroup: 'period',
                    cellsformat: 'c'
                }, {
                    text: 'Nom',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    columngroup: 'colonne',
                    minwidth: 100
                }, {
                    text: 'position',
                    datafield: 'position',
                    cellsalign: 'center',
                    align: 'center',
                    columngroup: 'colonne',
                    width: 70
                }],
            columngroups: [{
                    text: 'Colonne',
                    align: 'center',
                    name: 'colonne'
                }, {
                    text: 'Période',
                    align: 'center',
                    name: 'period'
                }
            ],
            rowdoubleclick: function (event) {
                var row = event.args.row;
                vm.editPrice(row.bounddata);
            },
            rowselect: function (event) {
                if (event.args.row) {
                    vm.selectedPrice = event.args.row;
                } else {
                    vm.selectedPrice = null;
                }
            }
        };

        vm.editPrice = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/eventprice.edit.dialog.html',
                controller: 'EventPriceEditDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventPrice');
                            return $translate.refresh();
                        }],
                    eventPrice: function () {
                        return data;
                    },
                    personStatus: function () {
                        return personStatus;
                    }
                }
            }).result.then(function (result) {
                result.mainEventId = vm.mainEventId;
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    //ajout
                    EventPrice.save(result, vm.load);

                } else {
                    //modification
                    EventPrice.update(result, vm.load);
                }
            });
        };

        vm.tryRemovePrice = function () {
            var startDate = $filter("date")(vm.selectedPrice.startDate, 'dd/MM/yyyy HH:mm');
            var endDate = $filter("date")(vm.selectedPrice.endDate, 'dd/MM/yyyy HH:mm');
            var msg = "Tarif " + vm.selectedPrice.price + "€ pour " + vm.selectedPrice.personStatus;
            if (startDate) {
                msg += " après le " + startDate;
            }
            if (endDate) {
                msg += " avant le " + endDate;
            }
            ConfirmDialog.openConfirmRemove(msg, vm.removePrice);
        };

        vm.removePrice = function () {
            EventPrice.remove({id: vm.selectedPrice.id}, vm.load, function () {
                AlertService.error("Impossible de supprimer le tarif");
            });
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

    }
})();