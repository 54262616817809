(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetEventAbstractController', SheetEventAbstractController);

    SheetEventAbstractController.$inject = ['Principal', '$log', '$stateParams', '$state', 'eventAbstractInformation', 'DataUtils', 'AbstractPerson', 'SheetHistoryService', 'AbstractSociety', 'CustomUtils', 'AlertService', 'ConfirmDialog', 'EventAbstract', '$uibModal', '$http', '$q', '$translate', '$filter'];

    function SheetEventAbstractController(Principal, $log, $stateParams, $state, eventAbstractInformation, DataUtils, AbstractPerson, SheetHistoryService, AbstractSociety, CustomUtils, AlertService, ConfirmDialog, EventAbstract, $uibModal, $http, $q, $translate, $filter) {
        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: eventAbstractInformation.eventAbstract,
            abstractPeople: eventAbstractInformation.abstractPeople,
            axeName: eventAbstractInformation.axeName,
            sessionName: eventAbstractInformation.sessionName,
            mainEventName: eventAbstractInformation.mainEventName,
            mainEventId: eventAbstractInformation.mainEventId,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                title: false,
                main: false,
                description: false,
                abstractPerson: false,
                abstractSociety: false
            },
            htmlDesc: null
        };

        var societies = [];
        angular.forEach(eventAbstractInformation.abstractSocieties, function (abstractSociety, index) {
            var society = null;
            for (var i = 0; i < societies.length; i++) {
                var aSociety = societies[i];
                if (aSociety.name === abstractSociety.name) {
                    society = aSociety;
                    break;
                }
            }
            if (society === null) {
                society = {
                    name: abstractSociety.name,
                    societyId: abstractSociety.societyId,
                    abstractSocieties: [],
                    index: index //identifiant de cette structure javascript
                };
                societies.push(society);
            }
            society.abstractSocieties.push(abstractSociety);
        });

        //sort societies
        var sortedSocieties = [];
        angular.forEach(eventAbstractInformation.abstractPeople, function (abstractPerson) {
            for (var i = 0; i < societies.length; i++) {
                var society = societies[i];
                var alreadySorted = false;
                for (var j = 0; j < sortedSocieties.length; j++) {
                    if (sortedSocieties[j].index === society.index) {
                        alreadySorted = true;
                        break;
                    }
                }
                if (!alreadySorted) {
                    for (var k = 0; k < society.abstractSocieties.length; k++) {
                        var absSociety = society.abstractSocieties[k];
                        if (absSociety.abstractPersonId === abstractPerson.id) {
                            sortedSocieties.push(society);
                            break;
                        }
                    }
                }
            }
        });

        vm.Model.abstractSocieties = sortedSocieties;
        $log.info(vm.Model);

        var sheetHistory = {
            name: vm.Model.entity.title,
            type: 'eventabstract',
            id: vm.Model.id
        };
        SheetHistoryService.add(sheetHistory);


        var onSaveSuccess = function (result) {
            AlertService.success("OK");
            vm.load();
        };

        var onSaveError = function (result) {
            AlertService.error("KO");
            vm.load();
        };

        vm.load = function () {
            $state.reload();
        };
        vm.edit = function (win) {
            vm.Model.edition[win] = true;
        };

        vm.save = function () {
            var promise = $q.when(true);

            if (vm.abstractForm.$dirty || vm.abstractTitleForm.$dirty) {
                promise = promise.then(function () {
                    return EventAbstract.update(vm.Model.entity).$promise;
                });
            }

            if (vm.abstractPeopleForm.$dirty) {
                for (var index in vm.abstractPeopleForm) {
                    var getUpdatePromise = function (abstractPerson) {
                        return AbstractPerson.update(abstractPerson).$promise;
                    };
                    if (index.startsWith("firstName") || index.startsWith("lastName")) {
                        var formInput = vm.abstractPeopleForm[index];
                        if (formInput.$dirty && formInput.$valid) {
                            var id = index.split("_")[1];
                            var abstractPerson = null;
                            for (var i = 0; i < vm.Model.abstractPeople.length; i++) {
                                if (vm.Model.abstractPeople[i].id == id) {
                                    abstractPerson = vm.Model.abstractPeople[i];
                                    break;
                                }
                            }
                            if (abstractPerson !== null) {
                                var updatePromise = getUpdatePromise(abstractPerson);
                                promise = promise.then(updatePromise);
                            }
                        }
                    }
                }
            }
            if (vm.abstractSocietyForm.$dirty) {
                for (var key in vm.abstractSocietyForm) {
                    if (key.startsWith("society")) {
                        var formInput = vm.abstractSocietyForm[key];
                        if (formInput.$dirty && formInput.$valid) {
                            var index = key.split("_")[1];
                            var society = null;
                            for (var i = 0; i < vm.Model.abstractSocieties.length; i++) {
                                if (vm.Model.abstractSocieties[i].index == index) {
                                    society = vm.Model.abstractSocieties[i];
                                    break;
                                }
                            }
                            if (society !== null) {
                                var getUpdatePromise = function (abstractSociety) {
                                    return AbstractSociety.update(abstractSociety).$promise;
                                };
                                for (var i = 0; i < society.abstractSocieties.length; i++) {
                                    var abstractSociety = society.abstractSocieties[i];
                                    abstractSociety.name = society.name;

                                    var updatePromise = getUpdatePromise(abstractSociety);
                                    promise = promise.then(updatePromise);
                                }

                            }
                        }
                    }
                }
            }

            promise.then(onSaveSuccess, onSaveError);
        };



        vm.cancel = function (reload) {
            for (var key in vm.Model.edition) {
                vm.Model.edition[key] = false;
            }
            if (reload === true) {
                vm.load();
            }
        };

        vm.openAbstract = function () {
            DataUtils.downloadFileFromUrl("api/event-abstracts/" + vm.Model.entity.id + "/pdf", vm.Model.entity.title + ".pdf");
        };

        vm.updateAbstract = function () {
            $state.go('createAbstract', {
                id: vm.Model.mainEventId,
                key: vm.Model.entity.registrationKey,
                abstractId: vm.Model.entity.id
            });
        };

        vm.tryRemoveAbstract = function () {
            ConfirmDialog.openConfirmRemove(vm.Model.entity.title, vm.removeAbstract, function () {
                $log.info("Cancel");
            });
        };

        vm.removeAbstract = function () {
            EventAbstract.deleteAllInformations({id: vm.Model.entity.id}, function () {
                $log.info("ok");
                $state.go("home");
            }, function (error) {
                $log.error("ko", error);
            });
        };

        vm.linkToPerson = function (abstractPerson) {
            $uibModal.open({
                templateUrl: 'app/sheet/eventabstract/dialog/link-person.dialog.html',
                controller: 'LinkPersonDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('entities');
                            return $translate.refresh();
                        }],
                    abstractPerson: abstractPerson
                }
            }).result.then(function () {
                vm.load();
            });
        };

        vm.tryUnlinkPerson = function (abstractPerson) {
            var name = abstractPerson.firstName + ' ' + abstractPerson.lastName;
            var msg = 'Confirmez-vous la dissociation de "' + name + '" avec la fiche en base ?';
            ConfirmDialog.openYesNo('Confirmation de dissociation', msg, function (result) {
                if (result === true) {
                    vm.unlinkPerson(abstractPerson);
                }
            });
        };

        vm.unlinkPerson = function (abstractPerson) {
            abstractPerson.personId = null;
            AbstractPerson.update(abstractPerson, function () {
                AlertService.success("Modification effectuée.");
                $state.reload();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde en base.");
            });
        };

        vm.linkToSociety = function (abstractSocieties) {
            $uibModal.open({
                templateUrl: 'app/sheet/eventabstract/dialog/link-society.dialog.html',
                controller: 'LinkSocietyDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('entities');
                            return $translate.refresh();
                        }],
                    abstractSocieties: abstractSocieties
                }
            }).result.then(function () {
                vm.load();
            });
        };

        vm.tryUnlinkSociety = function (abstractSociety) {
            var msg = 'Confirmer vous la dissociation de "' + abstractSociety.name + '" avec la fiche en base ?';
            ConfirmDialog.openYesNo('Confirmation de dissociation', msg, function (result) {
                if (result === true) {
                    vm.unlinkSociety(abstractSociety.abstractSocieties);
                }
            });
        };

        vm.unlinkSociety = function (abstractSocieties) {
            var promises = [];
            angular.forEach(abstractSocieties, function (abstractSociety) {
                abstractSociety.societyId = null;
                promises.push(AbstractSociety.update(abstractSociety).$promise);
            });

            $q.all(promises).then(function () {
                AlertService.success("Modification effectuée.");
                $state.reload();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde en base.");
            });

        };

    }
})();
