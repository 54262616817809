(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('AddExpertDialogController', AddExpertDialogController);

    AddExpertDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'callForProject', '$http', '$log', 'PersonSearch', 'submissions', 'JQWidgetsService', '$timeout', 'Groupe', 'CustomUtils', 'AlertService'];

    function AddExpertDialogController($translate, $translatePartialLoader, $uibModalInstance, callForProject, $http, $log, PersonSearch, submissions, JQWidgetsService, $timeout, Groupe, CustomUtils, AlertService) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        var ctrl = this;
        ctrl.callForProject = callForProject;
        ctrl.submissions = submissions;
        ctrl.selectedPerson = null;
        ctrl.selectedType = null;

        var label = $translate.instant('canceropoleGsoApp.CFPFormType.LI');
        if (!ctrl.callForProject.privateSpace) {
            label = $translate.instant('canceropoleGsoApp.CFPFormType.FS');
        }
        ctrl.typeSource = [{
                label: label,
                value: 'LI'
            }];

        if (ctrl.callForProject.privateSpace) {
            ctrl.typeSource.push({
                label: $translate.instant('canceropoleGsoApp.CFPFormType.EP'),
                value: 'EP'
            });
        }

        ctrl.typeSettings = {
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            displayMember: "label",
            valueMember: "value",
            source: ctrl.typeSource,
            change: function (event) {
                if (event.args) {
                    $log.info(event.args);
                    ctrl.editForm.$setDirty();
                }
            },
            created: function (args) {
                ctrl.typesCombo = args.instance;
                if (ctrl.typeSource.length === 1) {
                    $timeout(function () {
                        ctrl.typesCombo.selectIndex(0);
                    }, 100);
                }
            }
        };

        var expertListDataAdapter = new $.jqx.dataAdapter({
            datatype: 'json',
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'firstName',
                    type: 'string'
                }, {
                    name: 'lastName',
                    type: 'string'
                }, , {
                    name: 'name',
                    type: 'string'
                }]
        });

        ctrl.peopleListBoxSettings = {
            width: '100%',
            height: 200,
            displayMember: 'name',
            valueMember: 'id',
            source: expertListDataAdapter,
            created: function (args) {
                ctrl.listBox = args.instance;
            }
        };

        ctrl.ok = function () {
            ctrl.isSaving = true;

            var experts = ctrl.listBox.getItems();

            var expertIds = [];
            angular.forEach(experts, function (expert) {
                expertIds.push(expert.value);
            });

            var submissionIds = [];
            angular.forEach(ctrl.submissions, function (submission) {
                submissionIds.push(submission.id);
            });

            var toSend = {
                submissionIds: submissionIds,
                personIds: expertIds
            };

            $http.put("api/experts/assign/" + ctrl.callForProject.id + "/" + ctrl.selectedType, toSend)
                    .then(function () {
                        AlertService.success("Affectation sauvegardée en base.");
                        $uibModalInstance.close();
                    }, onSaveError);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function onSaveError(response) {
            ctrl.isSaving = false;
            $log.error(response);
        }

        var personSource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var personDataAdapter = new $.jqx.dataAdapter(personSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                PersonSearch.query({
                    query: ctrl.personComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    angular.forEach(result, function (item) {
                        item.name = item.firstName + " " + item.lastName;
                    });
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.personComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            autoDropDownHeight: true,
            remoteAutoComplete: true,
            source: personDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                ctrl.personComboBoxSettings.searchString = searchString;
                personDataAdapter.dataBind();
            },
            created: function (args) {
                ctrl.personComboBox = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    ctrl.addExpert(item.originalItem);
                    ctrl.personComboBox.clearSelection();
                }
            },
            unselect: function () {
            },
            change: function () {
            }
        };

        var groupeSource = {
            datatype: 'json',
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'description',
                    type: 'string'
                }, {
                    name: 'people',
                    type: 'array'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var groupeDataAdapter = new $.jqx.dataAdapter(groupeSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                Groupe.query({}, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        ctrl.groupSettings = {
            source: groupeDataAdapter,
            displayMember: "name",
            valueMember: "id",
            width: '100%',
            height: 30,
            placeHolder: JQWidgetsService.getDropdownListPlaceHolder($translate.use()),
            autoDropDownHeight: true,
            created: function (args) {
                ctrl.groupeDropdownList = args.instance;
            },
            select: function (event) {
                if (event.args) {
                    var item = event.args.item;
                    var group = item.originalItem;
                    angular.forEach(group.people, function (person) {
                        person.name = person.firstName + " " + person.lastName;
                        ctrl.addExpert(person);
                    });

                    ctrl.groupeDropdownList.clearSelection();
                }
            },
            unselect: function () {
            },
            change: function () {
            }
        };

        ctrl.addExpert = function (person) {
            var experts = ctrl.listBox.getItems();

            var found = false;
            for (var i = 0; i < experts.length; i++) {
                if (experts[i].value === person.id) {
                    found = true;
                }
            }

            if (!found) {
                ctrl.listBox.addItem(person);
            }
        };

        ctrl.removeExpert = function () {
            ctrl.listBox.removeItem(ctrl.selectedPerson);
        };
    }
})();