(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .factory('JQWidgetsService', JQWidgetsService);

    JQWidgetsService.$inject = ['CharactersDialog', 'CustomUtils'];

    function JQWidgetsService(CharactersDialog, CustomUtils) {

        var service = {
            getLocalization: getLocalization,
            getDropdownListPlaceHolder: getDropdownListPlaceHolder,
            getEditorCommand: getEditorCommand,
            getDefaultAdminEditorTools: getDefaultAdminEditorTools
        };

        return service;

        function getDropdownListPlaceHolder(culture) {
            switch (culture) {
                case "fr":
                    return "Veuillez choisir...";
                default:
                    return "Please choose...";
            }
        }

        function getEditorCommand(model) {
            return function (name) {
                var instance = this;
                switch (name) {
                    case 'characters':
                        return {
                            type: 'button',
                            tooltip: 'Caractères spéciaux',
                            init: function (widget) {
                                widget.jqxButton({height: 25, width: 25});
                                widget.html("<span style='line-height: 25px;'><b>&Omega;</b></span>");
                            },
                            action: function (widget, editor) {
                                CharactersDialog.open().then(function (character) {
                                    instance.execute('insertHTML', character);
                                    model = editor.val();
                                });
                            }
                        };
                    case 'exposant':
                        return {
                            type: 'button',
                            tooltip: 'Exposant',
                            init: function (widget) {
                                widget.jqxButton({height: 25, width: 25});
                                widget.html('<span style="line-height: 25px;"><span class="glyphicon glyphicon-superscript"></span></span>');
                            },
                            action: function () {
                                var selection = instance.selection.getText();
                                if (!CustomUtils.isEmpty(selection)) {
                                    instance.execute('insertHTML', '<sup>' + selection + '</sup>');
                                }
                            }
                        };
                    case 'indice':
                        return {
                            type: 'button',
                            tooltip: 'Indice',
                            init: function (widget) {
                                widget.jqxButton({height: 25, width: 25});
                                widget.html('<span style="line-height: 25px;"><span class="glyphicon glyphicon-subscript"></span></span>');
                            },
                            action: function () {
                                var selection = instance.selection.getText();
                                if (!CustomUtils.isEmpty(selection)) {
                                    instance.execute('insertHTML', '<sub>' + selection + '</sub>');
                                }
                            }
                        };
                    case 'justify':
                        return {
                            type: 'button',
                            tooltip: 'Justifier',
                            init: function (widget) {
                                widget.jqxButton({height: 25, width: 25});
                                widget.html('<span style="line-height: 25px;"><img src="content/images/textJustify.png"></img></span>');
                            },
                            action: function () {
                                var selection = instance.selection.getText();
                                if (!CustomUtils.isEmpty(selection)) {
                                    instance.execute('insertHTML', '<div align="justify">' + selection + '</div>');
                                }
                            }
                        };
                }
            };
        }

        function getDefaultAdminEditorTools() {
            var tools = 'bold italic underline | ' +
                    'characters | exposant indice | ' +
                    'format font size | +' +
                    'color background | ' +
                    'left center right justify | ' +
                    'outdent indent | ' +
                    'ul ol | ' +
                    'link | clean | html';
            return tools;
        }

        function getLocalization(culture) {
            var localization = null;
            switch (culture) {
                case "de":
                    localization = {
                        // separator of parts of a date (e.g. '/' in 11/05/1955)
                        '/': "/",
                        // separator of parts of a time (e.g. ':' in 05:44 PM)
                        ':': ":",
                        // the first day of the week (0 = Sunday, 1 = Monday, etc)
                        firstDay: 1,
                        days: {
                            // full day names
                            names: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                            // abbreviated day names
                            namesAbbr: ["Sonn", "Mon", "Dien", "Mitt", "Donn", "Fre", "Sams"],
                            // shortest day names
                            namesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
                        },
                        months: {
                            // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
                            names: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""],
                            // abbreviated month names
                            namesAbbr: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dez", ""]
                        },
                        // AM and PM designators in one of these forms:
                        // The usual view, and the upper and lower case versions
                        //      [standard,lowercase,uppercase]
                        // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
                        //      null
                        AM: ["AM", "am", "AM"],
                        PM: ["PM", "pm", "PM"],
                        eras: [
                            // eras in reverse chronological order.
                            // name: the name of the era in this culture (e.g. A.D., C.E.)
                            // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
                            // offset: offset in years from gregorian calendar
                            {"name": "A.D.", "start": null, "offset": 0}
                        ],
                        twoDigitYearMax: 2029,
                        patterns:
                                {
                                    d: "dd.MM.yyyy",
                                    D: "dddd, d. MMMM yyyy",
                                    t: "HH:mm",
                                    T: "HH:mm:ss",
                                    f: "dddd, d. MMMM yyyy HH:mm",
                                    F: "dddd, d. MMMM yyyy HH:mm:ss",
                                    M: "dd MMMM",
                                    Y: "MMMM yyyy"

                                },
                        percentsymbol: "%",
                        currencysymbol: "€",
                        currencysymbolposition: "after",
                        decimalseparator: '.',
                        thousandsseparator: ',',
                        pagergotopagestring: "Gehe zu",
                        pagershowrowsstring: "Zeige Zeile:",
                        pagerrangestring: " von ",
                        pagerpreviousbuttonstring: "nächster",
                        pagernextbuttonstring: "voriger",
                        pagerfirstbuttonstring: "first",
                        pagerlastbuttonstring: "last",
                        groupsheaderstring: "Ziehen Sie eine Kolumne und legen Sie es hier zu Gruppe nach dieser Kolumne",
                        sortascendingstring: "Sortiere aufsteigend",
                        sortdescendingstring: "Sortiere absteigend",
                        sortremovestring: "Entferne Sortierung",
                        groupbystring: "Group By this column",
                        groupremovestring: "Remove from groups",
                        filterclearstring: "Löschen",
                        filterstring: "Filter",
                        filtershowrowstring: "Zeige Zeilen, in denen:",
                        filterorconditionstring: "Oder",
                        filterandconditionstring: "Und",
                        filterselectallstring: "(Alle auswählen)",
                        filterchoosestring: "Bitte wählen Sie:",
                        filterstringcomparisonoperators: ['leer', 'nicht leer', 'enthält', 'enthält(gu)',
                            'nicht enthalten', 'nicht enthalten(gu)', 'beginnt mit', 'beginnt mit(gu)',
                            'endet mit', 'endet mit(gu)', 'equal', 'gleich(gu)', 'null', 'nicht null'],
                        filternumericcomparisonoperators: ['gleich', 'nicht gleich', 'weniger als', 'kleiner oder gleich', 'größer als', 'größer oder gleich', 'null', 'nicht null'],
                        filterdatecomparisonoperators: ['gleich', 'nicht gleich', 'weniger als', 'kleiner oder gleich', 'größer als', 'größer oder gleich', 'null', 'nicht null'],
                        filterbooleancomparisonoperators: ['gleich', 'nicht gleich'],
                        validationstring: "Der eingegebene Wert ist ungültig",
                        emptydatastring: "Nokeine Daten angezeigt",
                        filterselectstring: "Wählen Sie Filter",
                        loadtext: "Loading...",
                        clearstring: "Löschen",
                        todaystring: "Heute"
                    };
                    break;
                case "fr" :
                    localization = {
                        "/": "/",
                        ":": ":",
                        "firstDay": 1,
                        "days": {
                            "names": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
                            "namesAbbr": ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
                            "namesShort": ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]
                        },
                        "months": {
                            "names": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre", ""],
                            "namesAbbr": ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc.", ""]
                        },
                        "AM": ["AM", "am", "AM"],
                        "PM": ["PM", "pm", "PM"],
                        "eras": [{
                                "name": "ap. J.-C.",
                                "start": null,
                                "offset": 0
                            }],
                        "twoDigitYearMax": 2029,
                        "patterns": {
                            "d": "dd/MM/yyyy",
                            "D": "dddd, MMMM dd, yyyy",
                            "t": "h:mm tt",
                            "T": "h:mm:ss tt",
                            "f": "dddd, MMMM dd, yyyy h:mm tt",
                            "F": "dddd, MMMM dd, yyyy h:mm:ss tt",
                            "M": "MMMM dd",
                            "Y": "yyyy MMMM",
                            "S": "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                            "ISO": "yyyy-MM-dd hh:mm:ss",
                            "ISO2": "yyyy-MM-dd HH:mm:ss",
                            "d1": "dd.MM.yyyy",
                            "d2": "dd-MM-yyyy",
                            "d3": "dd-MMMM-yyyy",
                            "d4": "dd-MM-yy",
                            "d5": "H:mm",
                            "d6": "HH:mm",
                            "d7": "HH:mm tt",
                            "d8": "dd/MMMM/yyyy",
                            "d9": "MMMM-dd",
                            "d10": "MM-dd",
                            "d11": "MM-dd-yyyy"
                        },
                        "agendaDateColumn": "Date",
                        "agendaTimeColumn": "Heure",
                        "agendaAppointmentColumn": "Evénement",
                        "backString": "Avant",
                        "forwardString": "Après",
                        "toolBarPreviousButtonString": "Période précédente",
                        "toolBarNextButtonString": "Période suivante",
                        "emptyDataString": "Aucun événement à afficher",
                        "loadString": "Chargement...",
                        "clearString": "Effacer",
                        "todayString": "Aujourd'hui",
                        "dayViewString": "Jour",
                        "weekViewString": "Semaine",
                        "monthViewString": "Mois",
                        "agendaViewString": "Agenda",
                        "timelineDayViewString": "Timeline Day",
                        "timelineWeekViewString": "Timeline Week",
                        "timelineMonthViewString": "Timeline Month",
                        "agendaAllDayString": "all day",
                        "loadingErrorMessage": "The data is still loading and you cannot set a property or call a method. You can do that once the data binding is completed. jqxScheduler raises the 'bindingComplete' event when the binding is completed.",
                        "editRecurringAppointmentDialogTitleString": "Edit Recurring Appointment",
                        "editRecurringAppointmentDialogContentString": "Do you want to edit only this occurrence or the series?",
                        "editRecurringAppointmentDialogOccurrenceString": "Edit Occurrence",
                        "editRecurringAppointmentDialogSeriesString": "Edit The Series",
                        "editDialogTitleString": "Edit Appointment",
                        "editDialogCreateTitleString": "Créer un événement",
                        "contextMenuEditAppointmentString": "Modifier un événement",
                        "contextMenuCreateAppointmentString": "Créer un événement",
                        "editDialogSubjectString": "Sujet",
                        "editDialogLocationString": "Lieu",
                        "editDialogFromString": "De",
                        "editDialogToString": "A",
                        "editDialogAllDayString": "Toute la journée",
                        "editDialogExceptionsString": "Exceptions",
                        "editDialogResetExceptionsString": "Reset on Save",
                        "editDialogDescriptionString": "Description",
                        "editDialogResourceIdString": "Calendrier",
                        "editDialogStatusString": "Statut",
                        "editDialogColorString": "Couleur",
                        "editDialogColorPlaceHolderString": "Choisir la couleur",
                        "editDialogTimeZoneString": "Fuseau horaire",
                        "editDialogSelectTimeZoneString": "Selectionner le fuseau horaire",
                        "editDialogSaveString": "Sauvegarder",
                        "editDialogDeleteString": "Supprimer",
                        "editDialogCancelString": "Annuler",
                        "editDialogRepeatString": "Répéter",
                        "editDialogRepeatEveryString": "Répéter tous les",
                        "editDialogRepeatEveryWeekString": "semaine(s)",
                        "editDialogRepeatEveryYearString": "année(s)",
                        "editDialogRepeatEveryDayString": "jour(s)",
                        "editDialogRepeatNeverString": "Jamais",
                        "editDialogRepeatDailyString": "Quotidiennement",
                        "editDialogRepeatWeeklyString": "Weekly",
                        "editDialogRepeatMonthlyString": "Monthly",
                        "editDialogRepeatYearlyString": "Yearly",
                        "editDialogRepeatEveryMonthString": "month(s)",
                        "editDialogRepeatEveryMonthDayString": "Day",
                        "editDialogRepeatFirstString": "first",
                        "editDialogRepeatSecondString": "second",
                        "editDialogRepeatThirdString": "third",
                        "editDialogRepeatFourthString": "fourth",
                        "editDialogRepeatLastString": "last",
                        "editDialogRepeatEndString": "End",
                        "editDialogRepeatAfterString": "After",
                        "editDialogRepeatOnString": "On",
                        "editDialogRepeatOfString": "of",
                        "editDialogRepeatOccurrencesString": "occurrence(s)",
                        "editDialogRepeatSaveString": "Save Occurrence",
                        "editDialogRepeatSaveSeriesString": "Save Series",
                        "editDialogRepeatDeleteString": "Delete Occurrence",
                        "editDialogRepeatDeleteSeriesString": "Delete Series",
                        "editDialogStatuses": {
                            "free": "Libre",
                            "tentative": "Peut être",
                            "busy": "Occupé",
                            "outOfOffice": "Absent du bureau"
                        },
                        sortascendingstring: "Trier par ordre croissant",
                        sortdescendingstring: "Trier par ordre décroissant",
                        sortremovestring: "Ne pas trier",
                        groupsheaderstring: "Glisser et déposer une colonne ici pour grouper par cette colonne",
                        filterclearstring: "Effacer",
                        filterstring: "Filtrer",
                        filtershowrowstring: "Afficher les lignes où:",
                        filterorconditionstring: "Ou",
                        filterandconditionstring: "Et",
                        filterselectallstring: "(Tout selectionner)",
                        filterchoosestring: "Veuillez choisir :",
                        filterselectstring: "Choisir un Filtre",
                        filternumericcomparisonoperators: ['égale à', 'différent de', 'inférieur à', 'inférieur ou égale à', 'supérieur à', 'supérieur ou égale à', 'non défini', 'défini'],
                        loadtext: "Chargement...",
                        currencysymbol: " €",
                        currencysymbolposition: "after",
                        decimalseparator: ',',
                        thousandsseparator: ' '
                    };
                    break;
                case "en":
                default:
                    localization = {
                        // separator of parts of a date (e.g. '/' in 11/05/1955)
                        '/': "/",
                        // separator of parts of a time (e.g. ':' in 05:44 PM)
                        ':': ":",
                        // the first day of the week (0 = Sunday, 1 = Monday, etc)
                        firstDay: 0,
                        days: {
                            // full day names
                            names: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                            // abbreviated day names
                            namesAbbr: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                            // shortest day names
                            namesShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
                        },
                        months: {
                            // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
                            names: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ""],
                            // abbreviated month names
                            namesAbbr: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", ""]
                        },
                        // AM and PM designators in one of these forms:
                        // The usual view, and the upper and lower case versions
                        //      [standard,lowercase,uppercase]
                        // The culture does not use AM or PM (likely all standard date formats use 24 hour time)
                        //      null
                        AM: ["AM", "am", "AM"],
                        PM: ["PM", "pm", "PM"],
                        eras: [
                            // eras in reverse chronological order.
                            // name: the name of the era in this culture (e.g. A.D., C.E.)
                            // start: when the era starts in ticks (gregorian, gmt), null if it is the earliest supported era.
                            // offset: offset in years from gregorian calendar
                            {"name": "A.D.", "start": null, "offset": 0}
                        ],
                        twoDigitYearMax: 2029,
                        patterns: {
                            // short date pattern
                            d: "M/d/yyyy",
                            // long date pattern
                            D: "dddd, MMMM dd, yyyy",
                            // short time pattern
                            t: "h:mm tt",
                            // long time pattern
                            T: "h:mm:ss tt",
                            // long date, short time pattern
                            f: "dddd, MMMM dd, yyyy h:mm tt",
                            // long date, long time pattern
                            F: "dddd, MMMM dd, yyyy h:mm:ss tt",
                            // month/day pattern
                            M: "MMMM dd",
                            // month/year pattern
                            Y: "yyyy MMMM",
                            // S is a sortable format that does not vary by culture
                            S: "yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss",
                            // formatting of dates in MySQL DataBases
                            ISO: "yyyy-MM-dd hh:mm:ss",
                            ISO2: "yyyy-MM-dd HH:mm:ss",
                            d1: "dd.MM.yyyy",
                            d2: "dd-MM-yyyy",
                            d3: "dd-MMMM-yyyy",
                            d4: "dd-MM-yy",
                            d5: "H:mm",
                            d6: "HH:mm",
                            d7: "HH:mm tt",
                            d8: "dd/MMMM/yyyy",
                            d9: "MMMM-dd",
                            d10: "MM-dd",
                            d11: "MM-dd-yyyy"
                        },
                        percentsymbol: "%",
                        currencysymbol: "$",
                        currencysymbolposition: "before",
                        decimalseparator: '.',
                        thousandsseparator: ',',
                        pagergotopagestring: "Go to page:",
                        pagershowrowsstring: "Show rows:",
                        pagerrangestring: " of ",
                        pagerpreviousbuttonstring: "previous",
                        pagernextbuttonstring: "next",
                        pagerfirstbuttonstring: "first",
                        pagerlastbuttonstring: "last",
                        groupsheaderstring: "Drag a column and drop it here to group by that column",
                        sortascendingstring: "Sort Ascending",
                        sortdescendingstring: "Sort Descending",
                        sortremovestring: "Remove Sort",
                        groupbystring: "Group By this column",
                        groupremovestring: "Remove from groups",
                        filterclearstring: "Clear",
                        filterstring: "Filter",
                        filtershowrowstring: "Show rows where:",
                        filterorconditionstring: "Or",
                        filterandconditionstring: "And",
                        filterselectallstring: "(Select All)",
                        filterchoosestring: "Please Choose:",
                        filterstringcomparisonoperators: ['empty', 'not empty', 'enthalten', 'enthalten(match case)',
                            'does not contain', 'does not contain(match case)', 'starts with', 'starts with(match case)',
                            'ends with', 'ends with(match case)', 'equal', 'equal(match case)', 'null', 'not null'],
                        filternumericcomparisonoperators: ['equal', 'not equal', 'less than', 'less than or equal', 'greater than', 'greater than or equal', 'null', 'not null'],
                        filterdatecomparisonoperators: ['equal', 'not equal', 'less than', 'less than or equal', 'greater than', 'greater than or equal', 'null', 'not null'],
                        filterbooleancomparisonoperators: ['equal', 'not equal'],
                        validationstring: "Entered value is not valid",
                        emptydatastring: "No data to display",
                        filterselectstring: "Select Filter",
                        loadtext: "Loading...",
                        clearstring: "Clear",
                        todaystring: "Today"
                    };
                    break;
            }
            return localization;
        }
    }
})();