(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .filter('capitalize', capitalize);

    function capitalize() {
        String.prototype.toCapitalize = function () {
            return this.toLowerCase().replace(/(?:^|\s|-)\S/g, function (a) {
                return a.toUpperCase();
            });
        };

        return capitalizeFilter;

        function capitalizeFilter(input) {
            if (input != null) {
                return input.toCapitalize();
            }
            return input;
        }
    }
})();
