(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('CallForProjectSpaceFinalStepController', CallForProjectSpaceFinalStepController);

    CallForProjectSpaceFinalStepController.$inject = ['Principal', 'Submission', 'allInformations', '$log', '$state', 'AlertService', '$stateParams'];

    function CallForProjectSpaceFinalStepController(Principal, Submission, allInformations, $log, $state, AlertService, $stateParams) {
        var vm = this;
        vm.spaceKey = $stateParams.key;
        vm.submission = allInformations.submission;

        vm.isAdmin = function () {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                return true;
            }
            return false;
        };

        vm.reload = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.editorSettings = {
            width: '100%',
            height: 500,
            pasteMode: "text",
            created: function (event) {
                vm.editor = event.instance;
            }
        };

        vm.save = function () {
            Submission.update(vm.submission, function () {
                AlertService.success("Evaluation sauvegardée.");
            });
        };
    }
})();