/* global _ */

(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('ManifestationController', ManifestationController);

    ManifestationController.$inject = ['MainEvent', 'CustomUtils', '$log', 'AlertService', '$q', 'FileAttachement', '$filter', '$state', '$stateParams', '$http', 'ConfirmDialog', '$translate', 'JQWidgetsService', 'DataUtils', 'EventRegistration', '$uibModal', 'DateUtils'];

    function ManifestationController(MainEvent, CustomUtils, $log, AlertService, $q, FileAttachement, $filter, $state, $stateParams, $http, ConfirmDialog, $translate, JQWidgetsService, DataUtils, EventRegistration, $uibModal, DateUtils) {
        var vm = this;
        vm.mainEvent = null;

        //charge une fois le contexte lorsque le scheduler est chargé
        var firstLoad = true;

        vm.reset = function () {
            vm.mainEvent = null;
        };

        vm.load = function (appointment) {
            vm.sessions = null;
            if (appointment.originalData) {
                vm.mainEvent = appointment.originalData;
            } else {
                vm.mainEvent = appointment;
            }
            vm.openStateString = vm.inscriptionOpen() ? "Les inscriptions sont ouvertes" : "Les inscriptions sont closes";
            vm.refreshData();
            $state.go('manifestation', {event: vm.mainEvent.id}, {notify: false});
        };

        vm.inscriptionOpen = function () {
            if (vm.mainEvent.publique) {
                var limitDate = vm.mainEvent.inscriptionLimitDate;
                if (DateUtils.compare(limitDate, new Date()) > 0) {
                    return true;
                }
            }
            return false;
        };

        vm.updateDateRange = function (newDate) {
            //range search definition (1 years before and 1 years after)
            vm.currentDate = newDate;
            if (CustomUtils.isUndefinedOrNull(newDate)) {
                vm.currentDate = new $.jqx.date().date();
            }
            //with negative value the jqxWidget add a bug. It remove one year more.
            vm.currentFrom = vm.currentDate.addYears(-1, true).addYears(1).date();
            vm.currentTo = vm.currentDate.addYears(1, true).date();

            $log.debug("currentDate=" + vm.currentDate.toString("yyyy-MM-dd"));
            $log.debug("currentFrom=" + vm.currentFrom.toString("yyyy-MM-dd"));
            $log.debug("currentTo=" + vm.currentTo.toString("yyyy-MM-dd"));
            vm.schedulerSource.data.from = vm.currentFrom.toString("yyyy-MM-dd");
            vm.schedulerSource.data.to = vm.currentTo.toString("yyyy-MM-dd");
        };

        vm.changeOpenState = function () {
            MainEvent.update(vm.mainEvent, vm.load);
        };

        vm.searchSource = {
            datatype: 'json',
            datafields: [{
                    name: 'firstName'
                }, {
                    name: 'lastName'
                }],
            id: 'id',
            url: 'api/_search/people',
            data: {
                size: '8'
            }
        };

        var searchDataAdapter = new $.jqx.dataAdapter(vm.searchSource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                MainEvent.query({
                    query: vm.searchComboBoxSettings.searchString,
                    page: 0,
                    size: 7
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        vm.searchComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            remoteAutoComplete: true,
            source: searchDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.searchComboBoxSettings.searchString = searchString;
                searchDataAdapter.dataBind();
            },
            select: function (event) {
                if (event.args) {
                    var mainEvent = event.args.item.originalItem;
                    vm.showEvent(mainEvent);
                }
            }
        };

        vm.showEvent = function (mainEvent) {
            if (CustomUtils.isUndefinedOrNull(mainEvent)) {
                return;
            }

            var id = mainEvent.id;
            var startDate = new $.jqx.date(mainEvent.startDate);
            var endDate = new $.jqx.date(mainEvent.endDate);

            var waitForResult = false;

            if (startDate < vm.currentFrom || endDate > vm.currentTo) {
                vm.updateDateRange(startDate);
                $log.debug("reload events");
                var schedulerAdapter = new $.jqx.dataAdapter(vm.schedulerSource);
                vm.schedulerSettings.source = schedulerAdapter;
                vm.schedulerSettings.resources.source = schedulerAdapter;

                vm.schedulerSettings.refresh(['source']);
                waitForResult = true;
            }
            vm.schedulerSettings.apply('date', startDate);

            if (waitForResult) {
                setTimeout(function () {
                    vm.schedulerSettings.apply('selectAppointment', id);
                    vm.schedulerSettings.apply('ensureAppointmentVisible', id);
                }, 800);
            } else {
                vm.schedulerSettings.apply('selectAppointment', id);
                vm.schedulerSettings.apply('ensureAppointmentVisible', id);
            }
            vm.load(mainEvent);
        };

        vm.schedulerSource = {
            datatype: 'json',
            url: 'api/public/_search/main-events/start',
            data: {
                field: "startDate",
                from: "now-1y",
                to: "now",
                size: 1000
            },
            dataFields: [
                {name: 'id', type: 'number'},
                {name: 'name', type: 'string'},
                {name: 'type', type: 'string'},
                {name: 'description', type: 'string'},
                {name: 'location', type: 'string'},
                {name: 'lng', type: 'number'},
                {name: 'lat', type: 'number'},
                {name: 'phone', type: 'string'},
                {name: 'email', type: 'string'},
                {name: 'webSite', type: 'string'},
                {name: 'prefix', type: 'string'},
                {name: 'startDate', type: 'date'},
                {name: 'endDate', type: 'date'},
                {name: 'inscriptionLimitDate', type: 'date'},
                {name: 'inscriptionLimitDateEarlybird', type: 'date'},
                {name: 'posterLimitDate', type: 'date'},
                {name: 'oralCommunicationLimitDate', type: 'date'},
                {name: 'expectedParticipation', type: 'number'},
                {name: 'estimatedBudget', type: 'float'},
                {name: 'expectedCost', type: 'float'},
                {name: 'expectedIncome', type: 'float'},
                {name: 'result', type: 'float'},
                {name: 'expectedProgram', type: 'string'},
                {name: 'globalProgram', type: 'string'},
                {name: 'detailProgram', type: 'string'},
                {name: 'publique', type: 'bool'}
            ],
            id: 'id'
        };

        vm.updateDateRange();

        var schedulerAdapter = new $.jqx.dataAdapter(vm.schedulerSource);

        vm.schedulerSettings = {
            width: '100%',
            height: '500',
            view: 'monthView',
            showLegend: true,
            localization: {},
            views: [
                'dayView',
                'weekView',
                'monthView'
            ],
            source: vm.schedulerSource,
            resources: {
                //colorScheme: "scheme05",
                dataField: 'type',
                source: schedulerAdapter
            },
            appointmentDataFields: {
                from: 'startDate',
                to: 'endDate',
                id: 'id',
                description: 'description',
                location: 'location',
                subject: 'name',
                resourceId: 'type'
            },
            appointmentClick: function (event) {
                var args = event.args;
                var appointment = args.appointment;
                vm.load(appointment);
            },
            appointmentAdd: function (event) {
                var appointment = event.args.appointment;
                $log.debug(appointment);
                var mainEvent = {
                    name: appointment.subject,
                    startDate: appointment.from.toDate().toISOString(),
                    endDate: appointment.to.toDate().toISOString(),
                    type: appointment.resourceId
                };
                MainEvent.save(mainEvent, function (result) {
                    $log.debug(result);
                    vm.schedulerSettings.refresh(['source']);
                }, function (error) {
                    $log.debug(error);
                });
            },
            appointmentChange: function (event) {
                var changedAppointment = event.args.appointment;

                MainEvent.get({id: changedAppointment.id}, function (mainEvent) {
                    mainEvent.location = changedAppointment.location;
                    mainEvent.startDate = changedAppointment.from.toDate().toISOString();
                    mainEvent.endDate = changedAppointment.to.toDate().toISOString();
                    mainEvent.name = changedAppointment.subject;
                    mainEvent.type = changedAppointment.resourceId;

                    MainEvent.update(mainEvent, function () {
                        AlertService.success("Saved");
                    });
                });
            },
            appointmentDelete: function (event) {
                var appointment = event.args.appointment;
                $log.info("delete appointment with id " + appointment.id);
                MainEvent.delete({id: appointment.id}, function () {
                    vm.reset();
                });
            },
            dateChange: function (event) {
                var args = event.args;
                var date = args.date;
                var from = args.from;
                var to = args.to;

                if (from < vm.currentFrom || to > vm.currentTo) {
                    vm.updateDateRange(date);
                    $log.debug("reload events");
                    var schedulerAdapter = new $.jqx.dataAdapter(vm.schedulerSource);
                    vm.schedulerSettings.source = schedulerAdapter;
                    vm.schedulerSettings.resources.source = schedulerAdapter;

                    vm.schedulerSettings.refresh(['source']);
                }
            },
            bindingComplete: function () {
                if ($stateParams.event !== null && firstLoad === true) {
                    firstLoad = false;
                    for (var i = 0; i < schedulerAdapter.records.length; i++) {
                        var record = schedulerAdapter.records[i];
                        if (record.id == $stateParams.event) {
                            vm.showEvent(record);
                            break;
                        }
                    }
                }
            }
        };

        vm.refreshData = function () {
            var participationSource = {
                datatype: "json",
                datafields: [{
                        name: "id",
                        type: 'number'
                    }, {
                        name: 'civilite',
                        type: 'string'
                    }, {
                        name: 'firstName',
                        type: 'string'
                    }, {
                        name: 'lastName',
                        type: 'string'
                    }, {
                        name: 'name',
                        type: 'string'
                    }, {
                        name: 'societyName',
                        type: 'string'
                    }, {
                        name: 'city',
                        type: 'string'
                    }, {
                        name: 'email',
                        type: 'string'
                    }, {
                        name: 'phone',
                        type: 'string'
                    }, {
                        name: 'facturationSocietyName',
                        type: 'string'
                    }, {
                        name: 'facturationSocietyAddress',
                        type: 'string'
                    }, {
                        name: 'facturationSocietyCp',
                        type: 'string'
                    }, {
                        name: 'facturationSocietyCity',
                        type: 'string'
                    }, {
                        name: 'facturationSocietyCountry',
                        type: 'string'
                    }, {
                        name: 'totalPrice',
                        type: 'number'
                    }, {
                        name: 'personId',
                        type: 'number'
                    }, {
                        name: 'societyId',
                        type: 'number'
                    }, {
                        name: 'confirmed',
                        map: 'confirmed',
                        type: 'bool'
                    }, {
                        name: 'registrationDate',
                        type: 'date'
                    }, {
                        name: 'orderRef',
                        type: 'string'
                    }, {
                        name: 'billRef',
                        type: 'string'
                    }, {
                        name: 'attachementId',
                        type: 'number'
                    }, {
                        name: 'key',
                        type: 'number'
                    }
                ],
                sortcolumn: 'registrationDate',
                sortdirection: 'desc',
                id: 'id'
            };

            $http.get('api/event-registrations/main-event-management/' + vm.mainEvent.id).then(function (response) {
                if (response.status === 200) {
                    participationSource.localdata = response.data;
                    vm.participationsSettings.source = new $.jqx.dataAdapter(participationSource, {
                        beforeLoadComplete: function (records) {
                            vm.currentInscriptionCount = 0;
                            vm.totalInscriptionCount = records.length;
                            angular.forEach(records, function (record) {
                                record.name = record.firstName + ' ' + record.lastName;
                                if (record.confirmed) {
                                    vm.currentInscriptionCount++;
                                }
                            });
                        }
                    });
                } else {
                    AlertService.error(response.statusText);
                    $log.error(response);
                }
            });

        };

        var nameRenderer = function (row, column, value, html, cell, object) {
            if (!_.isNil(object.personId)) {
                var link = "<a href=\"#/sheet/person/" + object.personId + "?tab=main\">";
                link += object.name;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };

        var societyRenderer = function (row, column, value, html, cell, object) {
            if (!_.isNil(object.societyId)) {
                var link = "<a href=\"#/sheet/society/" + object.societyId + "?tab=main\">";
                link += object.societyName;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };

        var emailRenderer = function (row, column, value, html, cell, object) {
            if (!_.isNil(object.email)) {
                var link = "<a href=\"mailto:" + object.email + "\">";
                link += object.email;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };

        var facturationRenderer = function (row, column, value, html, cell, object) {
            if (!_.isEmpty(value)) {
                var newValue = object.facturationSocietyName;
                if (!_.isEmpty(object.facturationSocietyAddress)) {
                    newValue += ' ' + object.facturationSocietyAddress;
                }
                if (!_.isEmpty(object.facturationSocietyCp)) {
                    newValue += ' ' + object.facturationSocietyCp;
                }
                if (!_.isEmpty(object.facturationSocietyCity)) {
                    newValue += ' ' + object.facturationSocietyCity;
                }
                if (!_.isEmpty(object.facturationSocietyCountry)) {
                    newValue += ' ' + object.facturationSocietyCountry;
                }

                var result = html.replace(value, newValue);
                return result;
            }
            return html;
        };

        var priceRenderer = function (row, column, value, html, cell, object) {
            if (!CustomUtils.isUndefinedOrNull(value)) {
                var newValue = $filter('currency')(value, '€', 0);
                var result = html.replace(value, newValue);
                return result;
            }
            return html;
        };

        var billRefRenderer = function (row, column, value, html, cell, object) {
            var newValue = '';
            if (!_.isNil(object.attachementId)) {
                newValue += '<i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;';
            }
            if (!_.isNil(value)) {
                newValue += value;
            }
            if (!_.isEmpty(newValue)) {
                var result = html.replace(value, newValue);
                return result;
            }
            return html;
        };

        var dateRenderer = function (row, column, value, html, cell, object) {
            if (!_.isNil(value)) {
                var privateSpaceId = object.key;
                var link = '';
                if (!CustomUtils.isUndefinedOrNull(object.deletedAt)) {
                    var tooltip = "Supprimée le " + $filter("date")(object.deletedAt, 'dd/MM/yyyy');
                    link += '<span class="text-danger">';
                    link += '<i class="fa fa-info-circle" aria-hidden="true" data-toggle="tooltip" title="' + tooltip + '"></i>&nbsp;';
                    link += '</span>';
                }
                link += '<a href="#/manifestation/' + vm.mainEvent.id + '/space?key=' + privateSpaceId + '">';
                link += $filter('date')(object.registrationDate, 'dd/MM/yyyy HH:mm');
                link += '</a>';
                var result = html.replace(">" + value + "<", ">" + link + "<");
                return result;
            }
            return html;
        };

        vm.trySendPreInscriptionMail = function () {
            var index = vm.participationsGrid.getselectedrowindex();
            var eventRegistration = vm.participationsGrid.getrowdata(index);

            var email = eventRegistration.email;
            var mailKeyMessage = "manifestation.mail.send.confirmPreinscription";
            var sendEmailFunction = vm.sendPreInscriptionMail;
            if (eventRegistration.confirmed) {
                mailKeyMessage = "manifestation.mail.send.confirmInscription";
                sendEmailFunction = vm.sendConfirmInscriptionMail;
            }

            var i18nMessage = $translate.instant(mailKeyMessage, {
                email: email
            });
            ConfirmDialog.open("Confirmer l'envoi de mail", i18nMessage, sendEmailFunction);
        };

        vm.sendPreInscriptionMail = function () {
            var index = vm.participationsGrid.getselectedrowindex();
            var eventRegistration = vm.participationsGrid.getrowdata(index);
            $log.info("Sending pre inscription mail to ", eventRegistration.email);
            $http({
                method: "GET",
                url: "api/event-registrations/" + eventRegistration.id + "/send-pre-registration-mail"
            }).then(function () {
                AlertService.info("Le mail de pré inscription à été envoyé à " + eventRegistration.email);
            });
        };
        vm.sendConfirmInscriptionMail = function () {
            var index = vm.participationsGrid.getselectedrowindex();
            var eventRegistration = vm.participationsGrid.getrowdata(index);
            $log.info("Sending confirm inscription mail to ", eventRegistration.email);
            $http({
                method: "GET",
                url: "api/event-registrations/" + eventRegistration.id + "/send-confirm-registration-mail"
            }).then(function () {
                AlertService.info("Le mail d'inscription à été envoyé à " + eventRegistration.email);
            });
        };

        vm.selectedIndexes = [];
        vm.participationsSettings = {
            width: "100%",
            source: [],
            localization: JQWidgetsService.getLocalization($translate.use()),
            height: 540,
            selectionmode: 'checkbox',
            columnsresize: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            enabletooltips: true,
            altrows: true,
            editable: true,
            //rowsheight: 40,
            columns: [{
                    text: "Id",
                    dataField: "id",
                    width: 40,
                    editable: false,
                    cellsalign: 'center',
                    align: 'center',
                    pinned: true
                }, {
                    text: "Date",
                    dataField: 'registrationDate',
                    width: 135,
                    editable: false,
                    filtertype: 'range',
                    cellsrenderer: dateRenderer,
                    pinned: true
                }, {
                    editable: false,
                    text: 'Personne',
                    datafield: 'name',
                    width: 160,
                    cellsrenderer: nameRenderer,
                    pinned: true
                }, {
                    editable: false,
                    text: 'Structure',
                    datafield: 'societyName',
                    cellsrenderer: societyRenderer,
                    width: 250
                }, {
                    editable: false,
                    text: 'Ville',
                    datafield: 'city',
                    width: 100
                }, {
                    editable: false,
                    text: 'Email',
                    cellsrenderer: emailRenderer,
                    datafield: 'email',
                    width: 200
                }, {
                    editable: false,
                    text: 'Téléphone',
                    datafield: 'phone',
                    width: 100
                }, {
                    editable: false,
                    text: 'Tarif',
                    datafield: 'totalPrice',
                    cellsrenderer: priceRenderer,
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'number',
                    width: 70
                }, {
                    editable: false,
                    text: 'Ref. Bon de commande',
                    datafield: 'orderRef',
                    width: 100,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    editable: false,
                    text: 'Ref. Facture',
                    datafield: 'billRef',
                    width: 100,
                    cellsrenderer: billRefRenderer,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    editable: false,
                    text: 'Facturation',
                    datafield: 'facturationSocietyName',
                    cellsrenderer: facturationRenderer,
                    filterable: false
                }, {
                    editable: true,
                    text: 'Confirmer',
                    datafield: 'confirmed',
                    columntype: 'checkbox',
                    cellsalign: 'center',
                    align: 'center',
                    //filterable: false,
                    filtertype: 'bool',
                    width: 80
                }
            ],
            created: function (args) {
                vm.participationsGrid = args.instance;
            },
            rowselect: function (event) {
                vm.selectedIndexes = vm.participationsGrid.getselectedrowindexes();
                vm.selectedBill = null;
                vm.sessions = null;

                if (vm.selectedIndexes.length === 1) {
                    vm.selectedBill = vm.getSelectedData().attachementId;
                    vm.showSessions();
                }
            },
            rowunselect: function (event) {
                vm.selectedIndexes = vm.participationsGrid.getselectedrowindexes();
                vm.selectedBill = null;
                vm.sessions = null;

                if (vm.selectedIndexes.length == 1) {
                    vm.selectedBill = vm.getSelectedData().attachementId;
                    vm.showSessions();
                }
            },
            cellvaluechanged: function (event) {
                if (event.args) {
                    var args = event.args;
                    $log.info(event.args);
                    var eventRegistration = vm.participationsGrid.source.records[args.rowindex];
                    if (eventRegistration.civilite === null) {
                        eventRegistration.civilite = 'mr';
                    }
                    var promise = $q.when(true);

                    promise = promise.then(function () {
                        return $http.put("api/event-registrations/confirmOrUpdatePrice", {
                            id: eventRegistration.id,
                            total: eventRegistration.totalPrice,
                            confirmed: eventRegistration.confirmed
                        });
                    });
                    if (eventRegistration.confirmed) {
                        //mail de confirmation

                        $log.info("Sending confirmation mail to ", eventRegistration.email);
                        promise = promise.then(function () {
                            return $http({
                                method: "GET",
                                url: "api/event-registrations/" + eventRegistration.id + "/send-confirm-registration-mail"
                            });
                        });
                        promise.then(function () {
                            AlertService.info("Un mail de confirmation a été envoyé à " + eventRegistration.email);
                        });
                    }
                }
            }
        };

        vm.getSelectedData = function () {
            var index = vm.participationsGrid.getselectedrowindex();
            var data = vm.participationsGrid.getrowdata(index);
            return data;
        };

        vm.getSelectedDataList = function () {
            var indexes = vm.participationsGrid.getselectedrowindexes();
            var result = [];
            _.each(indexes, function (index) {
                var data = vm.participationsGrid.getrowdata(index);
                result.push(data);
            });
            return result;
        };

        vm.showSessions = function () {
            var data = vm.getSelectedData();
            vm.sessions = null;
            $http.get('api/event-registrations/' + data.id + '/participations').then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    vm.sessions = response.data;
                } else {
                    AlertService.error(response.statusText);
                    $log.error(response);
                }
            });
        };

        vm.showBill = function () {
            var data = vm.getSelectedData();
            if (data) {
                FileAttachement.get({id: data.attachementId}, function (result) {
                    if (result) {
                        DataUtils.downloadFile(result.content, data.name + '.pdf');
                    } else {
                        $log.info("Pas de facture.");
                    }
                });
            }
        };

        vm.exportEventRegistrations = function (type) {
            var registrations = [];

            angular.forEach(vm.selectedIndexes, function (index) {
                var registration = vm.participationsGrid.getrowdata(index);
                registrations.push(registration);
            });

            $state.go('exportEventRegistrations', {
                registrations: registrations,
                type: type,
                mainEventId: vm.mainEvent.id
            }, {
                notify: false
            });
        };

        vm.tryRemoveParticipation = function () {
            var rowData = vm.getSelectedData();

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/remove-participation.dialog.html',
                controller: 'RemoveParticipationDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    participation: function () {
                        return rowData;
                    }
                }
            }).result.then(function () {
                vm.participationsGrid.updatebounddata();
            });
        };

        vm.groupBill = function () {
            var participations = vm.getSelectedDataList();
            $uibModal.open({
                templateUrl: 'app/manifestation/subscription/dialog/orderRef.dialog.html',
                controller: 'OrderRefDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    billRef: function () {
                        var billRef = participations[0].billRef;
                        if (_.isEmpty(billRef)) {
                            if (!_.isEmpty(vm.mainEvent.prefix)) {
                                billRef = vm.mainEvent.prefix + '_';
                            }
                            billRef += participations[0].id;
                        }
                        return billRef;
                    },
                    orderRef: function () {
                        return participations[0].orderRef;
                    },
                    participations: function () {
                        return participations;
                    }
                }
            }).result.then(function (result) {
                var ids = [];
                _.each(participations, function (participation) {
                    ids.push(participation.id);
                });
                console.log(result);

                var billRef = result.participation.billRef;
                if (!_.isEmpty(result.billRef)) {
                    billRef = result.billRef;
                }
                var orderRef = result.participation.orderRef;
                if (!_.isEmpty(result.orderRef)) {
                    orderRef = result.orderRef;
                } else {
                    orderRef = '';
                }

                var societyName = result.participation.facturationSocietyName;
                if (!_.isEmpty(result.societyName)) {
                    societyName = result.societyName;
                }

                var address = result.participation.facturationSocietyAddress;
                if (!_.isEmpty(result.societyAddress)) {
                    address = result.societyAddress;
                }

                var cp = result.participation.facturationSocietyCp;
                if (!_.isEmpty(result.societyCp)) {
                    cp = result.societyCp;
                }

                var city = result.participation.facturationSocietyCity;
                if (!_.isEmpty(result.societyCity)) {
                    city = result.societyCity;
                }



                var errorMsg = "Problème lors de la génération de la facture groupée de " + result.participation.name;
                $http({
                    method: 'POST',
                    url: 'api/event-registrations/groupedbill/' + result.participation.id,
                    data: {
                        ids: ids,
                        orderRef: orderRef,
                        billRef: billRef,
                        cp: cp,
                        city: city,
                        address: address,
                        societyName: societyName
                    }
                }).then(function (response) {
                    if (response.status === 200) {
                        AlertService.success("La facture groupée a été générée dans l'espace de " + result.participation.name);
                        var data = response.data;
                        DataUtils.downloadFile(data.content, result.participation.name + '.pdf');
                        vm.refreshData();
                    } else {
                        AlertService.error(errorMsg);
                        $log.error(errorMsg, response);
                    }
                });

            }, function () {
                $log.error(arguments);
            });

        };
    }
})();
