(function () {
    'use strict';
    angular
            .module('canceropoleGsoApp')
            .factory('EventRegistration', EventRegistration);

    EventRegistration.$inject = ['$resource', 'DateUtils'];

    function EventRegistration($resource, DateUtils) {
        var resourceUrl = 'api/event-registrations/:id';
        var resourceUrl2 = 'api/public/event-registrations/:id/prices';
        var resourceUrl3 = 'api/public/event-registrations/:id/sessions';
        var resourceUrl5 = 'api/event-registrations/:id/participations';
        var resourceUrl6 = 'api/public/event-registrations/register';
        var resourceUrl7 = 'api/public/event-registration/key/:id';

        var resource = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.registrationDate = DateUtils.convertDateTimeFromServer(data.registrationDate);
                        data.preInscriptionMailDate = DateUtils.convertDateTimeFromServer(data.preInscriptionMailDate);
                        data.confirmationMailDate = DateUtils.convertDateTimeFromServer(data.confirmationMailDate);
                        data.deletedAt = DateUtils.convertDateTimeFromServer(data.deletedAt);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });

        var resource2 = $resource(resourceUrl2, {}, {
            'getPrices': {
                method: 'GET',
                isArray: true
            }
        });
        var resource3 = $resource(resourceUrl3, {}, {
            'getSessions': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function (item) {
                            item.startDate = DateUtils.convertDateTimeFromServer(item.startDate);
                            item.endDate = DateUtils.convertDateTimeFromServer(item.endDate);
                            angular.forEach(item.eventSessions, function (eventSession) {
                                eventSession.startDate = DateUtils.convertDateTimeFromServer(eventSession.startDate);
                                eventSession.endDate = DateUtils.convertDateTimeFromServer(eventSession.endDate);
                            });
                        });
                    }
                    return data;
                }
            }
        });
        var resource5 = $resource(resourceUrl5, {}, {
            'getParticipations': {
                method: 'GET',
                isArray: true
            }
        });
        var resource6 = $resource(resourceUrl6, {}, {
            'register': {
                method: 'POST'
            }
        });
        var resource7 = $resource(resourceUrl7, {}, {
            'getByKey': {
                method: 'GET'
            }
        });


        resource.getPrices = resource2.getPrices.bind(resource2);
        resource.getSessions = resource3.getSessions.bind(resource3);
        resource.getParticipations = resource5.getParticipations.bind(resource5);
        resource.register = resource6.register.bind(resource6);
        resource.getByKey = resource7.getByKey.bind(resource7);

        return resource;
    }
})();
