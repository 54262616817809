(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetProjectController', SheetProjectController);

    SheetProjectController.$inject = ['Principal', 'SheetHistoryService', '$stateParams', 'project', 'Project', '$state', '$translate'];

    function SheetProjectController(Principal, SheetHistoryService, $stateParams, project, Project, $state, $translate) {
        var vm = this;
        vm.project = project;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.saveTitle = function () {
            Project.update(vm.project);
        };

        var sheetHistory = {
            name: vm.project.name,
            type: 'project',
            id: vm.project.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.tabs = [{
                title: $translate.instant("sheet.project.main.tab"),
                route: "sheet.project.main"
            }, {
                title: $translate.instant("sheet.project.submission.tab"),
                route: "sheet.project.submission"
            }, {
                title: $translate.instant("sheet.project.followed.tab"),
                route: "sheet.project.followed"
            }, {
                title: $translate.instant("sheet.project.intervention.tab"),
                route: "sheet.project.intervention"
            }, {
                title: $translate.instant("sheet.project.publication.tab"),
                route: "sheet.project.publication"
            }, {
                title: $translate.instant("sheet.project.valorisation.tab"),
                route: "sheet.project.valorisation"
            }
        ];

        var activeTab = $stateParams.tab;

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.route.endsWith(activeTab)) {
                vm.activeTabIndex = index;
            }
        });

        $state.go(vm.tabs[vm.activeTabIndex].route);
    }
})();
