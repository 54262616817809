(function () {
    'use strict';

    angular
            .module('canceropoleGsoApp')
            .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sheet.eventabstract', {
            parent: 'sheet',
            url: 'eventabstract/:id',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'sheet.eventabstract.title'
            },
            params: {
                id: null
            },
            views: {
                'content@': {
                    templateUrl: 'app/sheet/eventabstract/sheet.eventabstract.html',
                    controller: 'SheetEventAbstractController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sheet');
                        return $translate.refresh();
                    }],
                eventAbstractInformation: ['$stateParams', '$http', function ($stateParams, $http) {
                        return $http.get("api/event-abstracts/" + $stateParams.id + "/all").then(function (response) {
                            return response.data;
                        });
                        //return EventAbstract.get({id: $stateParams.id}).$promise;
                    }]
            }
        });
    }
})();