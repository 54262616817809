(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetPersonFollowedTabController', SheetPersonFollowedTabController);

    SheetPersonFollowedTabController.$inject = ['Principal', '$stateParams', '$state', 'Job', 'CustomUtils', 'AlertService', 'ConfirmDialog', 'personEntity', '$uibModal', 'JQWidgetsService', '$translate', '$filter'];

    function SheetPersonFollowedTabController(Principal, $stateParams, $state, Job, CustomUtils, AlertService, ConfirmDialog, personEntity, $uibModal, JQWidgetsService, $translate, $filter) {
        if ($stateParams.tab !== "followed") {
            //update state param
            $stateParams.tab = "followed";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: personEntity,
            currentSocieties: [],
            jobs: {},
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                otherinformation: false
            }
        };

        vm.editJob = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/person/dialog/job.edit.dialog.html',
                controller: 'JobEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('job');
                            return $translate.refresh();
                        }],
                    job: function () {
                        if (!CustomUtils.isUndefinedOrNull(data)) {
                            return {
                                id: data.id,
                                label: data.label,
                                startDate: data.startDate,
                                endDate: data.endDate,
                                personId: data.personId,
                                societyId: data.societyId,
                                personMetierId: data.metierId,
                                personStatusId: data.statusId,
                                societyName: data.society,
                                position: data.position
                            };
                        }
                    },
                    searchPerson: false,
                    searchSociety: true
                }
            }).result.then(function (result) {
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    result.personId = vm.Model.entity.id;

                    Job.save(result, function () {
                        AlertService.success("Information sauvegardée");
                        vm.load();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                } else {
                    Job.update(result, function () {
                        AlertService.success("Information mise à jour.");
                        vm.load();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                }
            });
        };

        vm.tryRemoveJob = function () {
            if (!CustomUtils.isUndefinedOrNull(vm.selectedJob)) {
                var message = "";
                if (vm.selectedJob.startDate) {
                    message += $filter("date")(vm.selectedJob.startDate, "dd/MM/yyyy");
                }
                if (vm.selectedJob.endDate) {
                    if (message.length > 0) {
                        message += " - ";
                    }
                    message += $filter("date")(vm.selectedJob.endDate, 'dd/MM/yyyy');
                }
                if (message.length > 0) {
                    message += " - ";
                }
                message += vm.selectedJob.society;

                if (vm.selectedJob.metier) {
                    message += " - " + vm.selectedJob.metier;
                }
                if (vm.selectedJob.status) {
                    message += " - " + vm.selectedJob.status;
                }

                ConfirmDialog.openConfirmRemove(message, function () {
                    vm.removeJob(vm.selectedJob.id);
                });
            }
        };

        vm.removeJob = function (id) {
            Job.delete({id: id},
                    function () {
                        vm.jobsGrid.updatebounddata();
                        AlertService.success("Suppression effectuée.");
                    }, function () {
                AlertService.error("Problème lors de la suppression.");
            });
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };
        vm.cancel = vm.load;


        vm.edit = function (win) {
            vm.Model.edition[win] = true;
        };

        vm.loadTimeLine = function (records) {
            vm.Model.jobs = records;
            vm.jobTimeline = [];

            vm.Model.currentSocieties = [];
            angular.forEach(records, function (job) {
                var societyName = job.society;
                var societyId = job.societyId;

                var title = "";
                if (!CustomUtils.isEmpty(job.label)) {
                    title += "<b>" + job.label + "</b>";
                    title += "<br/>";
                }

                title += '<a href="#/sheet/society/' + societyId + '?tab=main">' + societyName + '</a>';

                var subTitle = "";
                if (!CustomUtils.isEmpty(job.address)) {
                    subTitle += job.address;
                    subTitle += "<br/>";
                }
                if (!CustomUtils.isEmpty(job.cp)) {
                    subTitle += job.cp;
                }
                if (!CustomUtils.isEmpty(job.city)) {
                    if (!CustomUtils.isEmpty(job.cp)) {
                        subTitle += " ";
                    }
                    subTitle += job.city;
                    subTitle += "<br/>";
                }

                if (!CustomUtils.isEmpty(job.metier)) {
                    subTitle += job.metier;
                    if (!CustomUtils.isEmpty(job.status)) {
                        subTitle += " - ";
                        subTitle += job.status;
                    }
                }

                var type = 'default';
                var icon = 'glyphicon glyphicon-info-sign';
                if (job.endDate === null) {
                    type = 'success';
                    icon = 'glyphicon glyphicon-play-circle';
                    vm.Model.currentSocieties.push({
                        id: societyId,
                        name: societyName
                    });
                }

                vm.jobTimeline.push({
                    title: title,
                    subTitle: subTitle,
                    startDate: job.startDate,
                    endDate: job.endDate,
                    icon: icon,
                    type: type,
                    position: job.position
                });

            });
            vm.jobTimeline.sort(function (event1, event2) {
                var posA = event1.position;
                var posB = event2.position;
                if (posA !== posB) {
                    return posA > posB ? -1 : 1;
                }

                var startA = event1.date;
                var endA = event1.endDate;
                var startB = event2.date;
                var endB = event2.endDate;

                var dateToCompareA = endA;
                var dateToCompareB = endB;
                if (CustomUtils.isUndefinedOrNull(endA)) {
                    if (CustomUtils.isUndefinedOrNull(endB)) {
                        dateToCompareA = startA;
                        dateToCompareB = startB;
                    } else {
                        return -1;
                    }
                } else if (CustomUtils.isUndefinedOrNull(endB)) {
                    return 1;
                }

                if (CustomUtils.isUndefinedOrNull(dateToCompareA)) {
                    return -1;
                } else if (CustomUtils.isUndefinedOrNull(dateToCompareB)) {
                    return 1;
                }

                return dateToCompareA > dateToCompareB ? -1 : dateToCompareA < dateToCompareB ? 1 : 0;
            });

        };

        var jobsSource = {
            datatype: "json",
            url: "api/jobs/person/" + vm.Model.id,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'firstName',
                    type: 'string'
                }, {
                    name: 'lastName',
                    type: 'string'
                }, {
                    name: 'label',
                    type: 'string'
                }, {
                    name: 'society',
                    type: 'string'
                }, {
                    name: 'societyId',
                    type: 'number'
                }, {
                    name: 'personId',
                    type: 'number'
                }, {
                    name: 'metierId',
                    type: 'number'
                }, {
                    name: 'metier',
                    type: 'String'
                }, {
                    name: 'statusId',
                    type: 'string'
                }, {
                    name: 'status',
                    type: 'string'
                }, {
                    name: 'endDate',
                    type: 'date'
                }, {
                    name: 'startDate',
                    type: 'date'
                }, {
                    name: 'address',
                    type: 'string'
                }, {
                    name: 'cp',
                    type: 'string'
                }, {
                    name: 'city',
                    type: 'string'
                }, {
                    name: 'position',
                    type: 'number'
                }
            ],
            sortcolumn: 'position',
            sortdirection: 'desc',
            id: 'id'
        };

        var jobsDataAdapter = new $.jqx.dataAdapter(jobsSource, {
            beforeLoadComplete: function (results, records) {
                vm.loadTimeLine(records);
            }
        });

        vm.selectedJob = null;
        vm.jobsGridSettings = {
            width: "100%",
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: jobsDataAdapter,
            enabletooltips: true,
            height: 350,
            columnsresize: true,
            altrows: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            created: function (args) {
                vm.jobsGrid = args.instance;
            },
            columns: [{
                    text: 'Position',
                    datafield: 'position',
                    cellsalign: 'center',
                    align: 'center',
                    width: 80,
                    pinned: true
                }, {
                    text: 'Structure',
                    datafield: 'society',
                    pinned: true,
                    minwidth: 200,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.societyId)) {
                            var link = '<a href="#/sheet/society/' + object.societyId + '?tab=main">';
                            link += object.society;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Libellé',
                    datafield: 'label',
                    minwidth: 80
                }, {
                    text: 'Début',
                    datafield: 'startDate',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    filtertype: 'date',
                    width: 80
                }, {
                    text: 'Fin',
                    datafield: 'endDate',
                    filtertype: 'date',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    width: 80
                }, {
                    text: 'Métier',
                    datafield: 'metier',
                    filtertype: 'checkedlist',
                    width: 100,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Statut',
                    datafield: 'status',
                    filtertype: 'checkedlist',
                    width: 100,
                    cellsalign: 'center',
                    align: 'center'
                }],
            rowselect: function (event) {
                if (event.args) {
                    var args = event.args;
                    vm.selectedJob = args.row;
                }
            },
            rowunselect: function () {
            }
        };

        //actions
        jobsDataAdapter.dataBind();

    }
})();
