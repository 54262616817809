(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventParticipationsTabController', SheetMainEventParticipationsTabController);

    SheetMainEventParticipationsTabController.$inject = ['$log', '$stateParams', '$http', '$state', 'entity', 'AlertService', 'Principal', '$q', 'ConfirmDialog', '$uibModal'];

    function SheetMainEventParticipationsTabController($log, $stateParams, $http, $state, entity, AlertService, Principal, $q, ConfirmDialog, $uibModal) {
        if ($stateParams.tab !== "participations") {
            //update state param
            $stateParams.tab = "participations";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.Model = {
            id: $stateParams.id,
            entity: entity,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                registrationMail: false,
                confirmRegistrationMail: false
            },
            html: {}
        };


        vm.participationSelected = [];

        vm.rowselect = function () {
            vm.participationSelected = vm.participationGrid.getselectedrowindexes();
        };

        vm.rowunselect = function () {
            vm.participationSelected = vm.participationGrid.getselectedrowindexes();
        };

        vm.getParticipationSelection = function () {
            var index = vm.participationGrid.getselectedrowindex();
            if (index !== -1) {
                return vm.participationGrid.getrowdata(index);
            } else {
                return null;
            }
        };

        vm.selectedParticipationIsLinked = function () {
            if (vm.participationSelected.length === 1) {
                var participation = vm.getParticipationSelection();
                if (!_.isNil(participation) && !_.isNil(participation.personId)) {
                    return true;
                }
            }

            return false;
        };


        vm.tryUnLinkPerson = function () {
            var rowData = vm.getParticipationSelection();
            var title = 'Confirmation de dissociation';
            var msg = 'Confirmez-vous la dissociation de ' + rowData.name + ' avec la fiche en base ?';
            ConfirmDialog.openYesNo(title, msg, function (result) {
                if (result === true) {
                    vm.unlinkPerson();
                }
            });
        };

        vm.unlinkPerson = function () {
            var rowData = vm.getParticipationSelection();
            $http({
                method: 'GET',
                url: 'api/event-registration/' + rowData.id + '/unLinkPerson/'
            }).success(function () {
                AlertService.success("Dissociation effectuée.");
                vm.participationGrid.updatebounddata();
                vm.participationGrid.clearselection();
            }).error(function () {
                AlertService.error("Problème lors de la dissociation.");
                vm.participationGrid.updatebounddata();
                vm.participationGrid.clearselection();
            });
        };

        vm.tryRemoveParticipation = function () {
            var rowData = vm.getParticipationSelection();

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/remove-participation.dialog.html',
                controller: 'RemoveParticipationDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    participation: function () {
                        return rowData;
                    }
                }
            }).result.then(function () {
                vm.participationGrid.updatebounddata();
                vm.participationGrid.clearselection();
            });
        };

        vm.confirmPresence = function () {
            var rejectedRegistrations = [];
            var ids = [];
            angular.forEach(vm.participationSelected, function (index) {
                var registration = vm.participationGrid.getrowdata(index);
                if (registration.confirmed === true) {
                    ids.push(registration.id);
                } else {
                    rejectedRegistrations.push(registration);
                }
            });

            var warning = "";
            if (rejectedRegistrations.length > 0) {
                warning = "<br/>";
                warning += '<div class="alert alert-warning" role="alert">';
                warning += '<p>';
                warning += "<strong>Attention</strong>&nbsp;";
                warning += "les participations suivantes doivent être confirmées avant.";
                warning += "</p><ul>";
                angular.forEach(rejectedRegistrations, function (rejectedRegistration) {
                    warning += "<li>";
                    warning += rejectedRegistration.name;
                    warning += "</li>";
                });
                warning += "</ul></div>";
            }

            ConfirmDialog.openYesNo("Confirmer la présence des participants", "Etes vous sûr de vouloir confirmer la présence des participants sélectionnés ?" + warning, function (result) {
                if (result === true) {
                    $log.info("Confirm ");
                    $http.post("api/event-registrations/presence", ids).then(function () {
                        vm.participationGrid.updatebounddata();
                        vm.participationGrid.clearselection();
                    });
                }
            });
        };

        vm.linkToPerson = function () {
            var rowData = vm.getParticipationSelection();

            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/link-registration-person.dialog.html',
                controller: 'LinkRegistrationPersonDialogController',
                controllerAs: 'ctrl',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('entities');
                            return $translate.refresh();
                        }],
                    toSearch: function () {
                        return rowData.firstName + " " + rowData.lastName;
                    },
                    abstractSpeakers: ['$http', function ($http) {
                            return $http.get("api/public/event-registration/" + rowData.id + "/speakers");
                        }]
                }
            }).result.then(function (result) {
                $http({
                    method: 'GET',
                    url: 'api/event-registration/' + rowData.id + '/linkToPerson/' + result.person.id,
                    params: {
                        'abstractPersonIds': result.abstractPersonIds
                    }
                }).success(function () {
                    AlertService.success("Association effectuée.");
                    vm.participationGrid.updatebounddata();
                }).error(function () {
                    AlertService.error("Problème lors de l'association.");
                    vm.participationGrid.updatebounddata();
                });
            });
        };

        vm.exportEventRegistrations = function (type) {
            var registrations = [];

            angular.forEach(vm.participationSelected, function (index) {
                var registration = vm.participationGrid.getrowdata(index);
                registrations.push(registration);
            });
            $state.go('exportEventRegistrationsFromSheet', {
                registrations: registrations,
                type: type,
                mainEventId: vm.Model.id
            }, {
                notify: false
            });
        };


    }
})();