(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('EventPriceEditDialogController', EventPriceEditDialogController);

    EventPriceEditDialogController.$inject = ['$translate', '$translatePartialLoader', '$uibModalInstance', 'eventPrice', '$log', 'personStatus'];

    function EventPriceEditDialogController($translate, $translatePartialLoader, $uibModalInstance, eventPrice, $log, personStatus) {
        $translatePartialLoader.addPart('dialog');
        $translate.refresh();

        $log.info(eventPrice);

        var ctrl = this;
        ctrl.eventPrice = eventPrice;
        ctrl.personStatus = personStatus;
        ctrl.personStatus.sort(function (a, b) {
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });

        if (ctrl.eventPrice === null) {
            ctrl.title = "Ajouter un tarif";
            ctrl.eventPrice = {
                price: 0,
                position: 1
            };

            var start = new Date();
            start.setSeconds(0, 0);
            ctrl.date = start;
        } else {
            ctrl.title = "Editer un tarif";
            if (eventPrice.startDate) {
                ctrl.date = eventPrice.startDate;
            } else if (eventPrice.endDate) {
                ctrl.date = eventPrice.endDate;
            }
        }

        ctrl.personStatusInitialized = false;
        ctrl.personStatusSettings = {
            width: "100%",
            height: 300,
            multiple: true,
            displayMember: "name",
            valueMember: "id",
            source: ctrl.personStatus,
            created: function (event) {
                ctrl.listBox = event.instance;
                angular.forEach(ctrl.eventPrice.personStatuses, function (personStatus, index) {
                    for (var i = 0; i < ctrl.personStatus.length; i++) {
                        var aStatus = ctrl.personStatus[i];
                        if (aStatus.id === personStatus.id) {
                            ctrl.listBox.selectIndex(i);
                            break;
                        }
                    }
                });
                ctrl.personStatusInitialized = true;
            }
        };

        ctrl.personStatusChange = function () {
            if (ctrl.personStatusInitialized === true) {
                ctrl.editEventPriceForm.$dirty = true;
            }
        };

        ctrl.periodSettings = {
            width: "'100%'",
            height: 30,
            autoDropDownHeight: true,
            source: ["Avant le", "Après le"],
            created: function (event) {
                ctrl.periodWidget = event.instance;
                if (ctrl.eventPrice.endDate === null) {
                    ctrl.periodWidget.selectIndex(1);
                } else {
                    ctrl.periodWidget.selectIndex(0);
                }
            }
        };

        ctrl.dateSettings = {
            width: "100%",
            height: 30,
            formatString: "dd/MM/yyyy HH:mm",
            showTimeButton: true,
            culture: "fr-FR"
        };

        ctrl.ok = function () {
            var selectedStatus = ctrl.listBox.getSelectedItems();

            var status = [];
            for (var i = 0; i < selectedStatus.length; i++) {
                for (var j = 0; j < ctrl.personStatus.length; j++) {
                    var personStatus = ctrl.personStatus[j];
                    if (personStatus.id === selectedStatus[i].value) {
                        status.push(personStatus);
                        break;
                    }
                }
            }
            ctrl.eventPrice.personStatuses = status;

            var item = ctrl.periodWidget.getSelectedIndex();
            if (item === 0) {
                ctrl.eventPrice.startDate = null;
                ctrl.eventPrice.endDate = ctrl.date;
            } else if (item === 1) {
                ctrl.eventPrice.startDate = ctrl.date;
                ctrl.eventPrice.endDate = null;
            }

            $log.info(ctrl.eventPrice);
            $uibModalInstance.close(ctrl.eventPrice);
        };
        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();