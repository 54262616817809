(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetSocietyMainTabController', SheetSocietyMainTabController);

    SheetSocietyMainTabController.$inject = ['SocietySearch', '$stateParams', '$state', 'Principal', 'societyEntity', 'geosites', 'societyTypes', 'Society', 'AlertService', '$http', 'CustomUtils', 'parents', '$q'];

    function SheetSocietyMainTabController(SocietySearch, $stateParams, $state, Principal, society, geosites, societyTypes, Society, AlertService, $http, CustomUtils, parents, $q) {
        if ($stateParams.tab !== "main") {
            //update state param
            $stateParams.tab = "main";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.parents = parents;
        vm.society = society;
        vm.societyTypes = societyTypes;
        vm.geosites = geosites;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.edition = {
            contact: false,
            otherinformation: false
        };

        vm.edit = function (win) {
            vm.edition[win] = true;
        };

        vm.load = function () {
            $state.reload();
        };
        vm.cancel = vm.load;

        vm.save = function (win) {
            if (win === 'otherinformation') {
                if (vm.geosite.id) {
                    vm.society.geositeId = vm.geosite.id;
                }
                if (vm.societyType.id) {
                    vm.society.societyTypeId = vm.societyType.id;
                }
            } else if (win === 'contact') {
                if (!CustomUtils.isEmpty(vm.society.webSite) && !vm.society.webSite.startsWith("http")) {
                    vm.society.webSite = "http://" + vm.society.webSite;
                }
            }


            $q.when(true).then(function () {
                return Society.update(vm.society).$promise;
            }).then(function () {
                var parentIds = [];
                _.forEach(vm.parents, function (parent) {
                    parentIds.push(parent.id);
                });

                return $http({
                    url: 'api/society/parents/' + vm.society.id,
                    method: 'PUT',
                    data: parentIds
                });
            }).then(function () {
                vm.load();
            }, function () {
                AlertService.error("Problème lors de la sauvegarde");
            });

        };

        CustomUtils.getCallingCodes().then(function (result) {
            vm.callingCodeSettings.source = result;
        });
        vm.callingCodeSettings = {
            created: function (args) {
                vm.callingCodeComboBox = args.instance;
            },
            renderer: function (element, label, value) {
                return "+" + label;
            },
            selectionRenderer: function (htmlElement, label, value) {
                var htmlString = htmlElement[0].innerHTML;
                return '<div style="position: relative; margin-left: 3px; margin-top: 8px;">+' + htmlString + '</div>';
            }
        };

        vm.geositeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            source: {
                localdata: vm.geosites,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                vm.geositeDropDown = event.instance;
            }
        };

        vm.societyTypeDropDownListSettings = {
            displayMember: 'name',
            valueMember: 'id',
            source: {
                localdata: vm.societyTypes,
                datatype: 'json',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: 'name',
                        type: 'string'
                    }]
            },
            created: function (event) {
                vm.societyTypeDropDown = event.instance;
            }
        };

        vm.fullAddress = vm.society.address;
        if (vm.society.address2) {
            vm.fullAddress += ' - ' + vm.society.address2;
        }
        if (vm.society.cp) {
            vm.fullAddress += ' - ' + vm.society.cp;
        }
        if (vm.society.city) {
            vm.fullAddress += ' - ' + vm.society.city;
        }

        if (vm.society.geositeId) {
            for (var i = 0; i < vm.geosites.length; i++) {
                var aGeosite = vm.geosites[i];
                if (aGeosite.id === vm.society.geositeId) {
                    vm.geosite = aGeosite;
                    break;
                }
            }
        } else {
            vm.geosite = {};
        }

        if (vm.society.societyTypeId) {
            for (var i = 0; i < vm.societyTypes.length; i++) {
                var aSocietyType = vm.societyTypes[i];
                if (aSocietyType.id === vm.society.societyTypeId) {
                    vm.societyType = aSocietyType;
                    break;
                }
            }
        } else {
            vm.societyType = {};
        }

        vm.societyDataAdapter = new $.jqx.dataAdapter({
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        }, {
            loadServerData: function (serverdata, source, callback) {
                var query = 'name:' + source.searchString;
                query += ' or ';
                query += 'shortName:' + source.searchString;
                query += ' and level="ENTITY"';
                SocietySearch.query({
                    query: query,
                    page: 0,
                    size: 20
                }, function (result) {
                    var final = [];
                    _.forEach(result, function (aSociety) {
                        if (aSociety.level === 'ENTITY') {
                            final.push(aSociety);
                        }
                    });
                    callback({
                        records: final
                    });
                });
            }
        });
    }
})();