(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetTeamMembersController', SheetTeamMembersController);

    SheetTeamMembersController.$inject = ['Principal', '$translate', '$stateParams', 'CustomUtils', '$state', 'ConfirmDialog', 'AlertService', 'team', 'DateUtils', 'JQWidgetsService', '$uibModal', 'TeamMember'];

    function SheetTeamMembersController(Principal, $translate, $stateParams, CustomUtils, $state, ConfirmDialog, AlertService, team, DateUtils, JQWidgetsService, $uibModal, TeamMember) {
        if ($stateParams.tab !== "members") {
            //update state param
            $stateParams.tab = "members";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.team = team;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.load = function () {
            $state.reload();
        };
        vm.cancel = vm.load;

        var customSort = function (column, direction) {
            var records = vm.jobsGridSettings.source.records;

            if (direction === 'ascending' || direction === 'asc') {
                direction = true;
            }
            if (direction === 'descending' || direction === 'desc') {
                direction = false;
            }

            if (!_.isNil(direction)) {
                records.sort(function (value1, value2) {
                    if (column === 'startDate' || column === 'endDate') {
                        value1 = value1[column];
                        value2 = value2[column];
                        if (direction === true) {
                            return DateUtils.compareWithUndefinedDate(value1, value2);
                        } else {
                            return DateUtils.compareWithUndefinedDate(value2, value1);
                        }
                    } else if (column === 'name') {
                        value1 = String(value1["personLastName"]).toLowerCase();
                        value2 = String(value2["personLastName"]).toLowerCase();
                    } else {
                        value1 = String(value1[column]).toLowerCase();
                        value2 = String(value2[column]).toLowerCase();
                    }
                    if (direction === true) {
                        return value1.localeCompare(value2);
                    } else {
                        return value2.localeCompare(value1);
                    }
                });
                vm.jobsGridSettings.source.records = records;
            }
            if (vm.jobsGrid) {
                vm.jobsGrid.refreshdata();
            }
        };

        var membersSource = {
            datatype: "json",
            url: "api/team/members/" + vm.team.id,
            data: {
                size: 1000
            },
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'label',
                    type: 'string'
                }, {
                    name: 'startDate',
                    type: 'date'
                }, {
                    name: 'endDate',
                    type: 'date'
                }, {
                    name: 'position',
                    type: 'number'
                }, {
                    name: 'teamId',
                    type: 'number'
                }, {
                    name: 'teamName',
                    type: 'string'
                }, {
                    name: 'personId',
                    type: 'number'
                }, {
                    name: 'personFirstName',
                    type: 'string'
                }, {
                    name: 'personLastName',
                    type: 'string'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'personPhone',
                    type: 'string'
                }, {
                    name: 'personPortable',
                    type: 'string'
                }, {
                    name: 'personEmail',
                    type: 'string'
                }, {
                    name: 'metierId',
                    type: 'number'
                }, {
                    name: 'metierName',
                    type: 'string'
                }, {
                    name: 'statusId',
                    type: 'number'
                }, {
                    name: 'statusName',
                    type: 'string'
                }
            ],
            sort: customSort,
            id: 'id'
        };

        var membersDataAdapter = new $.jqx.dataAdapter(membersSource, {
            autoBind: false,
            beforeLoadComplete: function (records) {
                vm.employeesNb = records.length;
                for (var i = 0; i < records.length; i++) {
                    var record = records[i];
                    var firstName = record.personFirstName;
                    var lastName = record.personLastName;
                    var name = CustomUtils.concatWith(firstName, lastName, " ");
                    record.name = name;
                }
                return records;
            }
        });

        var personNameRenderer = function (row, column, value, html, cell, object) {
            var link = "<a href=\"#/sheet/person/" + object.personId + "?tab=main\">";
            link += value;
            link += "</a>";

            var result = html.replace(value, link);
            return result;
        };

        vm.applyCurrentMembersFilter = function () {
            vm.currentMembersFilterGroup = new $.jqx.filter();
            var filter_or_operator = 1;

            var filtercondition = 'NULL';
            var filter = vm.currentMembersFilterGroup.createfilter('datefilter', null, filtercondition);
            vm.currentMembersFilterGroup.addfilter(filter_or_operator, filter);
            vm.membersGrid.addfilter('endDate', vm.currentMembersFilterGroup);
            vm.membersGrid.applyfilters();
        };

        vm.removeCurrentMembersFilter = function () {
            vm.membersGrid.removefilter('endDate', vm.currentTeamFilterGroup);
            vm.membersGrid.applyfilters();
        };

        vm.toggleEndDateFilter = function () {
            var filters = vm.membersGrid.getfilterinformation();
            var found = false;
            _.forEach(filters, function (filter) {
                if (filter.filter === vm.currentMembersFilterGroup) {
                    found = true;
                }
            });
            if (found) {
                vm.endDateFilterState = '';
                vm.removeCurrentMembersFilter();
            } else {
                vm.endDateFilterState = 'active';
                vm.applyCurrentMembersFilter();
            }
        };

        vm.memberSelected = null;
        vm.membersGridSettings = {
            width: "100%",
            source: membersDataAdapter,
            localization: JQWidgetsService.getLocalization($translate.use()),
            height: 550,
            //columnsresize: true,
            sortable: true,
            filterable: true,
            showfilterrow: true,
            showstatusbar: true,
            statusbarheight: 30,
            showaggregates: true,
            altrows: true,
            created: function (args) {
                vm.membersGrid = args.instance;
                vm.toggleEndDateFilter();
            },
            rowselect: function (event) {
                vm.memberSelected = null;
                if (event.args) {
                    vm.memberSelected = event.args.row;
                }
            },
            columns: [{
                    text: 'Nom',
                    datafield: 'name',
                    width: 210,
                    pinned: true,
                    cellsrenderer: personNameRenderer,
                    aggregates: [{
                            '<b>Total</b>': function (total) {
                                return ++total;
                            }
                        }]
                }, {
                    text: 'Libellé',
                    datafield: 'label',
                    minwidth: 180
                }, {
                    text: 'Téléphone',
                    datafield: 'personPhone',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Portable',
                    datafield: 'personPortable',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Email',
                    datafield: 'personEmail',
                    cellsrenderer: function (row, column, value, html) {
                        if (!CustomUtils.isEmpty(value)) {
                            var link = "<a href=\"mailto:" + value + "\">";
                            link += value;
                            link += "</a>";

                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    },
                    width: 220
                }, {
                    text: 'Métier',
                    datafield: 'metierName',
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    width: 200
                }, {
                    text: 'Statut',
                    datafield: 'statusName',
                    filtertype: 'checkedlist',
                    cellsalign: 'center',
                    align: 'center',
                    width: 200
                }, {
                    text: 'Début',
                    datafield: 'startDate',
                    columntype: 'date',
                    filtertype: 'date',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    filterable: true,
                    width: 150
                }, {
                    text: 'Fin',
                    datafield: 'endDate',
                    columntype: 'date',
                    filtertype: 'date',
                    cellsformat: 'dd/MM/yyyy',
                    cellsalign: 'center',
                    align: 'center',
                    filterable: true,
                    width: 150
                }
            ]
        };

        vm.addMember = function () {
            vm.editMember();
        };

        vm.tryEditMember = function () {
            if (!_.isNil(vm.memberSelected)) {
                vm.editMember(vm.memberSelected);
            }
        };

        vm.editMember = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/person/dialog/teamMember.edit.dialog.html',
                controller: 'TeamMemberEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('teamMember');
                            return $translate.refresh();
                        }],
                    teamMember: function () {
                        if (!_.isNil(data)) {
                            return {
                                id: data.id,
                                startDate: data.startDate,
                                endDate: data.endDate,
                                position: data.position,
                                personId: data.personId,
                                personMetierId: data.metierId,
                                personStatusId: data.statusId,
                                teamId: data.teamId,
                                teamName: data.teamName,
                                personName: data.name,
                                label: data.label
                            };
                        }
                    },
                    searchPerson: true,
                    searchTeam: false
                }
            }).result.then(function (result) {
                if (_.isNil(result.id)) {
                    result.teamId = vm.team.id;

                    TeamMember.save(result, function () {
                        AlertService.success("Information sauvegardée");
                        vm.jobsGrid.updatebounddata();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                } else {
                    TeamMember.update(result, function () {
                        AlertService.success("Information mise à jour.");
                        vm.membersGrid.updatebounddata();
                    }, function () {
                        AlertService.error("Impossible de sauvegarder en base.");
                    });
                }
            });
        };

        vm.tryRemoveMember = function () {
            var content = vm.memberSelected.name;
            ConfirmDialog.openConfirmRemoveLink(content, function () {
                TeamMember.delete({id: vm.memberSelected.id}, function () {
                    AlertService.success("canceropoleGsoApp.teamMember.deleted");
                    vm.membersGrid.updatebounddata();
                });
            });
        };


    }
})();
