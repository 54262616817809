(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventOptionsTabController', SheetMainEventOptionsTabController);

    SheetMainEventOptionsTabController.$inject = ['Principal', '$log', '$state', '$stateParams', 'JQWidgetsService', 'CustomUtils', '$translate', '$filter', 'EventOption', 'optionsParticipations', '$q', 'ModelConfig', '$http', '$uibModal', 'ConfirmDialog', 'entity', 'EventSession', 'EventDay'];

    function SheetMainEventOptionsTabController(Principal, $log, $state, $stateParams, JQWidgetsService, CustomUtils, $translate, $filter, EventOption, optionsParticipations, $q, ModelConfig, $http, $uibModal, ConfirmDialog, entity, EventSession, EventDay) {
        if ($stateParams.tab !== "options") {
            //update state param
            $stateParams.tab = "options";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.mainEvent = entity;
        vm.options = [];
        vm.registrations = [];
        angular.forEach(optionsParticipations, function (optionParticipation) {
            var option = null;
            for (var i = 0; i < vm.options.length; i++) {
                var anOption = vm.options[i];
                if (anOption.id === optionParticipation.id) {
                    option = anOption;
                    break;
                }
            }

            if (option === null) {
                option = {
                    id: optionParticipation.id,
                    name: optionParticipation.name,
                    price: optionParticipation.price,
                    position: optionParticipation.position
                };
                vm.options.push(option);
            }
            if (optionParticipation.registrationId) {
                var registration = {
                    id: optionParticipation.registrationId,
                    date: optionParticipation.registrationDate,
                    key: optionParticipation.registrationKey,
                    personId: optionParticipation.personId,
                    personName: optionParticipation.personName,
                    societyId: optionParticipation.societyId,
                    societyName: optionParticipation.societyName,
                    optionId: optionParticipation.id,
                    optionName: optionParticipation.name
                };
                vm.registrations.push(registration);
            }
        });


        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.optionsDataAdapter = new $.jqx.dataAdapter({
            datatype: "json",
            localdata: vm.options,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'price',
                    type: 'number'
                }, {
                    name: 'position',
                    type: 'number'
                }
            ],
            sortcolumn: 'position',
            sortdirection: 'asc'
        });


        vm.selectedOption = null;
        vm.optionsSettings = {
            width: "100%",
            source: vm.optionsDataAdapter,
            height: 500,
            filterable: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            //columnsresize: true,
            sortable: true,
            //altrows: true,
            editable: false,
            created: function (args) {
                vm.optionsGrid = args.instance;
            },
            rowselect: function (event) {
                if (event.args) {
                    vm.selectedOption = event.args.row;
                    if (CustomUtils.isDefined(vm.registrationsGrid) && CustomUtils.isDefined(vm.selectedOption)) {
                        vm.registrationsGrid.clearfilters();
                        var filtergroup = new $.jqx.filter();
                        var filter_or_operator = 1;
                        var filter = filtergroup.createfilter('stringfilter', vm.selectedOption.name, 'equal');
                        filtergroup.addfilter(filter_or_operator, filter);

                        vm.registrationsGrid.addfilter('optionName', filtergroup);
                        vm.registrationsGrid.applyfilters();
                    }
                }
            },
            columns: [{
                    text: 'Position',
                    datafield: 'position',
                    width: 50,
                    filterable: false,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Nom',
                    datafield: 'name',
                    minwidth: 200
                }, {
                    text: 'Tarif',
                    datafield: 'price',
                    cellsformat: 'c',
                    width: 80,
                    cellsalign: 'right',
                    align: 'right'
                }]
        };

        vm.registrationsDataAdapter = new $.jqx.dataAdapter({
            datatype: "json",
            localdata: vm.registrations,
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'date',
                    type: 'date'
                }, {
                    name: 'key',
                    type: 'number'
                }, {
                    name: 'personId',
                    type: 'number'
                }, {
                    name: 'personName',
                    type: 'string'
                }, {
                    name: 'societyId',
                    type: 'number'
                }, {
                    name: 'societyName',
                    type: 'string'
                }, {
                    name: 'optionId',
                    type: 'number'
                }, {
                    name: 'optionName',
                    type: 'string'
                }
            ],
            sortcolumn: 'date',
            sortdirection: 'desc',
            id: 'id'
        });

        var nameRenderer = function (row, column, value, html, cell, object) {
            if (CustomUtils.isDefined(object.personId)) {
                var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
                link += object.personName;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };

        var societyRenderer = function (row, column, value, html, cell, object) {
            if (CustomUtils.isDefined(object.societyId)) {
                var link = '<a href="#/sheet/society/' + object.societyId + '?tab=main">';
                link += object.societyName;
                link += "</a>";
                var result = html.replace(value, link);
                return result;
            }
            return html;
        };

        var privateSpaceRenderer = function (row, column, value, html, cell, object) {
            if (CustomUtils.isDefined(value)) {
                var link = '';
                link += '<a href="#/manifestation/' + vm.mainEvent.id + '/space?key=' + object.key + '">';
                link += $filter('date')(object.date, 'dd/MM/yyyy HH:mm');
                link += '</a>';
                var result = html.replace(">" + value + "<", ">" + link + "<");
                return result;
            }
            return html;
        };

        vm.participationSelected = [];
        vm.optionsRegistrationsSettings = {
            width: "100%",
            source: vm.registrationsDataAdapter,
            height: 500,
            filterable: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            //columnsresize: true,
            selectionmode: 'checkbox',
            showstatusbar: true,
            statusbarheight: 25,
            showaggregates: true,
            sortable: true,
            altrows: true,
            editable: false,
            created: function (args) {
                vm.registrationsGrid = args.instance;
            },
            rowselect: function (event) {
                vm.participationSelected = vm.registrationsGrid.getselectedrowindexes();
            },
            rowunselect: function (event) {
                vm.participationSelected = vm.registrationsGrid.getselectedrowindexes();
            },
            columns: [{
                    text: 'id',
                    datafield: 'id',
                    width: 40,
                    cellsalign: 'center',
                    align: 'center'
                }, {
                    text: 'Espace personnel',
                    datafield: 'date',
                    cellsrenderer: privateSpaceRenderer,
                    width: 140,
                    cellsalign: 'center',
                    align: 'center',
                    aggregates: [{
                            '<b>Total</b>': function (aggregatedValue, currentValue) {
                                return ++aggregatedValue;
                            }
                        }]
                }, {
                    text: 'Nom',
                    datafield: 'personName',
                    cellsrenderer: nameRenderer,
                    width: 200
                }, {
                    text: 'Structure',
                    datafield: 'societyName',
                    cellsrenderer: societyRenderer,
                    minwidth: 130
                }, {
                    text: 'Option',
                    datafield: 'optionName',
                    minwidth: 200
                }]
        };

        vm.addOption = function () {
            vm.editOption({
                mainEventId: vm.mainEvent.id
            });
        };

        vm.editOption = function (data) {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/eventoption.edit.dialog.html',
                controller: 'EventOptionEditDialogController',
                controllerAs: 'ctrl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            $translatePartialLoader.addPart('eventOption');
                            return $translate.refresh();
                        }],
                    option: function () {
                        return data;
                    },
                    mainEvent: function () {
                        return vm.mainEvent;
                    }
                }
            }).result.then(function (result) {
                result.mainEventId = vm.mainEvent.id;
                if (CustomUtils.isUndefinedOrNull(result.id)) {
                    //ajout
                    EventOption.save(result, vm.load);

                } else {
                    //modification
                    EventOption.update(result, vm.load);
                }
            });
        };

        vm.tryRemoveOption = function () {
            ConfirmDialog.openConfirmRemove(vm.selectedOption.name, vm.removeOption);
        };

        vm.removeOption = function () {
            EventOption.remove({id: vm.selectedOption.id}, vm.load);
        };

        vm.exportEventRegistrations = function (type) {
            var registrations = [];

            angular.forEach(vm.participationSelected, function (index) {
                var registration = vm.registrationsGrid.getrowdata(index);
                registrations.push(registration);
            });
            $state.go('exportEventRegistrationsFromSheet', {
                registrations: registrations,
                type: type,
                mainEventId: vm.mainEvent.id
            }, {
                notify: false
            });
        };
    }
})();