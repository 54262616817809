(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('RemovePersonDialogController', RemovePersonDialogController);

    RemovePersonDialogController.$inject = ['$uibModalInstance', 'entity', '$log', 'dryDeleteResult', 'AlertService', 'Person', 'CustomUtils'];

    function RemovePersonDialogController($uibModalInstance, entity, $log, dryDeleteResult, AlertService, Person, CustomUtils) {

        var ctrl = this;
        ctrl.entity = entity;
        ctrl.dryDeleteResult = [];

        $log.info(dryDeleteResult);

        ctrl.appendDryCount = function (key, message) {
            var count = dryDeleteResult[key];
            if (count > 0) {
                var msg = 'il y a ' + count + ' ' + message;
                ctrl.dryDeleteResult.push(msg);
            }
        };

        ctrl.appendDryCount('abstractPersonCount', 'abstracts liés à cette personne..');
        ctrl.appendDryCount('callForProjectManagementCount', 'aap liées à cette personne en tant qu\'organisateur.');
        ctrl.appendDryCount('eventManagementCount', 'évènements liés à cette personne en tant qu\'organisateur.');
        ctrl.appendDryCount('expertCount', 'aap liés à cette personne en tant qu\'expert.');
        ctrl.appendDryCount('groupPersonCount', 'groupes liés à cette personne.');
        ctrl.appendDryCount('jobCount', 'parcours liés à cette personne.');
        ctrl.appendDryCount('personAxeCount', 'axes liés à cette personne.');
        ctrl.appendDryCount('personSocietyInputCount', 'abstracts d\'une structure lié à cette personne.');
        ctrl.appendDryCount('projectParticipationCount', 'projets liés à cette personne.');
        ctrl.appendDryCount('publicationCount', 'publications liées à cette personne.');
        ctrl.appendDryCount('teamCount', 'équipes liées à cette personne.');
        ctrl.appendDryCount('registrationCount', 'évènements liés à cette personne.');
        ctrl.appendDryCount('userCount', 'utilisateur lié à cette personne.');


        ctrl.ok = function () {
            //suppression simple
            Person.delete({id: ctrl.entity.id}, function () {
                $uibModalInstance.close();
            }, function () {
                AlertService.error("Problème lors de la suppression de la personne.");
            });
        };

        ctrl.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }

})();