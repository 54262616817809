(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('GroupsController', GroupsController);

    GroupsController.$inject = ['CustomUtils', 'JQWidgetsService', '$log', 'Groupe', 'ConfirmDialog', '$translate', '$state', '$stateParams', '$uibModal'];

    function GroupsController(CustomUtils, JQWidgetsService, $log, Groupe, ConfirmDialog, $translate, $state, $stateParams, $uibModal) {
        var vm = this;
        vm.groupSelected = null;
        vm.personSelected = null;

        var linkCellRenderer = function (row, column, value, html, cell, resource) {
            if (value.length > 0) {
                var url = '';
                if (column === 'lastName' | column === 'firstName') {
                    url = "#/sheet/person/" + resource.id + "?tab=main";
                } else if (column === 'email') {
                    url = 'mailto:' + value;
                }
                var link = "<a href=\"" + url + "\" title=\"" + value + "\">" + value + "</a>";
                return html.replace(value, link);
            }
            return "";
        };

        var groupsSource = {
            datatype: 'json',
            url: 'api/groupes',
            datafields: [{
                    name: 'id',
                    type: 'number'
                }, {
                    name: 'firstName',
                    type: 'string'
                }, {
                    name: 'lastName',
                    type: 'string'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'description',
                    type: 'string'
                }, {
                    name: 'people',
                    type: 'array'
                }],
            id: 'id',
            sortcolumn: 'name',
            sortdirection: 'asc'
        };

        var groupsDataAdapter = new $.jqx.dataAdapter(groupsSource, {
            beforeLoadComplete: function (records) {
                angular.forEach(records, function (group) {
                    angular.forEach(group.people, function (person) {
                        person.name = person.firstName + " " + person.lastName;
                    });
                });
            }
        });

        vm.groupsSettings = {
            width: "100%",
            altrows: true,
            sortable: true,
            height: 500,
            //autoheight: true,
            //autorowheight: true,
            enabletooltips: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: groupsDataAdapter,
            columnsresize: true,
            columns: [{
                    text: 'Groupe',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    width: 250
                }, {
                    text: 'Description',
                    datafield: 'description',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 120
                }],
            created: function (args) {
                vm.groupsGrid = args.instance;
                if (CustomUtils.isDefined($stateParams.id)) {
                    $log.info($stateParams.id);
                    var boundIndex = vm.groupsGrid.getrowboundindexbyid($stateParams.id);
                    if (boundIndex !== -1) {
                        vm.groupsGrid.selectrow(boundIndex);
                    }
                }
            },
            rowselect: function (event) {
                var args = event.args;
                if (args && args.row) {
                    var row = args.row;
                    vm.groupSelected = row;
                    $stateParams.id = row.id;
                    $state.go($state.current.name, $stateParams, {
                        notify: false
                    });
                    vm.peopleSettings.source = new $.jqx.dataAdapter({
                        datatype: "json",
                        localdata: vm.groupSelected.people,
                        sort: customSort,
                        sortcolumn: 'name',
                        sortdirection: 'asc'
                    });
                }
            }
        };

        var customSort = function (column, direction) {
            var records = this.records;

            if (direction === 'ascending' || direction === 'asc') {
                direction = true;
            }
            if (direction === 'descending' || direction === 'desc') {
                direction = false;
            }

            if (direction !== null) {
                records.sort(function (value1, value2) {
                    if (column === 'name') {
                        value1 = String(value1["lastName"]).toLowerCase();
                        value2 = String(value2["lastName"]).toLowerCase();
                    } else {
                        value1 = String(value1[column]).toLowerCase();
                        value2 = String(value2[column]).toLowerCase();
                    }
                    if (direction === true) {
                        return value1.localeCompare(value2);
                    } else {
                        return value2.localeCompare(value1);
                    }
                });
                this.records = records;
            }
            if (vm.peopleGrid) {
                vm.peopleGrid.refreshdata();
            }
        };

        vm.peopleSettings = {
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            autorowheight: true,
            enabletooltips: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            columnsresize: true,
            columns: [{
                    text: 'Prénom',
                    datafield: 'firstName',
                    cellsrenderer: linkCellRenderer,
                    cellsalign: 'center',
                    align: 'center',
                    width: 110
                }, {
                    text: 'Nom',
                    datafield: 'lastName',
                    cellsrenderer: linkCellRenderer,
                    cellsalign: 'center',
                    align: 'center',
                    width: 110
                }, {
                    text: 'Email',
                    datafield: 'email',
                    cellsrenderer: linkCellRenderer,
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 200
                }, {
                    text: 'Téléphone',
                    datafield: 'phone',
                    cellsalign: 'center',
                    align: 'center',
                    width: 130
                }, {
                    text: 'Portable',
                    datafield: 'portable',
                    cellsalign: 'center',
                    align: 'center',
                    width: 130
                }],
            created: function (args) {
                vm.peopleGrid = args.instance;
            },
            rowselect: function (event) {
                var args = event.args;
                if (args && args.row) {
                    var row = args.row;
                    vm.personSelected = row;
                }
            }
        };

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.addGroup = function () {
            vm.editGroup({});
        };

        vm.editSelectedGroup = function () {
            vm.editGroup({
                id: vm.groupSelected.id,
                name: vm.groupSelected.name,
                description: vm.groupSelected.description,
                people: vm.groupSelected.people
            });
        };

        vm.editGroup = function (groupe) {
            $uibModal.open({
                templateUrl: 'app/groups/dialog/add-groupe.dialog.html',
                controller: 'AddGroupeDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    groupe: function () {
                        return groupe;
                    }
                }
            }).result.then(function (groupe) {
                if (CustomUtils.isDefined(groupe.id)) {
                    Groupe.update(groupe, vm.load);
                } else {
                    Groupe.save(groupe, vm.load);
                }
            });
        };

        vm.tryRemoveGroup = function () {
            var text = vm.groupSelected.name;
            ConfirmDialog.openConfirmRemove(text, function () {
                vm.removeGroup(vm.groupSelected);
            });
        };

        vm.removeGroup = function (group) {
            Groupe.delete({id: group.id}, vm.load);
        };

        vm.tryRemovePerson = function () {
            var person = vm.personSelected;
            var msg = person.name + " du groupe " + vm.groupSelected.name;
            ConfirmDialog.openConfirmRemove(msg, function () {
                vm.removePerson(person);
            });
        };

        vm.addPerson = function () {
            $uibModal.open({
                templateUrl: 'app/groups/dialog/select-person.dialog.html',
                controller: 'SelectPersonDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }]
                }
            }).result.then(function (person) {
                if (CustomUtils.isUndefinedOrNull(person)) {
                    return;
                }

                var found = false;
                for (var i = 0; i < vm.groupSelected.people.length; i++) {
                    var aPerson = vm.groupSelected.people[i];
                    if (aPerson.id === person.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    vm.groupSelected.people.push(person);
                    Groupe.update(vm.groupSelected, vm.load);
                }
            });
        };


        vm.removePerson = function (person) {
            var personIndex = -1;
            for (var i = 0; i < vm.groupSelected.people.length; i++) {
                var aPerson = vm.groupSelected.people[i];
                if (aPerson.id === person.id) {
                    personIndex = i;
                    break;
                }
            }
            if (personIndex !== -1) {
                vm.groupSelected.people.splice(personIndex, 1);
                Groupe.update(vm.groupSelected, vm.load);
            }
        };
    }
})();
