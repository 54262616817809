(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetCallForProjectMailsTabController', SheetCallForProjectMailsTabController);

    SheetCallForProjectMailsTabController.$inject = ['$log', '$stateParams', 'CallForProjectMail', 'SheetHistoryService', '$state', 'callForProject', '$translate', 'Principal', '$q', 'mails', 'CallForProjectService', '$uibModal'];

    function SheetCallForProjectMailsTabController($log, $stateParams, CallForProjectMail, SheetHistoryService, $state, callForProject, $translate, Principal, $q, mails, CallForProjectService, $uibModal) {
        if ($stateParams.tab !== "mails") {
            //update state param
            $stateParams.tab = "mails";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;
        vm.callForProject = callForProject;
        vm.mails = mails;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        vm.edition = {
            informations: false
        };

        var sheetHistory = {
            name: vm.callForProject.name,
            type: 'callforproject',
            id: vm.callForProject.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.save = function (result) {
            for (var i = 0; i < vm.mails.length; i++) {
                var mail = vm.mails[i];
                if (mail.id === result.id) {
                    mail.title = result.title;
                    mail.content = result.content;
                    CallForProjectMail.update(mail);
                    break;
                }
            }
        };

        vm.remove = function (result) {
            if (result.id) {
                CallForProjectMail.remove(result, function () {
                    vm.load();
                });
            }
        };

        vm.getPanelTitle = function (mail) {
            return CallForProjectService.getMailStepLabelValue(vm.callForProject, mail.type);
        };

        vm.getTemplateType = function (mail) {
            if (mail.type.startsWith('G_EXPSaved')) {
                return "expert";
            }
            return "callforproject";
        };

        vm.addTemplate = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/callforproject/dialog/add-mail-template.dialog.html',
                controller: 'AddCallForProjectMailDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('callForProjectMail');
                            $translatePartialLoader.addPart('callForProjectMailType');
                            return $translate.refresh();
                        }],
                    mail: function () {
                        return {
                            title: null,
                            content: null,
                            type: null,
                            callForProjectId: vm.callForProject.id
                        };
                    },
                    callForProject: function () {
                        return vm.callForProject;
                    },
                    existingMails: function () {
                        return vm.mails;
                    }
                }
            }).result.then(function () {
                vm.load();
            });
        };

    }
})();