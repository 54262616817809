(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetPersonController', SheetPersonController);

    SheetPersonController.$inject = ['Principal', '$stateParams', '$state', 'Person', 'SheetHistoryService', 'personEntity', '$translate', 'ConfirmDialog', 'AlertService', '$log', '$uibModal', '$http'];

    function SheetPersonController(Principal, $stateParams, $state, Person, SheetHistoryService, personEntity, $translate, ConfirmDialog, AlertService, $log, $uibModal, $http) {
        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: personEntity,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                title: false
            }
        };

        var sheetHistory = {
            name: vm.Model.entity.firstName + ' ' + vm.Model.entity.lastName,
            type: 'person',
            id: vm.Model.id
        };
        SheetHistoryService.add(sheetHistory);

        vm.load = function () {
            $state.go($state.current, {}, {reload: true});
        };

        vm.cancel = vm.load;

        vm.edit = function (win) {
            vm.Model.edition[win] = true;
        };

        vm.save = function () {
            return Person.update(vm.Model.entity, vm.load, vm.load);
        };

        vm.refreshIndex = function () {
            $http.get('api/person/index/' + vm.Model.id).then(function () {
                var name = vm.Model.entity.firstName + ' ' + vm.Model.entity.lastName;
                AlertService.success("L'indexation de " + name + " est en cours");
            });
        };

        vm.tryRemovePerson = function () {

            $uibModal.open({
                templateUrl: 'app/sheet/person/dialog/remove-person.dialog.html',
                controller: 'RemovePersonDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    entity: function () {
                        return vm.Model.entity;
                    },
                    dryDeleteResult: function () {
                        return $http.delete("api/person/" + vm.Model.entity.id + "/dry").then(function (response) {
                            return response.data;
                        });
                    }
                }
            }).result.then(function () {
                $state.go("home");
            });

        };

        vm.tabs = [{
                title: $translate.instant("sheet.person.general.tab"),
                route: "sheet.person.main"
            }, {
                title: $translate.instant("sheet.person.followed.tab"),
                route: "sheet.person.followed"
            }, {
                title: $translate.instant("sheet.person.communication.tab"),
                route: 'sheet.person.communication'
            }, {
                title: $translate.instant("sheet.person.project.tab"),
                route: 'sheet.person.project'
            }
        ];

        var activeTab = $stateParams.tab;

        vm.activeTabIndex = 0;
        angular.forEach(vm.tabs, function (tab, index) {
            if (tab.route.endsWith(activeTab)) {
                vm.activeTabIndex = index;
            }
        });

        $state.go(vm.tabs[vm.activeTabIndex].route);
    }
})();
