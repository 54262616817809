(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SheetMainEventMainTabController', SheetMainEventMainTabController);

    SheetMainEventMainTabController.$inject = ['Principal', '$log', 'SocietySearch', '$stateParams', 'MainEvent', 'CustomUtils', '$translate', '$state', 'SheetHistoryService', 'Axe', '$q', 'ModelConfig', 'EventManagement', '$uibModal', 'ConfirmDialog', 'entity', 'EventSession', 'DataUtils', 'JQWidgetsService'];

    function SheetMainEventMainTabController(Principal, $log, SocietySearch, $stateParams, MainEvent, CustomUtils, $translate, $state, SheetHistoryService, Axe, $q, ModelConfig, EventManagement, $uibModal, ConfirmDialog, entity, EventSession, DataUtils, JQWidgetsService) {
        if ($stateParams.tab !== "main") {
            //update state param
            $stateParams.tab = "main";
            $state.transitionTo($state.current.name, $stateParams, {notify: false});
        }

        var vm = this;

        vm.Model = {
            id: $stateParams.id,
            entity: entity,
            canEdit: Principal.hasAnyAuthority(['ROLE_ADMIN']),
            edition: {
                informations: false,
                description: false,
                otherinformation: false,
                dates: false,
                form: false
            },
            html: {}
        };
        vm.axes = Axe.query();

        vm.dateTimeSettings = {
            height: 30,
            culture: "fr-FR",
            formatString: "dd/MM/yyyy HH:mm",
            showTimeButton: true
        };

        vm.changePublicState = function () {
            MainEvent.update(vm.Model.entity, vm.load, vm.load);
        };
        vm.getPublicState = function () {
            if (vm.Model.entity.publique) {
                return $translate.instant('diffusion.online');
            } else {
                return $translate.instant('diffusion.offline');
            }
        };

        var onLoadSuccess = function (result) {
            for (var key in vm.Model.edition) {
                vm.Model.edition[key] = false;
            }
            vm.Model.entity = result;
            $log.debug(result);

            vm.Model.fullAddress = vm.Model.entity.address;
            if (vm.Model.entity.cp) {
                vm.Model.fullAddress += " - " + vm.Model.entity.cp;
            }
            if (vm.Model.entity.city) {
                vm.Model.fullAddress += " - " + vm.Model.entity.city;
            }

            var sheetHistory = {
                name: vm.Model.entity.name,
                type: 'mainevent',
                id: vm.Model.id
            };
            SheetHistoryService.add(sheetHistory);
        };

        vm.edit = function (win) {
            vm.Model.edition[win] = true;
        };

        vm.save = function () {
            if (vm.Model.entity.freeRegistration === true) {
                vm.Model.entity.billManagement = false;
            }

            return MainEvent.update(vm.Model.entity, vm.load, vm.load);
        };

        vm.load = function (first) {
            if (first === true) {
                //premier chargement, pas la peine de charger l'entité
                //mais il faut quand même charger le reste
                onLoadSuccess(vm.Model.entity);
            } else {
                $state.go($state.current, {}, {reload: true});
            }
        };
        vm.cancel = function () {
            vm.load();
        };

        vm.axesSettings = {
            width: "100%",
            height: 160,
            displayMember: "name",
            valueMember: "id",
            multiple: true,
            checkboxes: true,
            source: vm.axes,
            created: function (args) {
                vm.axeList = args.instance;
                angular.forEach(vm.Model.entity.axes, function (axe) {
                    vm.axeList.checkItem(axe.id);
                });
            },
            checkChange: function (event) {
                if (event.args) {
                    var args = event.args;

                    var index = null;
                    for (var i = 0; i < vm.Model.entity.axes.length; i++) {
                        var axe = vm.Model.entity.axes[i];
                        if (axe.id === args.value) {
                            index = i;
                            break;
                        }
                    }

                    if (args.checked === true && index === null) {
                        vm.Model.entity.axes.push({
                            id: args.value,
                            name: args.label
                        });
                    } else if (args.checked === false && index !== null) {
                        vm.Model.entity.axes.splice(index, 1);
                    }
                }
            }
        };

        var societySource = {
            datatype: 'json',
            datafields: [{
                    name: 'name'
                }],
            id: 'id',
            url: 'api/_search/',
            data: {
                size: '20'
            }
        };

        var societyDataAdapter = new $.jqx.dataAdapter(societySource, {// jshint ignore:line
            loadServerData: function (serverdata, source, callback) {
                SocietySearch.query({
                    query: vm.societyComboBoxSettings.searchString,
                    page: 0,
                    size: 20
                }, function (result) {
                    callback({
                        records: result
                    });
                });
            }
        });

        vm.societyComboBoxSettings = {
            displayMember: 'name',
            valueMember: 'id',
            minLength: 1,
            //multiSelect: true,
            remoteAutoComplete: true,
            //remoteAutoCompleteDelay: 100,
            source: societyDataAdapter,
            search: function (searchString) {
                searchString = searchString.replaceAll('/', '//');
                vm.societyComboBoxSettings.searchString = searchString;
                societyDataAdapter.dataBind();
            },
            created: function (args) {
                vm.societyComboBox = args.instance;
                angular.forEach(vm.Model.entity.societies, function (item) {
                    vm.societyComboBox.val(item.name);
                });
            }
        };

        vm.societyEventChangeHandler = function () {
            vm.Model.entity.societies = [];
            var selection = vm.societyComboBox.getSelectedItem();
            if (selection.originalItem) {
                vm.Model.entity.societies.push(selection.originalItem);
            }
        };

        var managementSource = {
            datatype: "json",
            url: "api/public/main-events/" + $stateParams.id + "/managements",
            data: {
                size: 1000
            },
            sort: function (column, direction) {
                var records = vm.managementsDataAdapter.records;

                if (direction === 'ascending' || direction === 'asc') {
                    direction = true;
                }
                if (direction === 'descending' || direction === 'desc') {
                    direction = false;
                }

                if (direction != null) {
                    records.sort(function (value1, value2) {
                        if (column === 'name') {
                            value1 = String(value1['lastName']).toLowerCase();
                            value2 = String(value2['lastName']).toLowerCase();
                        } else {
                            value1 = String(value1[column]).toLowerCase();
                            value2 = String(value2[column]).toLowerCase();
                        }
                        if (direction === true) {
                            return value1.localeCompare(value2);
                        } else {
                            return value2.localeCompare(value1);
                        }
                    });
                    vm.managementsDataAdapter.records = records;
                }
                vm.managementGrid.refreshdata();
            },
            datafields: [{
                    name: "id",
                    type: 'number'
                }, {
                    name: 'firstName',
                    map: 'person>firstName',
                    type: 'string'
                }, {
                    name: 'lastName',
                    map: 'person>lastName',
                    type: 'string'
                }, {
                    name: 'type',
                    type: 'string'
                }, {
                    name: 'name',
                    type: 'string'
                }, {
                    name: 'mainEventId',
                    type: 'number'
                }, {
                    name: 'personId',
                    type: 'number'
                }
            ],
            updaterow: function (rowid, rowdata) {
                if (!CustomUtils.isEmpty(rowdata.type)) {
                    EventManagement.update({
                        id: rowdata.id,
                        mainEventId: rowdata.mainEventId,
                        personId: rowdata.personId,
                        type: rowdata.type
                    });
                } else {
                    vm.managementsDataAdapter.dataBind();
                }
            },
            sortcolumn: 'name',
            sortdirection: 'asc',
            id: 'id'
        };

        vm.managementsDataAdapter = new $.jqx.dataAdapter(managementSource, {
            beforeLoadComplete: function (records) {
                vm.missingSubscriptionManager = true;
                angular.forEach(records, function (record) {
                    record.name = record.firstName + " " + record.lastName;
                    if (ModelConfig.mainEvent.management.getSubscriptionManagerType() === record.type) {
                        vm.missingSubscriptionManager = false;
                    }
                });
            }
        });

        vm.managementTypeDataAdapter = new $.jqx.dataAdapter({
            dataType: 'array',
            localdata: ModelConfig.mainEvent.management.types,
            datafields: [{
                    name: 'label',
                    type: 'string'
                }, {
                    name: 'value',
                    type: 'string'
                }]
        });

        vm.managementsSettings = {
            width: "100%",
            source: vm.managementsDataAdapter,
            height: 300,
            filterable: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            //columnsresize: true,
            sortable: true,
            //altrows: true,
            editable: vm.Model.canEdit,
            created: function (args) {
                vm.managementGrid = args.instance;
            },
            rowselect: function () {
                vm.canRemoveManagement = true;
            },
            columns: [{
                    text: $translate.instant('sheet.mainevent.participations.name'),
                    datafield: 'name',
                    width: 220,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.personId)) {
                            var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
                            link += object.name;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    },
                    editable: false
                }, {
                    text: 'Type',
                    datafield: 'type',
                    filtertype: 'checkedlist',
                    columnType: 'dropdownlist',
                    cellsrenderer: function (row, column, value, html) {
                        var keyLabel = ModelConfig.mainEvent.management.getLabel(value);
                        var label = $translate.instant(keyLabel);
                        return html.replace(value, label);
                    },
                    createeditor: function (row, value, editor) {
                        editor.jqxDropDownList({
                            height: 25,
                            source: vm.managementTypeDataAdapter,
                            displayMember: 'label',
                            valueMember: 'value',
                            placeHolder: "Veuillez choisir...",
                            //selectedIndex: ModelConfig.mainEvent.management.getIndex(value),
                            renderer: function (index, label, value) {
                                return $translate.instant(label);
                            },
                            selectionRenderer: function (element, index, label, value) {
                                return '<span style="line-height:25px">' + $translate.instant(label) + '</span>';
                            }
                        });
                    },
                    cellvaluechanging: function (row, column, columntype, oldValue, newValue) {
                        return ModelConfig.mainEvent.management.getValue(newValue);
                    }
                }]
        };

        vm.addManagement = function () {
            $uibModal.open({
                templateUrl: 'app/sheet/mainevent/dialog/management.dialog.html',
                controller: 'ManagementDialogController',
                controllerAs: 'ctrl',
                size: 'sm',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('subscription');
                            return $translate.refresh();
                        }]
                }
            }).result.then(function (result) {
                EventManagement.save({
                    mainEventId: vm.Model.id,
                    personId: result.personId,
                    type: result.type
                }, function () {
                    vm.managementsDataAdapter.dataBind();
                });
            });
        };

        vm.canRemoveManagement = false;

        vm.tryRemoveManagement = function () {
            var index = vm.managementGrid.getselectedrowindex();
            var data = vm.managementGrid.getrowdata(index);
            var labelKey = ModelConfig.mainEvent.management.getLabel(data.type);
            var label = $translate.instant(labelKey);
            ConfirmDialog.openConfirmRemove(data.name + " de " + label, vm.removeManagement);
        };

        vm.removeManagement = function () {
            var index = vm.managementGrid.getselectedrowindex();
            var data = vm.managementGrid.getrowdata(index);
            EventManagement.remove({
                id: data.id
            }, function () {
                vm.managementsDataAdapter.dataBind();
            });
        };

        vm.load(true);
    }
})();