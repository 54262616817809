(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('DatabaseController', DatabaseController);

    DatabaseController.$inject = ['JhiConfigurationService', 'DatabaseService', 'ConfirmDialog', 'DataUtils'];

    function DatabaseController(JhiConfigurationService, DatabaseService, ConfirmDialog, DataUtils) {
        var vm = this;

        vm.datasourceConfiguration = null;
        vm.escapeConfiguration = null;

        vm.dumpEnable = true;
        vm.dumpBtnTxt = 'Sauvegarder la base';
        vm.dumps = [];

        JhiConfigurationService.get().then(function (configurations) {
            _.forEach(configurations, function (configuration) {
                if (configuration.prefix === 'escape') {
                    vm.escapeConfiguration = configuration.properties;
                } else if (configuration.prefix === 'spring.datasource') {
                    vm.datasourceConfiguration = configuration.properties;
                }
            });
        });

        vm.refresh = function () {
            DatabaseService.get().then(function (response) {
                vm.dumps = response;
            });
        };

        vm.tryMakeDump = function () {
            var title = 'Confirmation de sauvegarde de la base';
            var txt = '<span class="label label-warning">Attention</span> La sauvegarde de la base prend de la ressource machine.<br/><br/>' +
                    'Etes-vous sûr de vouloir effectuer la sauvegarde ?';
            ConfirmDialog.open(title, txt, function () {
                vm.makeDump();
            });
        };

        vm.makeDump = function () {
            var initTxt = vm.dumpBtnTxt;
            vm.dumpEnable = false;
            vm.dumpBtnTxt = 'Sauvegarde en cours...';
            DatabaseService.makeDump().then(function (response) {
                vm.refresh();
                vm.dumpBtnTxt = initTxt;
                vm.dumpEnable = true;
            });
        };

        vm.download = function (dump) {
            var url = "api/database/dump/download?fileName=" + dump.name;
            DataUtils.downloadFileFromUrl(url, dump.name);
        };

        vm.tryRemove = function (dump) {
            var txt = '<span class="label label-warning">Attention</span>  Etes-vous sûr de vouloir supprimer la sauvegarde ' + dump.name;
            ConfirmDialog.openConfirmRemoveWithCustomMsg(txt, function () {
                vm.removeDump(dump);
            });
        };

        vm.removeDump = function (dump) {
            DatabaseService.removeDump(dump.name).then(function (response) {
                vm.refresh();
            });
        };

        vm.refresh();
    }
})();
