(function () {
    'use strict';

    angular.module('canceropoleGsoApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('addPerson', {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                firstName: null,
                lastName: null
            },
            onEnter: ['$state', '$uibModal', 'previousState', '$stateParams', function ($state, $uibModal, previousState, $stateParams) {
                    $uibModal.open({
                        templateUrl: 'app/add/person/add-person-dialog.html',
                        controller: 'AddPersonDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            name: {
                                firstName: $stateParams.firstName,
                                lastName: $stateParams.lastName
                            }
                        }
                    }).result.then(function (result, goToSheet) {
                        if (result.goToSheet) {
                            var person = result.person;
                            $state.go('sheet.person', {
                                id: person.id,
                                type: 'person',
                                name: person.firstName + " " + person.lastName
                            }, {
                                reload: true
                            });
                        } else {
                            $state.go(previousState.name, previousState.params);
                        }
                    }, function () {
                        $state.go(previousState.name, previousState.params);
                    });
                }],
            resolve: {
                previousState: ['$state', function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('person');
                        $translatePartialLoader.addPart('civilite');
                        $translatePartialLoader.addPart('dialog');
                        return $translate.refresh();
                    }]
            }
        }).state('addSociety', {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                name: null
            },
            onEnter: ['$state', '$uibModal', 'previousState', 'geosites', function ($state, $uibModal, previousState, geosites) {
                    $uibModal.open({
                        templateUrl: 'app/add/society/add-society-dialog.html',
                        controller: 'AddSocietyDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            geosites: function () {
                                return geosites;
                            }
                        }
                    }).result.then(function (result) {
                        if (result.goToSheet) {
                            $state.go('sheet.society', {
                                id: result.society.id,
                                type: 'society',
                                name: result.society.name
                            }, {
                                reload: true
                            });
                        } else {
                            $state.go(previousState.name, previousState.params);
                        }
                    }, function () {
                        $state.go(previousState.name, previousState.params);
                    });
                }],
            resolve: {
                previousState: ['$state', function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('society');
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('dialog');
                        $translatePartialLoader.addPart('societyLevel');
                        return $translate.refresh();
                    }],
                geosites: ['Geosite', function (Geosite) {
                        return Geosite.query().$promise;
                    }]
            }
        }).state('addTeam', {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            params: {
                name: null
            },
            onEnter: ['$state', '$uibModal', 'previousState', function ($state, $uibModal, previousState) {
                    $uibModal.open({
                        templateUrl: 'app/add/team/add.team.dialog.html',
                        controller: 'AddTeamDialogController',
                        controllerAs: 'ctrl',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    $translatePartialLoader.addPart('dialog');
                                    $translatePartialLoader.addPart('team');
                                    return $translate.refresh();
                                }],
                            team: function () {
                                return {
                                    id: null,
                                    publique: true
                                };
                            },
                            geosites: ['Geosite', function (Geosite) {
                                    return Geosite.query().$promise;
                                }],
                            society: function () {
                                return null;
                            }
                        }
                    }).result.then(function (result) {
                        if (result.goToSheet) {
                            $state.go('sheet.team', {
                                id: result.team.id,
                                type: 'team',
                                name: result.team.name
                            }, {
                                reload: true
                            });
                        } else {
                            $state.go(previousState.name, previousState.params);
                        }
                    }, function () {
                        $state.go(previousState.name, previousState.params);
                    });
                }],
            resolve: {
                previousState: ['$state', function ($state) {
                        var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('society');
                        $translatePartialLoader.addPart('sheet');
                        $translatePartialLoader.addPart('dialog');
                        return $translate.refresh();
                    }],
                geosites: ['Geosite', function (Geosite) {
                        return Geosite.query().$promise;
                    }]
            }
        }).state('addMainEvent', {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['axes', '$state', '$uibModal', function (axes, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/add/mainEvent/add-main-event-dialog.html',
                        controller: 'AddMainEventDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            axes: function () {
                                return axes;
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('sheet.mainevent', {
                            id: result.id,
                            type: 'mainevent',
                            name: result.name
                        }, {
                            reload: true
                        });
                    }, function () {
                        $state.go('home', null, {reload: true});
                    });
                }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('mainEvent');
                        $translatePartialLoader.addPart('dialog');
                        return $translate.refresh();
                    }],
                axes: ['Axe', function (Axe) {
                        return Axe.query().$promise;
                    }]
            }
        }).state('addCallForProject', {
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['axes', '$state', '$uibModal', 'aapTypes', function (axes, $state, $uibModal, aapTypes) {
                    $uibModal.open({
                        templateUrl: 'app/add/callforproject/add-callforproject-dialog.html',
                        controller: 'AddCallForProjectDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            axes: function () {
                                return axes;
                            },
                            aapTypes: function () {
                                return aapTypes;
                            }
                        }
                    }).result.then(function (result) {
                        if (result.goToSheet === true) {
                            $state.go('sheet.callforproject.main', {
                                id: result.callForProject.id,
                                type: 'callforproject',
                                name: result.callForProject.name
                            }, {
                                reload: true
                            });
                        }
                    }, function () {
                        $state.go('home', null, {reload: true});
                    });
                }],
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('callForProject');
                        $translatePartialLoader.addPart('dialog');
                        return $translate.refresh();
                    }],
                axes: ['Axe', function (Axe) {
                        return Axe.query().$promise;
                    }],
                aapTypes: ['CallForProjectType', function (CallForProjectType) {
                        return CallForProjectType.query({size: 100}).$promise;
                    }]
            }
        });
    }

})();
