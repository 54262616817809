(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('LeafletMapsController', LeafletMapsController);

    LeafletMapsController.$inject = ['$scope', 'leafletData', 'MapSearchService', 'GeoService', 'CustomUtils', 'availableTypes', '$log', 'leafletMarkersHelpers', '$state', '$location', 'localStorageService', '$document', '$timeout'];

    function LeafletMapsController($scope, leafletData, MapSearchService, GeoService, CustomUtils, availableTypes, $log, leafletMarkersHelpers, $state, $location, localStorageService, $document, $timeout) {
        var vm = this;
        vm.availableTypes = [];
        var LOCATION_KEY_STORAGE = "lngLatLocation";

        $scope.$on('$stateChangeStart', function () {
            leafletMarkersHelpers.resetMarkerGroups();
        });

        vm.availableTypes = [];
        if (availableTypes && availableTypes.aggregations && availableTypes.aggregations.type) {
            vm.availableTypes = availableTypes.aggregations.type.buckets;
        }

        vm.redirect = function (id, type, name) {
            $log.info("Go to sheet ", arguments);

            $state.go('sheet.' + type, {
                id: id,
                name: name,
                tab: 'main'
            });
        };

        vm.toggleType = function (type) {
            type.selected = !type.selected;
            var mapStorage = {
                center: vm.center,
                types: vm.getSelectedTypes()
            };

            localStorageService.add(LOCATION_KEY_STORAGE, angular.toJson(mapStorage));

            vm.searchTypes();
        };

        vm.searchTypes = function () {
            var query = null;
            if (!CustomUtils.isUndefinedOrNull(vm.availableTypes)) {
                var typeQuery = '';
                for (var i = 0; i < vm.availableTypes.length; i++) {
                    var availableType = vm.availableTypes[i];
                    if (availableType.selected) {
                        if (typeQuery.length > 0) {
                            typeQuery += ' | ';
                        }
                        typeQuery += availableType.key;
                    }
                }
                if (typeQuery.length > 0) {
                    query = '_type:(' + typeQuery + ')';
                }
            }
            $log.debug("query is", query);
            if (query === null) {
                var markers = [];
                leafletData.getDirectiveControls().then(function (controls) {
                    controls.markers.clean(markers, vm.markers);
                    vm.markers = markers;
                });
            } else {
                MapSearchService.query({
                    query: query
                }, function (result) {
                    vm.docCount = result.length;
                    var markers = [];
                    for (var i = 0; i < result.length; i++) {
                        var aResult = result[i];

                        if (aResult.lat) {
                            var type = aResult.type;
                            var name = aResult.name.replaceAll("'", '');
                            var id = aResult.id;

                            var description = aResult.description;

                            var markerKey = aResult.name.replace(/-/g, '_');
                            markerKey = CustomUtils.removeAccents(markerKey);
                            markerKey = CustomUtils.removeSpaces(markerKey);
                            markerKey = CustomUtils.removeQuotes(markerKey);

                            var message = '<div ng-click="vm.redirect(' + id + ',\'' + type + '\',\'' + name + '\')" style="cursor:pointer;">';
                            //message += "(" + id + ")<br/>";
                            message += '<b>' + name + '</b><br/>';
                            message += description;
                            message += '</div>';

                            markers.push({
                                lat: parseFloat(aResult.lat),
                                lng: parseFloat(aResult.lng),
                                message: message,
                                layer: type,
                                draggable: false,
                                icon: GeoService.iconMarker(aResult.type),
                                compileMessage: true,
                                getMessageScope: function () {
                                    return $scope;
                                }
                            });
                        }
                    }
                    leafletData.getDirectiveControls().then(function (controls) {
                        controls.markers.create(markers, vm.markers);
                        vm.markers = markers;
                    });
                });
            }
        };

        vm.center = {
            lat: 45.600865,
            lng: 1.432632,
            zoom: 5
        };

        var lastLocationStr = localStorageService.get(LOCATION_KEY_STORAGE);
        if (lastLocationStr) {
            var lastLocation = angular.fromJson(lastLocationStr);
            vm.center = lastLocation.center;
            if (!CustomUtils.isUndefinedOrNull(vm.availableTypes) &&
                    !CustomUtils.isUndefinedOrNull(lastLocation.types)) {
                for (var i = 0; i < lastLocation.types.length; i++) {
                    var selectedType = lastLocation.types[i];
                    for (var j = 0; j < vm.availableTypes.length; j++) {
                        var availableType = vm.availableTypes[j];
                        if (selectedType === availableType.key) {
                            availableType.selected = true;
                            break;
                        }
                    }
                }
            }
        }

        $scope.$on("centerUrlHash", function (event, centerHash) {
            $location.search({c: centerHash});

            var latLng = centerHash.split(":");
            var center = {
                lat: Number(latLng[0]),
                lng: Number(latLng[1]),
                zoom: Number(latLng[2])
            };

            var mapStorage = {
                center: center,
                types: vm.getSelectedTypes()
            };

            localStorageService.add(LOCATION_KEY_STORAGE, angular.toJson(mapStorage));
        });

        vm.getSelectedTypes = function () {
            var selectedTypes = [];
            if (!CustomUtils.isUndefinedOrNull(vm.availableTypes)) {
                angular.forEach(vm.availableTypes, function (availableType) {
                    if (availableType.selected) {
                        selectedTypes.push(availableType.key);
                    }
                });
            }
            return selectedTypes;
        };

        vm.watchOptions = {
            markers: {
                type: null,
                individual: {
                    type: null
                }
            }
        };

        vm.layers = {
            baselayers: {
                osm: {
                    name: 'OpenStreetMap',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    type: 'xyz'
                }
            },
            overlays: {}
        };

        angular.forEach(vm.availableTypes, function (availableType) {
            vm.layers.overlays[availableType.key] = {
                name: availableType.key,
                type: "markercluster",
                visible: true
            };
        });

        angular.extend($scope, {
            overlays: {
                events: {
                    map: {
                        enable: ['moveend', 'popupopen'],
                        logic: 'emit'
                    },
                    marker: {
                        enable: [],
                        logic: 'emit'
                    }
                },
                watchOptions: vm.watchOptions
            }
        });

        vm.searchTypes();

        $timeout(function () {
            leafletData.getMap().then(function (map) {
                var mapPanel = $("#mapPanel");
                var mapPanelHeight = mapPanel.height();
                $log.info(mapPanelHeight);

                var windowHeight = $(window).height();
                $log.info(windowHeight);

                var mapDiv = $("#map");
                mapDiv.height(windowHeight - 200);
                map.invalidateSize();
            });
        }, 100);


    }
})();
