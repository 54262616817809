(function () {
    'use strict';
    angular.module('canceropoleGsoApp')
            .controller('MetierController', MetierController);
    MetierController.$inject = ['$log', 'PersonMetier', '$translate', 'AlertService', 'ConfirmDialog', 'CustomUtils', '$uibModal', '$filter', 'JQWidgetsService'];
    function MetierController($log, PersonMetier, $translate, AlertService, ConfirmDialog, CustomUtils, $uibModal, $filter, JQWidgetsService) {
        var vm = this;
        vm.isSaving = false;
        vm.metierSelected = null;
        vm.statusSelected = null;
        vm.metierSettings = {
            width: "100%",
            altrows: true,
            sortable: true,
            enabletooltips: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            source: new $.jqx.dataAdapter({
                datatype: "json",
                url: 'api/person-metiers',
                datafields: [{
                        name: 'id',
                        type: 'number'
                    }, {
                        name: "name",
                        type: "string"
                    }, {
                        name: "groupName",
                        type: 'string'
                    }, {
                        name: "groupName2",
                        type: 'string'
                    }, {
                        name: "personStatuses",
                        type: 'array'
                    }],
                id: 'id',
                sortcolumn: 'name',
                sortdirection: 'asc'
            }),
            columnsresize: true,
            columns: [{
                    text: 'Métier',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 250
                }, {
                    text: 'Groupe 1',
                    datafield: 'groupName',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: 'Groupe 2',
                    datafield: 'groupName2',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }],
            created: function (args) {
                vm.metierGrid = args.instance;
            },
            rowselect: function (event) {
                var args = event.args;
                if (args && args.row) {
                    var row = args.row;
                    vm.metierSelected = {
                        id: row.id,
                        name: row.name,
                        groupName: row.groupName,
                        groupName2: row.groupName2,
                        personStatuses: row.personStatuses
                    };
                    vm.statusSettings.source = new $.jqx.dataAdapter({
                        datatype: "json",
                        localdata: row.personStatuses,
                        sortcolumn: 'name',
                        sortdirection: 'asc'
                    });
                    vm.statusGrid.clearselection();
                    vm.jobsSettings.source = new $.jqx.dataAdapter({
                        datatype: "json",
                        url: 'api/jobs/metier/' + row.id
                    });
                    vm.jobGrid.clearselection();
                    vm.registrationsSettings.source = new $.jqx.dataAdapter({
                        datatype: "json",
                        url: 'api/event-registrations/metier/' + row.id
                    });
                    vm.registrationsGrid.clearselection();
                }
            }
        };
        vm.statusSettings = {
            width: "100%",
            altrows: true,
            columnsresize: true,
            sortable: true,
            enabletooltips: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            columns: [{
                    text: 'Statut',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 250
                }, {
                    text: $translate.instant('canceropoleGsoApp.personStatus.groupName'),
                    datafield: 'groupName',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }, {
                    text: $translate.instant('canceropoleGsoApp.personStatus.groupName2'),
                    datafield: 'groupName2',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120
                }],
            created: function (args) {
                vm.statusGrid = args.instance;
            },
            rowselect: function (event) {
                var args = event.args;
                if (args && args.row) {
                    var row = args.row;
                    vm.statusSelected = {
                        id: row.id,
                        name: row.name,
                        groupName: row.groupName,
                        groupName2: row.groupName2
                    };
                    vm.filterJobs(row.name);
                }
            }
        };
        vm.deleteMetier = function () {
            if (!vm.isSaving && vm.metierSelected !== null) {
                ConfirmDialog.openConfirmRemove(vm.metierSelected.name, function () {
                    vm.isSaving = true;
                    PersonMetier.delete(vm.metierSelected, function () {
                        vm.isSaving = false;
                        vm.metierGrid.updatebounddata();
                        vm.statusSettings.source = new $.jqx.dataAdapter({
                            datatype: "json",
                            localdata: []
                        });
                    }, function (response) {
                        AlertService.error("Problème lors de la sauvegarde en base.");
                        $log.error(response);
                        vm.isSaving = false;
                    });
                });
            }
        };
        vm.tryDeleteStatus = function () {
            if (!vm.isSaving && vm.metierSelected !== null && vm.statusSelected !== null) {
                var statusToDelete = vm.statusSelected.name.replaceAll('\'', ' ');
                ConfirmDialog.openConfirmRemove(statusToDelete, function () {
                    vm.deleteStatus();
                });
            }
        };
        vm.deleteStatus = function () {
            if (!vm.isSaving && vm.metierSelected !== null && vm.statusSelected !== null) {

                var statusIndex = null;
                for (var i = 0; i < vm.metierSelected.personStatuses.length; i++) {
                    var personStatus = vm.metierSelected.personStatuses[i];
                    if (personStatus.id === vm.statusSelected.id) {
                        statusIndex = i;
                        break;
                    }
                }
                if (statusIndex !== null) {
                    vm.isSaving = true;
                    vm.metierSelected.personStatuses.splice(statusIndex, 1);
                    PersonMetier.update(vm.metierSelected, function () {
                        vm.isSaving = false;
                        vm.metierGrid.updatebounddata();
                        vm.statusGrid.updatebounddata();
                    }, function (response) {
                        AlertService.error("Problème lors de la sauvegarde en base.");
                        $log.error(response);
                        vm.isSaving = false;
                    });
                }
            }
        };
        vm.jobsSettings = {
            width: "100%",
            altrows: true,
            columnsresize: true,
            sortable: true,
            filterable: true,
            enabletooltips: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            columns: [{
                    text: 'Nom',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 150,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.personId)) {
                            var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
                            link += object.name;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Structure',
                    datafield: 'society',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 200,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.societyId)) {
                            var link = '<a href="#/sheet/society/' + object.societyId + '?tab=main">';
                            link += object.society;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Statut',
                    datafield: 'status',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 120
                }],
            created: function (args) {
                vm.jobGrid = args.instance;
            }
        };
        vm.filterJobs = function (value) {
            vm.jobGrid.clearfilters();
            if (!CustomUtils.isUndefinedOrNull(value)) {
                var filtergroup = new $.jqx.filter();
                var filter_or_operator = 1;
                var filter = filtergroup.createfilter('stringfilter', value, 'equal');
                filtergroup.addfilter(filter_or_operator, filter);
                vm.jobGrid.addfilter('status', filtergroup);
                vm.jobGrid.applyfilters();
            }
        };
        vm.addMetier = function () {
            vm.editMetier(true);
        };

        vm.editMetier = function (addMetier) {
            $uibModal.open({
                templateUrl: 'app/metier/dialog/metier.edit.dialog.html',
                controller: 'MetierEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    metier: function () {
                        if (addMetier === true) {
                            return null;
                        }
                        return vm.metierSelected;
                    }
                }
            }).result.then(function (metier) {
                vm.metierGrid.updatebounddata();
                vm.statusSettings.source = new $.jqx.dataAdapter({
                    datatype: "json",
                    localdata: metier.personStatuses
                });
            });
        };
        vm.addStatus = function () {
            vm.editStatus(true);
        };
        vm.editStatus = function (addStatus) {
            $uibModal.open({
                templateUrl: 'app/metier/dialog/status.edit.dialog.html',
                controller: 'StatusEditDialogController',
                controllerAs: 'ctrl',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dialog');
                            return $translate.refresh();
                        }],
                    status: function () {
                        if (addStatus === true) {
                            return null;
                        }
                        return vm.statusSelected;
                    }
                }
            }).result.then(function (status) {
                if (addStatus === true && vm.metierSelected) {
                    //ajout d'un nouveau statut
                    vm.metierSelected.personStatuses.push(status);
                    PersonMetier.update(vm.metierSelected, function () {
                        vm.metierGrid.updatebounddata();
                        vm.metierGrid.clearselection();
                        vm.statusSettings.source = new $.jqx.dataAdapter({
                            datatype: "json",
                            localdata: []
                        });
                    });
                } else {
                    //maj d'un statut
                    vm.metierGrid.updatebounddata();
                    vm.statusSelected = status;
                    var statusIndex = null;
                    for (var i = 0; i < vm.metierSelected.personStatuses.length; i++) {
                        var aStatus = vm.metierSelected.personStatuses[i];
                        if (aStatus.id === status.id) {
                            statusIndex = i;
                            break;
                        }
                    }
                    if (statusIndex !== null) {
                        vm.metierSelected.personStatuses[statusIndex] = status;
                        vm.statusGrid.updatebounddata();
                    }
                }
            });
        };
        vm.registrationsSettings = {
            width: "100%",
            altrows: true,
            columnsresize: true,
            sortable: true,
            filterable: true,
            enabletooltips: true,
            showfilterrow: true,
            localization: JQWidgetsService.getLocalization($translate.use()),
            columns: [{
                    text: 'Evénement',
                    datafield: 'mainEvent',
                    cellsalign: 'center',
                    align: 'center',
                    width: 150,
                    pinned: true,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.mainEventId)) {
                            var link = '<a href="#/sheet/mainevent/' + object.mainEventId + '?tab=main">';
                            link += object.mainEvent;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Inscriptions',
                    datafield: 'registrationDate',
                    cellsalign: 'center',
                    align: 'center',
                    width: 120,
                    pinned: true,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.registrationKey)) {
                            var link = '<a href="#/manifestation/' + object.mainEventId + '/space?key=' + object.registrationKey + '">';
                            link += $filter('date')(object.registrationDate, 'dd/MM/yyyy HH:mm');
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Nom',
                    datafield: 'name',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 150,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.personId)) {
                            var link = '<a href="#/sheet/person/' + object.personId + '?tab=main">';
                            link += object.name;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }, {
                    text: 'Statut',
                    datafield: 'status',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 120
                }, {
                    text: 'Structure',
                    datafield: 'society',
                    cellsalign: 'center',
                    align: 'center',
                    minwidth: 200,
                    cellsrenderer: function (row, column, value, html, cell, object) {
                        if (!CustomUtils.isUndefinedOrNull(object.societyId)) {
                            var link = '<a href="#/sheet/society/' + object.societyId + '?tab=main">';
                            link += object.society;
                            link += "</a>";
                            var result = html.replace(value, link);
                            return result;
                        }
                        return html;
                    }
                }],
            created: function (args) {
                vm.registrationsGrid = args.instance;
            }
        };
    }
})();
