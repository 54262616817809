(function () {
    'use strict';

    angular.module('canceropoleGsoApp')
            .controller('SearchController', SearchController);

    SearchController.$inject = ['$scope', '$log', 'localStorageService', '$stateParams', 'SearchService', 'thumbnailFilter', '$translatePartialLoader', '$translate', '$timeout'];

    function SearchController($scope, $log, localStorageService, $stateParams, SearchService, thumbnailFilter, $translatePartialLoader, $translate, $timeout) {
        $translatePartialLoader.addPart('entities');
        $translate.refresh();

        var vm = this;

        vm.searchService = SearchService;

        SearchService.loadSearch();

        $scope.$watch('vm.searchService.query', function () {
            SearchService.newSearch = true;
        });

        $scope.$watch('vm.searchService.searchResult', function () {
            if (SearchService.searchResult) {
                var hits = SearchService.searchResult.hits.hits;
                vm.total = SearchService.searchResult.hits.total;

                var length = 0;
                if (!SearchService.newSearch) {
                    length = SearchService.resultList.length;
                }

                var resultList = [];
                for (var i = 0; i < hits.length; i++) {
                    var hit = hits[i];
                    var result = hit._source;

                    if (!_.isNil(result.type) && result.type !== hit._type) {
                        result.subType = result.type;
                    }
                    result.type = hit._type;
                    result.type = hit._type;

                    length++;
                    result.uid = result.type + "_" + result.id + "_" + length;

                    result._meta = {
                        score: hit._score,
                        highlight: hit.highlight
                    };

                    resultList.push(result);
                }
                if (SearchService.newSearch) {
                    SearchService.resultList = resultList;
                } else {
                    SearchService.resultList = SearchService.resultList.concat(resultList);
                }

                SearchService.newSearch = false;
                SearchService.displayResult = true;

            }
        });

        if (SearchService.query) {
            SearchService.loadPage();
        }
    }
})();
